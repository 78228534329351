import React, {useEffect, useState} from 'react'
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Button, Modal, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import RepairCategoryForm from "./form";

const REPAIR_CATEGORY_STATUS_ENABLE = 1; //启用
const REPAIR_CATEGORY_STATUS_DISABLE = 2; //禁用
const repairCategoryStatusAlias = {
    [REPAIR_CATEGORY_STATUS_ENABLE]: '启用',
    [REPAIR_CATEGORY_STATUS_DISABLE]: '禁用',
}


function CategoryList() {

    let initialRepairCategory = {
        ownerId: common.getUser().company.id,
        taxonomy: 'repairCategory',
        name: '',
        flag: '',
        id: '',
        sort: 0,
        status: REPAIR_CATEGORY_STATUS_ENABLE,
        spec: '',
        parentId: '',
        companyIds: [],
    }


    let [repairCategories, setRepairCategories] = useState([])    //维修工单类型列表
    let [modalVisible, setModalVisible] = useState(false)
    let [repairCategory, setRepairCategory] = useState(initialRepairCategory)    // 通过弹窗模式新增或编辑的维修工单类型


    //获取维修分类
    let getRepairCategory = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                common.consoleLog(data)
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getRepairCategory()
    }, [])

    let save = () => {
        common.consoleLog(repairCategory)
        if (repairCategory.id === "") {
            createRepairCategory()
        } else {
            updateRepairCategory()
        }
    }


    let createRepairCategory = () => {

        common.loadingStart()

        common.ajax("post", "/support/term/create", repairCategory)
            .then((result) => {
                common.consoleLog(result)
                common.toast("添加成功")
                setModalVisible(false)
                setRepairCategories([result, ...repairCategories]) //新增成功返回 repairCategory对象
            })
            .finally(common.loadingStop)
    }

    let updateRepairCategory = () => {
        common.loadingStart()
        common.consoleLog(repairCategory)
        common.ajax("post", "/support/term/updateById", {...repairCategory, companyIds: []})
            .then((result) => {
                common.toast("编辑成功");
                setModalVisible(false)
                getRepairCategory();
            })
            .finally(common.loadingStop)
    }


    //工单类型列
    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 100,
            ellipsis: true,
        },
        {
            title: '类型名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true,
        },
        {
            title: '顺序号',
            dataIndex: 'sort',
            width: 100,
            ellipsis: true,
        },
        {
            title: '标记',
            dataIndex: 'flag',
            width: 100,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return (
                    repairCategoryStatusAlias[text]
                )
            }
        },
        {
            title: '资料说明',
            dataIndex: 'spec',
            width: 120,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render:
                (text, record) => {
                    return (
                        record.id < 0 ? '' :
                            <div className={"action-btns"}>
                                <Button type="link" onClick={() => {
                                    setRepairCategory({...record})
                                    setModalVisible(true)
                                }}>编辑</Button>
                            </div>
                    )
                }
        },
    ]

    return (
        <React.Fragment>

            <PageTop title={'维修工单类型管理'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setRepairCategory(initialRepairCategory)
                    setModalVisible(true)
                }}>新增</Button>
            </PageTop>

            <Table scroll={{x: '100%'}}
                   rowKey={record => record.id}
                   pagination={false}
                   columns={columns}
                   dataSource={repairCategories}
            />

            <Modal
                maskClosable={false}
                footer={null}
                width={1000}
                visible={modalVisible}
                destroyOnClose={true}
                onCancel={() => {
                    setModalVisible(false)
                }}
            >
                <RepairCategoryForm repairCategory={repairCategory} setRepairCategory={setRepairCategory}
                                    onSave={save}/>
            </Modal>

        </React.Fragment>
    )
}

export default CategoryList

