import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Divider, Form, Modal, Row, Table} from "antd";
import {CheckOutlined, RollbackOutlined, CloseOutlined, QuestionCircleOutlined, EditOutlined} from "@ant-design/icons";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import {Link} from "react-router-dom";
import {Order} from "../../../components/coupon/config";
import LookupCustomerRecord from "../../../components/passport/LookupCustomerRecord";
import Search from "../../../utils/search";
import moment from "moment";

function SaleSettlement(props) {
    let {code} = props

    let [tableList2, setTableList2] = useState([]);// 礼券
    let [sale, setSale] = useState({});// 订单
    let [total, setTotal] = useState(0);// 总金额
    let [visible, setVisible] = useState(false);
    let [settlementAtVisible, setSettlementAtVisible] = useState(false) // 结算日期弹框

    let settlement = (params) => {
        common.loadingStart();
        common.ajax("post", `/coupon/order/settlement`, {
            ownerId: common.getUser().company.id,
            code: sale.code,
            type: params
        }).then(() => {
            if (params === 'settlement') {
                common.toast("结算成功")
            }

            if (params === 'cancel') {
                common.toast("取消结算成功")
            }

            getOneData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getOneData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/order/list", {
            ownerId: common.getUser().company.id,
            businessTypes: [Order.BUSINESS_SALE_TYPE_SALE, Order.BUSINESS_SALE_TYPE_SUPPORT],
            page: 1,
            limit: 100,
            businessCode: code !== undefined ? code : props.match.params.id,
        }).then((data) => {
            if (data.orders.length > 0) {
                let total = 0
                data.orders.forEach((item) => {
                    item.total = new BigNumber(item.price).multipliedBy(item.quantity).toNumber()
                    total = new BigNumber(item.total).plus(total).toNumber()
                })

                setTotal(total)
                setSale({
                    id: '-1',
                    code: data.orders[0].businessCode,
                    ownerId: data.orders[0].ownerId,
                    status: data.orders[0].status,
                    settlementAt: (data.orders[0].status === Order.STATUS_SETTLEMENT || data.orders[0].status === Order.STATUS_PAID || data.orders[0].status === Order.STATUS_SENT) ? data.orders[0].settlementAt.substring(0, 10) : "",
                    customerName: data.orders[0].memberName,
                    customerId: data.orders[0].memberId,
                    createdAt: data.orders[0].createdAt.substring(0, 10),
                    businessType: data.orders[0].businessType,
                    spec: data.orders[0].spec,
                })
            }
            setTableList2(data.orders)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getOneData, [props])

    // 重置结算日期
    let handleSettleDate = () => {
        if (sale.settlementAtNew === undefined || sale.settlementAtNew === null || sale.settlementAtNew === "") {
            common.toast("请选择新结算日期")
            return
        }
        let params = {code: sale.code, ownerId: sale.ownerId, settlementAt: sale.settlementAtNew + " 00:00:00"}
        common.loadingStart()
        common.ajax('post', '/coupon/order/updateSettlementAt', params)
            .then(() => {
                common.toast("更新成功")
                setSettlementAtVisible(false)
                setSale({
                    ...sale,
                    settlementAt: sale.settlementAtNew,
                    settlementAtNew: null
                })
            })
            .finally(common.loadingStop)
    }

    const columns = [
        {
            title: '客户',
            dataIndex: 'customerName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '总金额',
            dataIndex: 'total',
            width: 200,
            ellipsis: true,
            render: () => common.numberFormat(total)
        },
        {
            title: '业务类型',
            dataIndex: 'businessType',
            width: 200,
            ellipsis: true,
            render: (text) => Order.BusinessTypes[text]
        }
    ]

    const columns2 = [
        {
            title: '礼券名称',
            dataIndex: 'couponName',
            width: 200,
            ellipsis: true
        },
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
        },
        {
            title: '销售价',
            dataIndex: 'price',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '金额',
            dataIndex: 'total',
            width: 100,
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
    ]

    return (
        <div className={"wms"}>
            {code === undefined &&
            <PageTop title='礼券销售结算'>
                <Button
                    type={"primary"}
                    icon={<CheckOutlined/>}
                    disabled={sale.status !== Order.STATUS_WAITING}
                    onClick={() => {
                        settlement('settlement')
                    }}>确认结算</Button>
                <Button
                    icon={<CloseOutlined/>}
                    disabled={sale.status !== Order.STATUS_SETTLEMENT}
                    onClick={() => {
                        common.confirm("确定要取消结算吗?", () => {
                            settlement('cancel')
                        })
                    }}>取消结算</Button>
                <Button icon={<EditOutlined/>}
                        onClick={() => {
                            setSettlementAtVisible(true)
                        }}
                        hidden={!(sale.status === Order.STATUS_SETTLEMENT || sale.status === Order.STATUS_PAID || sale.status === Order.STATUS_SENT)}
                >重置结算日期</Button>
                {/*<Button>*/}
                {/*    <Link to={'/finance/prepay'}>*/}
                {/*        <RollbackOutlined/>*/}
                {/*        <span> 返回</span>*/}
                {/*    </Link>*/}
                {/*</Button>*/}
                <Button
                    icon={<RollbackOutlined/>}
                    onClick={() => {
                        Search.back()
                    }}>返回</Button>
            </PageTop>}
            {Object.keys(sale).length > 0 && tableList2.length > 0 &&
            <Form className={'ant-advanced-inline-form'}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label={"订单编号"}>
                            <span className="ant-form-text"> {sale.code}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"客户"}>
                            <span className="ant-form-text"> {sale.customerName}
                                &nbsp;&nbsp;
                                <QuestionCircleOutlined onClick={() => {
                                    setVisible(true)
                                }}/>
                            </span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"状态"}>
                            <span className="ant-form-text"> {Order.StatusAlias[sale.status]}</span>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"创建时间"}>
                            <span className="ant-form-text"> {sale.createdAt}</span>
                        </Form.Item>
                    </Col>
                    {(sale.status === Order.STATUS_SETTLEMENT || sale.status === Order.STATUS_PAID || sale.status === Order.STATUS_SENT) &&
                    <Col span={6}>
                        <Form.Item label={"结算日期"}>
                            <span className="ant-form-text"> {sale.settlementAt}</span>
                        </Form.Item>
                    </Col>}
                    <Col span={24}>
                        <Form.Item label={"备注"}>
                            <span className="ant-form-text"> {sale.spec}</span>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>}
            <Divider orientation="left">应收</Divider>
            {sale.code !== undefined &&
            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={[sale]}
                scroll={{x: "100%"}}
                footer={() => {
                    return (
                        <div>
                            <b>合计：</b>
                            <b style={{color: 'red'}}>
                                {total}
                            </b>
                        </div>
                    )
                }}
            />}
            <Divider orientation="left">礼券</Divider>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={tableList2}
                scroll={{x: "100%"}}/>

            <Modal
                maskClosable={false}
                visible={settlementAtVisible}
                width={800}
                onOk={handleSettleDate}
                destroyOnClose={true}
                title={"重置结算日期"}
                onCancel={() => {
                    setSettlementAtVisible(false)
                }}>
                <Form>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={"原结算日期"}>
                                <DatePicker value={sale.settlementAt ? moment(sale.settlementAt) : null} disabled
                                            format="YYYY-MM-DD"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"新结算日期"}>
                                <DatePicker showTime={false} format="YYYY-MM-DD" onChange={(date, dateString) => {
                                    setSale({
                                        ...sale,
                                        settlementAtNew: dateString
                                    })
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal title="客户往来详情"
                   maskClosable={false}
                   destroyOnClose={true}
                   visible={visible}
                   width={1000}
                   footer={null}
                   onCancel={() => {
                       setVisible(false)
                   }}>
                <LookupCustomerRecord customerId={sale.customerId}/>
            </Modal>
        </div>
    );
}

export default SaleSettlement