import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined,
    CheckOutlined,
    MinusCircleOutlined,
    EyeOutlined
    , PrinterOutlined
} from '@ant-design/icons';
import {
    Input,
    DatePicker,
    Select,
    Button,
    Modal,
    Tabs,
    Form,
    Row,
    Col, Divider
} from 'antd';
import "./style.less"
import PageTop from "../../../components/layout/PageTop";
import Calculation from "../trial/calculation";
import Articles from "./articles"
import Discount from "./discount"
import OrderInfo from "./orderInfo";
import Coupon from "./coupon";
import SaleContractForm from "./print/saleContract";

import common from "../../../utils/common";
import moment from 'moment'
import BigNumber from "bignumber.js";
import LookupAllotCar from "../../../components/wms/LookupAllotCar";
import CarSkuCheck from "../../../components/wms/car/sku/check";
import ArticleSkuCheck from "../../../components/wms/article/sku/check";
import LookupCustomer from "../../../components/passport/LookupCustomer";
import Search from "../../../utils/search";
import {SaleOrderItem} from "../../../components/sale/config";
import {Term} from "../../../components/finance/config";

const {TextArea} = Input;

const {Option} = Select;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};
// 开票信息(增票,机动车票)
const {TabPane} = Tabs;

const conversionTypeName = {
    "1": "区域客户订单",
    "11": "一般客户订单",
}

function Plus(props) {
    const {match, history} = props
    let params = {
        orderId: "",
        type: "1"
    }
    const loginUser = common.getUser();

    let initialAdvanceData = {
        id: '',
        type: 1,            // 请款类型
        spec: '',            // 说明
        vin: '',             // 车架号
        plate: '',           // 车牌号
        orderType: 0,        // 订单类型
        orderCode: 0,        // 关联单号
        customerId: 0,       // 客户id
        customerName: '',    // 客户名称
        creatorId: '',
        createdAt: '',
        code: '',
        advanceItemList: [],
        creatorName: '',
        productId: '',
        productName: '',
        ownerId: common.getUser().company.id
    }

    let [saleTrem, setSaleTrem] = useState([])

    let [visibleCancellation, setVisibleCancellation] = useState(false)
    let [visibleCalculation, setVisibleCalculation] = useState(false)
    let [conversionVisible, setConversionVisible] = useState(false)
    let [lookupAllotCarVisible, setLookupAllotCarVisible] = useState(false)
    let [lookupCustomerVisible, setLookupCustomerVisible] = useState(false)
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(1)
    let [isClick, setIsClick] = useState(false)
    let [invoiceType, setInvoiceType] = useState([])
    let [saleMobile, setSaleMobile] = useState("")// 打印时用 销售顾问手机号
    let [companyObj, setCompanyObj] = useState({
        name: "",
        egalPerson: "",
        address: "",
        province: "",
        provinceName: "",
        cityName: "",
        areaName: "",
        bankDeposit: "",
        bankAccount: "",
    })// 打印时用 公司信息

    let [conversionInfo, setConversionInfo] = useState({
        customerName: '',
        customerId: '',
        vehicleOwner: '',
        vehicleOwnerPhone: '',
        customerType: 1
    })
    let [conversionId, setConversionId] = useState('')
    let [rebates, setRebates] = useState([])
    let [rebateDefaultId, setRebateDefaultId] = useState('')
    let [rebateDefaultFlag, setRebateDefaultFlag] = useState('')
    let [rebateFlag, setRebateFlag] = useState({})
    // let [taskInfo, setTaskInfo] = useState({})
    let [order, setOrder] = useState({
        ownerId: loginUser.company.id,
        orderId: params.orderId ? params.orderId : "",
        saleOrderId: '',
        code: "",
        type: params.type ? params.type : "1",
        downPayment: "0",
        createdAt: getNowTime(),
        deliveryAddress: common.getUser().company.name,
        // deliveryDate: getNowTime(),
        source: [],
        channelId: "0",
        partnerId: "",
        partnerName: "",
        status: "",
        saleId: "",
        saleName: common.getUser().nickname,
        orderRemark: "",
        orderRemark2: "",
        customerId: "",
        customerName: "",
        customerType: 1,
        customerPhone: "",
        customerTypeName: "",
        vehicleOwner: "",
        vehicleOwnerPhone: "",
        identityType: "1",
        identityNumber: "",
        payType: '',
        exchangeType: 3,
        useRebate: 0,
        productName: "",
        productId: "",
        vehicleSalePrice: "",
        partsSalePrice: "",
        partsPrice: 0,
        partsRemark: "",
        actualPayPrice: 0,
        discount: "0",
        invoiceType: "",
        couponSalesPrice: 0,
        departmentId: '',
        departmentName: '',
        couponAdvanceDiscount: 0,
        couponTicketDiscount: 0,
        colorInner: "",
        colorOut: "",
        vin: "",
        bindBy: "",
        subtotal: 0,
        bindName: "暂未绑定",
        vehicle: {
            colorInnerId: "",
            colorInner: "",
            colorOutId: "",
            colorOut: "",
            price: 0,
            productId: "",
            productName: "",
            quantity: 1,
            skuId: "",
            vin: "",
            isEdit: false
        },
        vehicleId: "",
        brand: [],
        brandId: "",
        vehicleType: [],
        vehicleTypeId: "",
        plans: {},
        parts: [],
        isEdit: match.params.id,
        ifEdit: match.params.id,
        couponIsEdit: match.params.id,
        discountIsEdit: match.params.id,
        rebates: [],
        advanceDiscount: 0,
        discountPrice: 0,
        settlementStatus: "",
        isDistributor: false,
        ticketIds: [],
        typeChange: false,
        transferId: '',
        isTicket: false,
        isPlans: false,
        orderPayInfoRequest: {
            partnerId: '',
            partnerName: '',
            loanMoney: '0',
            loanPeriod: 0,
            customerProfit: '',
            feeMoney: '0',
            spec: ''
        },
        orderExchangeInfoRequest: {
            vehicleOwner: '',
            productId: '',
            productName: '',
            usedCarMoney: '0',
            subsidyMoney: '0',
            decorate: '',
            additional: '',
            commissionIncome: '0',
            agentId: '',
            agentName: '',
            remark: ''
        },
        advanceCreateRequest: initialAdvanceData
    })
    let [audit, setAudit] = useState(false)
    let [isSubmit, setIsSubmit] = useState(false)
    let [isDistributor, setIsDistributor] = useState(false)
    let [payData, setPayData] = useState([])
    let [inColors, setInColors] = useState([])
    let [outColors, setOutColors] = useState([])
    let [isIdCard, setIsIdCard] = useState(false)
    let [isAudit, setIsAudit] = useState(false)
    let [mspr, setMspr] = useState('0')

    let [advanceData, setAdvanceData] = useState(initialAdvanceData);

    let [typeChannels, setTypeChannels] = useState({})

    let getInvoiceType = () => {
        if (!match.params.id) {
            common.loadingStart()

            common.ajax("get", "/finance/term/findByTaxonomy", {
                taxonomy: "invoiceType",
                status: Term.STATUS_YES,// 启用
            }).then(data => {
                data = data.filter(item => item.status !== 2)
                if (match.params.customerId) {
                    common.loadingStart()
                    common.ajax("get", "/passport/customer/findById", {id: match.params.customerId}).then(customerRes => {
                        let invoiceTypeId = ""
                        if (data.length > 0) {
                            invoiceTypeId = data[0].id
                            // setOrder({...order, invoiceType: data[0].id})
                        }
                        setOrder({
                            ...order,
                            customerId: customerRes.id,
                            customerName: customerRes.name,
                            customerAddress: customerRes.provinceName + customerRes.cityName + customerRes.districtName + customerRes.address,
                            customerPhone: customerRes.phone,
                            customerTypeName: customerRes.customerTypeName ? customerRes.customerTypeName : "",
                            vehicleOwner: customerRes.name,
                            vehicleOwnerPhone: customerRes.phone,
                            invoiceType: invoiceTypeId,
                            type: props.match.params.type,
                            typeChange: true,
                            identityNumber: order.identityType === '1' ? customerRes.idcard : ''
                        })
                    }).finally(common.loadingStop)
                } else {
                    if (data.length > 0) {
                        setOrder({...order, invoiceType: data[0].id})
                    }
                }

                let list = []

                data.forEach(item => {
                    list.push({
                        key: item.id,
                        value: item.name
                    })
                })

                setInvoiceType(list)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    let getRebates = () => {
        common.loadingStart()
        common.ajax("get", "/sale/term/findByTaxonomy", {taxonomy: 'rebate'}).then(data => {
            data = data.filter(item => item.status !== 2)
            setRebates(data)
            let obj = {}
            if (data.length > 0) {
                setRebateDefaultId(data[0].id)
                setRebateDefaultFlag(data[0].flag)
                data.forEach(item => {
                    obj = {...obj, [item.id]: item.flag}
                })
            }

            setRebateFlag(obj)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getPay = () => {
        common.loadingStart()
        common.ajax('get', "/sale/term/findByTaxonomy", {taxonomy: 'pay'}).then(data => {
            data = data.filter(item => item.status !== 2)
            setPayData(data)
        }).finally(common.loadingStop)
    }

    // 获取订单信息
    let getOrderInfo = () => {
        if (match.params.id) {

            common.loadingStart()

            common.ajax("get", "/sale/order/findById", {id: match.params.id}).then(data => {
                let obj = order

                Object.keys(data).forEach(key => {
                    obj[key] = data[key]
                })

                obj.orderPayInfoRequest = {...obj.orderPayInfoRequest, ...data.orderPayInfoDto}
                obj.orderExchangeInfoRequest = {...obj.orderExchangeInfoRequest, ...data.orderExchangeInfoDto}
                obj.saleOrderId = data.orderId

                if (obj.vehicle !== null) {
                    setMspr(obj.vehicle.msprPrice)
                    obj.productName = obj.vehicle.productName
                } else {
                    obj.vehicle = {
                        colorInner: "",
                        colorOut: "",
                        price: 0,
                        productId: "",
                        productName: "",
                        quantity: 1,
                        skuId: "",
                        vin: ""
                    }
                }

                // let price = 0
                let price = new BigNumber('0')
                obj.parts.forEach(item => {
                    price = price.plus(new BigNumber(item.price).multipliedBy(item.quantity))
                    if (parseFloat(obj.couponTicketDiscount) > 0) {
                        item.finalMoney = item.total
                    }
                })
                obj.partsPrice = price.toString()
                obj.typeChange = true

                common.ajax("get", "/finance/term/findByTaxonomy", {
                    taxonomy: "invoiceType",
                    status: Term.STATUS_YES,// 启用
                }).then(invoiceTypeRes => {
                    invoiceTypeRes = invoiceTypeRes.filter(item => item.status !== 2)
                    let invoiceTypeList = []

                    invoiceTypeRes.forEach(item => {
                        invoiceTypeList.push({
                            key: item.id,
                            value: item.name
                        })
                    })

                    setInvoiceType(invoiceTypeList)
                }).finally(common.loadingStop)
                common.ajax("get", "/sale/rebate/list", {
                    ownerId: common.getUser().company.id,
                    orderId: obj.id
                }).then(data1 => {
                    obj.rebates = data1.rebateDtos
                    if (obj.type !== '3' && obj.type !== '4' && obj.type !== '5') {
                        if (obj.customerType === 1) {
                            common.ajax("get", "/passport/customer/findById", {id: obj.customerId}).then(data2 => {
                                obj.customerPhone = data2.phone
                                obj.customerTypeName = data2.customerTypeName ? data2.customerTypeName : ""
                                obj.customerAddress = data2.provinceName + data2.cityName + data2.districtName + data2.address
                                obj.typeChange = true
                                setOrder({...obj})
                            }).finally(common.loadingStop)
                        } else {
                            setOrder(obj)
                        }
                    } else {
                        setOrder(obj)
                    }
                }).finally(() => {
                    common.loadingStop()
                })
                // }).finally(common.loadingStop)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 获取销售渠列表
    let getSaleTrem = () => {
        common.loadingStart();
        let params = {
            taxonomy: "channel",
            userId: common.getUser().company.id
        }
        common.ajax("get", "/sale/term/findUserTermsByTaxonomy", params).then(data => {
            data = data.filter(item => item.status !== 2)
            setSaleTrem(data)
        }).finally(() => {
            common.loadingStop();
        })
    }

    // let getTaskInfo = () => {
    //     if (match.params.taskId) {
    //         common.loadingStart()
    //         common.ajax("get", "/crm/task/findById", {id: match.params.taskId},).then(data => {
    //             setTaskInfo(data)
    //         }).finally(common.loadingStop)
    //     }
    // }
    // useEffect(getTaskInfo, [match.params.taskId])

    let getColors = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: "colorInside",
            status: 1,
            limit: 1000,
            ownerId: common.getUser().company.id
        }).then(data => {
            let terms = data.terms
            terms = terms.filter(item => item.status !== 2).map(item => item)
            common.ajax('get', '/wms/term/list', {
                taxonomy: "colorOutside",
                status: 1,
                limit: 1000,
                ownerId: common.getUser().company.id
            }).then(outData => {
                let outTerms = outData.terms
                outTerms = outTerms.filter(item => item.status !== 2).map(item => item)
                setInColors(terms)
                setOutColors(outTerms)
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    let getTypeChannels = () => {
        common.loadingStart()
        common.ajax('get', '/sale/type/channel/list', {ownerId: common.getUser().company.id}).then(data => {
            let o = {}
            data.forEach(item => {
                o = {...o, [item.typeId]: item.termDtos}
            })
            setTypeChannels(o)
        }).finally(common.loadingStop)
    }

    useEffect(getTypeChannels, [])

    useEffect(getColors, [])

    useEffect(getOrderInfo, [match.params.id])

    useEffect(getInvoiceType, [])

    useEffect(() => {
        getRebates()
        getPay()
        getSaleTrem();
    }, [])

    useEffect(() => {
        let params = {
            taxonomy: "isRequireIdcard",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/sale/setting', params).then(data => {
            if (data == null || data.flag === '1') {
                setIsIdCard(true)
            }
            params.taxonomy = 'isRequireAudit'
            common.ajax('get', '/sale/setting', params).then(data1 => {
                if (data1 == null || data1.flag === '1') {
                    setIsAudit(true)
                }
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }, [])

    function getNowTime() {
        let myDate = new Date()
        let str = myDate.getFullYear() + "-" + (myDate.getMonth() + 1).toString().padStart(2, "0") + "-"
            + myDate.getDate().toString().padStart(2, "0") + " "
            + myDate.getHours().toString().padStart(2, "0") + ":"
            + myDate.getMinutes().toString().padStart(2, "0") + ":"
            + myDate.getSeconds().toString().padStart(2, "0")

        return str.toString()
    }

    function calculationActualPayPrice() {
        if (order.type === "8" && order.vehicleSalePrice === "") {
            order.vehicleSalePrice = "0"
        }

        return new BigNumber(order.vehicleSalePrice === "" ? '0' : order.vehicleSalePrice)
            .plus(new BigNumber(order.partsSalePrice === "" ? '0' : order.partsSalePrice))
            .plus(new BigNumber(order.couponSalesPrice))
            // .minus(new BigNumber(order.couponAdvanceDiscount))
            .minus(new BigNumber(order.couponTicketDiscount))
            .minus(new BigNumber(order.useRebate === "" ? "0" : order.useRebate))
            .minus(new BigNumber(order.discount === "" ? "0" : order.discount))
            .toString()

    }

    function handleOk(e, index) {
        if (index === "cancellation") {
            setVisibleCancellation(false)
        } else if (index === "calculation") {
            setVisibleCalculation(false)
        }
    };

    function handleCancel(e, index) {
        if (index === "cancellation") {
            setVisibleCancellation(false)
        } else if (index === "calculation") {
            setVisibleCalculation(false)
        }
    };

    function save() {
        if (match.params.id) {
            toEdit()
        } else {
            toCreate()
        }
    }

    function setPartsPrice() {
        let parts = order.parts
        let partsSalePrice = order.partsSalePrice
        let partsPrice = order.partsPrice
        let total = new BigNumber('0')

        parts.forEach((item, index) => {
            if (index !== parts.length - 1) {
                let b = new BigNumber('0')
                if (item.finalMoney) {
                    b = common.numberCut(
                        new BigNumber(partsSalePrice)
                            .dividedBy(new BigNumber(partsPrice))
                            .multipliedBy(new BigNumber(item.finalMoney))
                            .toString()
                        , 2)
                } else {
                    let a = common.numberCut(
                        new BigNumber(item.price)
                            .multipliedBy(new BigNumber(item.quantity)).toString()
                        , 2)
                    if (new BigNumber(partsPrice).toFixed(2) === '0.00') {
                        b = "0.00"
                    } else {
                        b = common.numberCut(
                            new BigNumber(partsSalePrice)
                                .dividedBy(new BigNumber(partsPrice))
                                .multipliedBy(new BigNumber(a))
                                .toString(),
                            2)
                    }
                }
                item.total = parseFloat(b) < 0 ? '0' : b
                total = total.plus(new BigNumber(b))
            } else {
                if (item.finalMoney && parseFloat(order.couponTicketDiscount) > 0) {
                    let a = common.numberCut(
                        new BigNumber(partsSalePrice)
                            .minus(new BigNumber(order.couponTicketDiscount)).toString()
                        , 2)
                    item.total = common.numberCut(new BigNumber(a).minus(new BigNumber(total)).toString(), 2)
                } else {
                    item.total = common.numberCut(new BigNumber(partsSalePrice).minus(new BigNumber(total)).toString(), 2)
                }
            }

            item.quantity = item.quantity.toString()

            if (item.type === 'plan') {
                if (item.needProcess === 'Y') {
                    item.needProcess = 1
                } else if (item.needProcess === 'N') {
                    item.needProcess = 2
                }
            }
        })

        return parts
    }

    function toEdit() {
        order.actualPayPrice = calculationActualPayPrice()
        let params = {...order, id: match.params.id}
        params.parts = setPartsPrice()
        params.taskId = ''

        if (params.orderPayInfoRequest.customerProfit === '') {
            params.orderPayInfoRequest.customerProfit = '0'
        }

        if (params.orderPayInfoRequest.loanMoney === '') {
            params.orderPayInfoRequest.loanMoney = '0'
        }

        if (params.orderPayInfoRequest.loanPeriod === '') {
            params.orderPayInfoRequest.loanPeriod = '0'
        }

        if (params.orderPayInfoRequest.feeMoney === '') {
            params.orderPayInfoRequest.feeMoney = '0'
        }

        if (params.orderExchangeInfoRequest.usedCarMoney === '') {
            params.orderExchangeInfoRequest.usedCarMoney = '0'
        }

        if (params.orderExchangeInfoRequest.subsidyMoney === '') {
            params.orderExchangeInfoRequest.subsidyMoney = '0'
        }

        if (params.orderExchangeInfoRequest.commissionIncome === '') {
            params.orderExchangeInfoRequest.commissionIncome = '0'
        }

        if (params.type === "8" && params.parts.length <= 0) {
            common.alert("请选择精品")
            return
        }

        if (order.type !== "8" && order.vehicleSalePrice === "") {
            common.alert("整车实际售价必填")
            return
        }

        if (order.type === "8" && order.vehicleSalePrice === "") {
            params.vehicleSalePrice = "0"
        }

        if (isDistributor) {
            if (!order.partnerId) {
                common.alert("经销商不能为空")
                return
            }
        }

        if (order.parts.length > 0 && order.partsSalePrice === "") {
            common.alert("用品实际售价必填")
            return
        }

        // 验证精品的出库方式方式必填
        if (order.parts.length > 0) {
            let errorParts = order.parts.filter(item => item.remarkBuy === null || item.remarkBuy === '')
            if (errorParts.length > 0) {
                common.alert("精品出库方式必填")
                return false
            }

            // 出库方式为购买时 价格必须大于0
            let errorPartsByPrice = order.parts.filter(item => item.remarkBuy === SaleOrderItem.REMARK_BUY_PURCHASE && (item.remarkPrice === null || item.remarkPrice === '' || parseFloat(item.remarkPrice) <= 0))
            if (errorPartsByPrice.length > 0) {
                common.alert(errorPartsByPrice[0].productName + " 出库方式为购买,销售单价必须大于0")
                return false
            }

            order.parts.forEach(item => {
                if (item.remarkPrice === null || item.remarkPrice === '' || isNaN(item.remarkPrice)) {
                    item.remarkPrice = '0.00'
                    item.remarkTotal = '0.00'
                }
            })
        }

        if (order.parts.length <= 0 && order.partsSalePrice == '') {
            params.partsSalePrice = '0'
        }

        if (order.channelId === "0") {
            common.alert("销售渠道不能为空")
            return
        }

        if (order.payType == '') {
            common.alert("销售方式必须选择")
            return
        }

        if (order.type !== "8" && (order.vehicle == null || order.vehicle.productId == '')) {
            common.alert("车型必须选择")
            return
        }

        if (order.type !== '8' && (order.vehicle != null && order.vehicle.colorOut == '')) {
            common.alert("车型外饰不能为空")
            return
        }

        if (order.type !== '8' && (order.vehicle != null && order.vehicle.colorInner == '')) {
            common.alert("车型内饰不能为空")
            return
        }

        if (order.type !== '4' && order.customerName === "" && order.customerId === "") {
            common.alert("客户必须选择")
            return
        }

        if (order.type !== '3' && order.type !== '4' && order.type !== '5' && order.vehicleOwner === "") {
            common.alert("车主名称必填")
            return
        }

        let isPrice = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        let type = false
        let type2 = false

        order.rebates.forEach(val => {
            if (!isPrice.test(val.money)) {
                type = true
                return false
            } else if (!val.partnerId) {
                type2 = true
            }
        })

        if (type) {
            common.alert("折扣录入金额格式有误")
            return
        }

        if (type2) {
            common.alert("折扣录入请选择折扣对象")
            return;
        }

        if (order.downPayment !== "" && !isPrice.test(order.downPayment)) {
            common.alert("预防定金格式有误")
            return
        }

        let idCard = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/
        if (order.identityType === "1" && order.identityNumber !== "" && !idCard.test(order.identityNumber)) {
            common.alert("身份证号格式有误")
            return
        }

        if (order.type === "8" && order.productId === "" && order.productName === "") {
            params.vehicle = null
        }

        if (params.discount === "") {
            params.discount = "0"
        }

        if (params.useRebate === "") {
            params.useRebate = "0"
        }
        if (params.downPayment === "") {
            params.downPayment = "0"
        }

        params.deliveryDate = params.deliveryDate ? moment(params.deliveryDate).format("YYYY-MM-DD HH:mm:ss") : ""

        if (isIdCard && !params.identityNumber) {
            common.alert('证件号码必填')
            return;
        }

        params.orderId = order.saleOrderId
        common.loadingStart()
        common.ajax("post", "/sale/order/update", params).then(data => {
            common.toast("编辑成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    function toCreate() {
        order.actualPayPrice = calculationActualPayPrice()
        let params = order
        params.parts = setPartsPrice()
        params.taskId = match.params.taskId || ''

        if (params.orderPayInfoRequest.customerProfit === '') {
            params.orderPayInfoRequest.customerProfit = '0'
        }

        if (params.orderPayInfoRequest.loanMoney === '') {
            params.orderPayInfoRequest.loanMoney = '0'
        }

        if (params.orderPayInfoRequest.loanPeriod === '') {
            params.orderPayInfoRequest.loanPeriod = '0'
        }

        if (params.orderPayInfoRequest.feeMoney === '') {
            params.orderPayInfoRequest.feeMoney = '0'
        }

        if (params.orderExchangeInfoRequest.usedCarMoney === '') {
            params.orderExchangeInfoRequest.usedCarMoney = '0'
        }

        if (params.orderExchangeInfoRequest.subsidyMoney === '') {
            params.orderExchangeInfoRequest.subsidyMoney = '0'
        }

        if (params.orderExchangeInfoRequest.commissionIncome === '') {
            params.orderExchangeInfoRequest.commissionIncome = '0'
        }

        if (params.type === '5' && !params.transferId) {
            common.alert("请选择调拨单")
            return
        }

        if (params.type === "8" && params.parts.length <= 0) {
            common.alert("请选择精品")
            return
        }

        if (order.type !== "8" && order.vehicleSalePrice === "") {
            common.alert("整车实际售价必填")
            return
        }

        if (order.type === "8" && order.vehicleSalePrice === "") {
            params.vehicleSalePrice = "0"
        }

        if (order.parts.length > 0 && order.partsSalePrice === "") {
            common.alert("用品实际售价必填")
            return
        }

        // 验证精品的出库方式方式必填
        if (order.parts.length > 0) {
            let errorParts = order.parts.filter(item => item.remarkBuy === null || item.remarkBuy === '')
            if (errorParts.length > 0) {
                common.alert("精品出库方式必填")
                return false
            }

            // 出库方式为购买时 价格必须大于0
            let errorPartsByPrice = order.parts.filter(item => item.remarkBuy === SaleOrderItem.REMARK_BUY_PURCHASE && (item.remarkPrice === null || item.remarkPrice === '' || parseFloat(item.remarkPrice) <= 0))
            if (errorPartsByPrice.length > 0) {
                common.alert(errorPartsByPrice[0].productName + " 出库方式为购买,销售单价必须大于0")
                return false
            }

            order.parts.forEach(item => {
                if (item.remarkPrice === null || item.remarkPrice === '' || isNaN(item.remarkPrice)) {
                    item.remarkPrice = '0.00'
                    item.remarkTotal = '0.00'
                }
            })
        }

        if (order.parts.length <= 0 && order.partsSalePrice === '') {
            params.partsSalePrice = '0'
        }

        if (order.payType === '') {
            common.alert("销售方式必须选择")
            return
        }

        if (isDistributor) {
            if (!order.partnerId) {
                common.alert("经销商不能为空")
                return
            }
        }

        if (order.channelId === "0") {
            common.alert("销售渠道不能为空")
            return
        }

        if (order.type !== "8" && (order.vehicle === null || order.vehicle.productId === '')) {
            common.alert("车型必须选择")
            return
        }

        if (order.type !== '8' && (order.vehicle != null && order.vehicle.colorOut === '')) {
            common.alert("车型外饰不能为空")
            return
        }

        if (order.type !== '8' && (order.vehicle != null && order.vehicle.colorInner === '')) {
            common.alert("车型内饰不能为空")
            return
        }

        if (order.customerName === "" && order.customerId === "") {
            common.alert("客户必须选择")
            return
        }

        if (order.vehicleOwner === "") {
            common.alert("车主名称必填")
            return
        }

        let isPrice = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        let type = false
        let rebateType = false

        order.rebates.forEach(val => {
            if (!isPrice.test(val.money)) {
                type = true
                return false
            }
            if (!val.partnerId) {
                rebateType = true
                return false
            }
        })

        if (rebateType) {
            common.alert("折扣录入请选择折扣对象")
            return
        }

        if (type) {
            common.alert("折扣录入金额格式有误")
            return
        }

        if (order.downPayment !== "" && !isPrice.test(order.downPayment)) {
            common.alert("预防定金格式有误")
            return
        }

        let idCard = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/
        if (order.identityType === "1" && order.identityNumber !== "" && !idCard.test(order.identityNumber)) {
            common.alert("身份证号格式有误")
            return
        }

        if (order.type === "8" && order.productId === "" && order.productName === "") {
            params.vehicle = null
            setOrder({
                ...order,
                vehicle: {
                    colorInner: "",
                    colorOut: "",
                    price: 0,
                    productId: "",
                    productName: "",
                    quantity: 1,
                    skuId: "",
                    vin: ""
                }
            })
        }

        params.deliveryDate = params.deliveryDate ? moment(params.deliveryDate).format("YYYY-MM-DD HH:mm:ss") : ""

        if (params.discount === "") {
            params.discount = "0"
        }

        if (params.useRebate === "") {
            params.useRebate = "0"
        }
        if (params.downPayment === "") {
            params.downPayment = "0"
        }

        if (isIdCard && !params.identityNumber && params.customerType === 1) {
            common.alert('证件号码必填')
            return;
        }

        let items = []

        for (let i = 0; i < params.advanceCreateRequest.advanceItemList.length; i++) {
            if (!params.advanceCreateRequest.advanceItemList[i].businessCategoryId) {
                common.confirm("请选择预收款业务类别")
                return
            }

            if (!params.advanceCreateRequest.advanceItemList[i].financeCategoryId) {
                common.confirm("请选择预收款财务类别")
                return
            }

            if (params.advanceCreateRequest.advanceItemList[i].money <= 0) {
                common.confirm("预收金额必须大于0")
                return
            }
            items.push({
                'businessCategoryId': params.advanceCreateRequest.advanceItemList[i].businessCategoryId,
                'financeCategoryId': params.advanceCreateRequest.advanceItemList[i].financeCategoryId,
                'money': params.advanceCreateRequest.advanceItemList[i].money,
                'spec': params.advanceCreateRequest.advanceItemList[i].remark,
            })
        }

        params.advanceCreateRequest.items = items
        params.orderId = order.saleOrderId

        common.loadingStart()
        common.ajax("post", "/sale/order/create", params).then(data => {
            setIsSubmit(true)
            history.push("/sale/order")
            common.toast("录入成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 提交审核
    let onAudit = () => {
        common.loadingStart()
        common.ajax("post",
            "/sale/order/audit/create",
            {id: match.params.id},
            {contentType: 'application/x-www-form-urlencoded'})
            .then(() => {
                common.toast("提交审核成功")
                setIsSubmit(true)
                setAudit(true)
                setOrder({...order, status: 2})
            }).finally(common.loadingStop)
    }

    // 取消审核
    let AudioCancel = () => {
        common.confirm("确定取消审核吗?", () => {
            let id = match.params.id
            common.loadingStart()
            common.ajax('post', '/sale/audit/cancel', {id: id}, {contentType: 'application/x-www-form-urlencoded'}).then(res => {
                common.toast("取消审核成功")
                setAudit(false)
                setIsSubmit(false)
                setOrder({...order, status: 1})
            }).finally(common.loadingStop)
        })
    }

    let retreat = () => {
        common.confirm("确定作废订单吗?", () => {
            let id = match.params.id
            common.loadingStart()
            common.ajax('post', '/sale/order/retreat', {id: id}, {contentType: 'application/x-www-form-urlencoded'}).then(res => {
                common.toast("订单作废成功")
                setAudit(true)
                setIsSubmit(true)
                setOrder({...order, status: 9})
            }).finally(common.loadingStop)
        })
    }

    let toConversion = (item) => {
        common.loadingStart()
        common.ajax("post", "/sale/order/conversion", {
            id: match.params.id,
            type: item,
            customerId: order.type === '4' ? conversionInfo.customerId : order.customerId,
            vehicleOwner: order.type === '4' ? conversionInfo.vehicleOwner : order.vehicleOwner,
            customerType: order.type === '4' ? conversionInfo.customerType : order.customerType,
            vehicleOwnerPhone: order.type === '4' ? conversionInfo.vehicleOwnerPhone : order.vehicleOwnerPhone
        }).then(res => {
            common.toast("转换成功")
            if (order.type === '4') {
                setOrder({
                    ...order,
                    type: item,
                    customerId: conversionInfo.customerId,
                    customerName: conversionInfo.customerName,
                    customerType: conversionInfo.customerType,
                    vehicleOwner: conversionInfo.vehicleOwner,
                    vehicleOwnerPhone: conversionInfo.vehicleOwnerPhone
                })
            } else {
                setOrder({...order, type: item})
            }
            setConversionVisible(false)
        }).finally(common.loadingStop)
    }

    // 获取当前时间
    let getDate = () => {
        if (match.params.id) {
            return;
        }
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                setOrder({...order, deliveryDate: moment(res).format("YYYY-MM-DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    // 打印的时候使用 公司信息
    useEffect(() => {
        if (match.params.id) {
            common.loadingStart()
            common.ajax('get', '/passport/company/findById', {id: common.getUser().company.id})
                .then(res => {
                    setCompanyObj(res)
                })
                .finally(common.loadingStop)
        }
    }, [])

    // 打印的时候使用 公司信息
    useEffect(() => {
        if (match.params.id && order.saleId) {
            common.loadingStart()
            common.ajax("get", "/passport/employee/findById", {
                'id': order.saleId,
            }).then((res) => {
                setSaleMobile(res.mobile)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }, [order.saleId])

    // 打印
    let print = (printData) => {
        // 组装数据
        let data = {
            "code": "sale_contract",
            "owner_id": common.getUser().company.id,
            "data": {
                ...order,
                ...printData,
                company: {
                    ...companyObj,
                    province: companyObj.provinceName + companyObj.cityName + companyObj.areaName,
                    address: companyObj.provinceName + companyObj.cityName + companyObj.areaName + companyObj.address
                },
                partsPrice: mspr,
                brand: order.vehicle.brandName + order.vehicle.seriesName,
                productName: order.vehicle.productName,
                seriesName: order.vehicle.seriesName,
                colorOut: order.vehicle.colorOut,
                vehicleSalePriceUpper: common.moneyToChinese(order.vehicleSalePrice),//大写
                saleMobile: saleMobile,// 销售顾问手机号
                spec1: printData.spec !== "" ? ("<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）" + printData.spec + "</div>") : "",
                spec2: (order.partsRemark === "" ? "" : order.partsRemark + " | ") + order.orderItems.filter(item => item.kind === 2).map(item => item.productName).join(" "),
                date: order.createdAt != null ? order.createdAt.substring(0, 10) : "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
            }
        }
        // 备注
        if (data.data.spec2 !== "") {
            data.data.spec2 = data.data.spec1 !== "" ? (`<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（二）赠送装饰：` + data.data.spec2 + `</div>`) : (`<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）赠送装饰：` + data.data.spec2 + `</div>`)
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className="plus">
            <PageTop title={order.couponIsEdit ? "订单编辑" : "订单录入"}>
                {order.couponIsEdit && common.can('sale.order.contract.print') &&
                <Button icon={<PrinterOutlined/>} onClick={() => {
                    setModalTitle("")
                    setModalNumber(3)
                    setVisible(true)
                }}>打印</Button>}
                {common.can('wms.boutique.sku.number.query') &&
                <Button icon={<EyeOutlined/>} onClick={() => {
                    setModalTitle("精品查询")
                    setModalNumber(1)
                    setVisible(true)
                }}>精品查询</Button>}
                {common.can('wms.car.sku.query') &&
                <Button icon={<EyeOutlined/>} onClick={() => {
                    setModalTitle("车辆查询")
                    setModalNumber(2)
                    setVisible(true)
                }}>车辆查询</Button>}

                {
                    order.couponIsEdit && (order.type === "2" || order.type === '4') &&
                    <Button type='primary' onClick={() => setConversionVisible(true)}>转为客户订单</Button>
                }

                {order.couponIsEdit && order.status === 1 &&
                <Button icon={<MinusCircleOutlined/>} onClick={retreat} type="danger">订单作废</Button>
                }

                {((order.couponIsEdit && order.status === 2) || (order.couponIsEdit && order.status === 4 && order.type === "8")) &&
                <Button
                    icon={<CloseOutlined/>}
                    onClick={AudioCancel}>取消审核</Button>
                }

                {
                    order.couponIsEdit && (order.status === 1 || order.status === 12) &&
                    <Button
                        icon={<CheckOutlined/>}
                        type="primary"
                        onClick={onAudit}
                        disabled={audit}>提交审核</Button>
                }

                <Button
                    disabled={order.couponIsEdit && !(order.status === 1 || order.status === 12) ? true : isSubmit}
                    icon={<SaveOutlined/>} type="primary" onClick={save}>保存
                </Button>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                }}>返回</Button>
            </PageTop>
            <Tabs defaultActiveKey="1">
                <TabPane tab="订单录入" key="1">
                    <OrderInfo
                        order={order}
                        setOrder={setOrder}
                        saleTrem={saleTrem}
                        invoiceType={invoiceType}
                        isDistributor={isDistributor}
                        setIsDistributor={setIsDistributor}
                        setLookupAllotCarVisible={setLookupAllotCarVisible}
                        isClick={isClick}
                        setIsClick={setIsClick}
                        history={props.history}
                        payData={payData}
                        inColors={inColors}
                        outColors={outColors}
                        advanceData={advanceData}
                        setAdvanceData={setAdvanceData}
                        mspr={mspr}
                        setMspr={setMspr}
                        typeChannels={typeChannels}
                        isIdCard={isIdCard}/>
                </TabPane>
                <TabPane tab="精品录入" key="2">
                    <Articles order={order} setOrder={setOrder} isSettlement={false}/>
                </TabPane>
                <TabPane tab="销售费用" key="3">
                    <Discount order={order} setOrder={setOrder} rebates={rebates} rebateDefaultId={rebateDefaultId}
                              rebateDefaultFlag={rebateDefaultFlag} rebateFlag={rebateFlag}/>
                </TabPane>
                <TabPane tab="礼券销售" key="4">
                    <Coupon order={order} setOrder={setOrder}/>
                </TabPane>
            </Tabs>

            <div className="Home">
                <Modal
                    maskClosable={false}
                    getContainer={false}
                    title={"订单作废"}
                    closable={false}
                    visible={visibleCancellation}
                    onOk={(e) => handleOk(e, "cancellation")}
                    onCancel={(e) => handleCancel(e, "cancellation")}
                    cancelText={"关闭"}
                    okText={"保存"}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label={"订单日期"}>
                            <DatePicker/>
                        </Form.Item>
                        <Form.Item label={"客户名称"}>
                            <Input value={"张总"}/>
                        </Form.Item>
                        <Form.Item label={"订单号"}>
                            <Input value={"DC21091111111"}/>
                        </Form.Item>
                        <Form.Item label={"预收余额"}>
                            <Input value={"5000.00"}/>
                        </Form.Item>
                        <Form.Item label={"作废原因"}>
                            <Select defaultValue={"1"}>
                                <Option value={"1"}>客户退订</Option>
                                <Option value={"2"}>信息有误</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={"说明"}>
                            <TextArea placeholder="Autosize height based on content lines"/>
                        </Form.Item>
                        <Form.Item label={"操作人"}>
                            <Input value={"销售顾问"}/>
                        </Form.Item>
                        <Form.Item label={"操作时间"}>
                            <DatePicker/>
                        </Form.Item>
                    </Form>
                </Modal>
                {/*订单试算*/}
                <Modal
                    maskClosable={false}
                    // getContainer={false}
                    width="40%"
                    closable={false}
                    visible={visibleCalculation}
                    onOk={(e) => handleOk(e, "calculation")}
                    onCancel={(e) => handleCancel(e, "calculation")}
                    cancelText={"关闭"}
                    okText={"保存"}
                >
                    <Calculation/>
                </Modal>

                <Modal title={order.type !== '4' ? "订单类型" : ''}
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={conversionVisible}
                       width={800}
                       footer={null}
                       onCancel={() => {
                           setConversionVisible(false)
                       }}>

                    {
                        order.type === '4' &&
                        <div>
                            <br/>
                            <PageTop title={'订单类型'}>
                                <Button type={"primary"} onClick={() => {
                                    if (!conversionId) {
                                        common.toast("请选择订单类型")
                                        return
                                    }

                                    if (!conversionInfo.customerId) {
                                        common.toast("请选择客户")
                                        return
                                    }

                                    if (!conversionInfo.vehicleOwner) {
                                        common.toast("请填写车主名称")
                                        return
                                    }

                                    toConversion(conversionId)
                                }}>保存</Button>
                            </PageTop>
                        </div>
                    }
                    {
                        Object.keys(conversionTypeName).map(item => {
                            return <Button key={item}
                                           type={(order.type === '4' && item === conversionId) ? 'primary' : ''}
                                           style={{
                                               width: "47%",
                                               marginBottom: "15px",
                                               marginLeft: "15px",
                                               height: "50px"
                                           }} onClick={() => {
                                if (order.type === '4') {
                                    setConversionId(item)
                                } else {
                                    toConversion(item)
                                }
                            }}>{conversionTypeName[item]}</Button>
                        })
                    }

                    {
                        order.type === '4' &&
                        <div>
                            <Divider orientation="left">
                                <b>
                                    <h3>修改客户信息</h3>
                                </b>
                            </Divider>
                            <Form className={'ant-advanced-inline-form'}>
                                <Row gutter={24}>

                                    <Col span={12}>

                                        <Form.Item label="订单客户" required={true}
                                        >
                                            <Input
                                                type="text"
                                                placeholder={'选择客户'}
                                                suffix={
                                                    conversionInfo.customerName !== "" && conversionInfo.customerName !== '' ?
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setConversionInfo({
                                                                    ...conversionInfo,
                                                                    customerName: "",
                                                                    customerId: ""
                                                                })
                                                            }}/> : <span/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupCustomerVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupCustomerVisible(true)
                                                }}
                                                value={
                                                    conversionInfo.customerName
                                                }
                                            />
                                        </Form.Item>

                                    </Col>

                                    <Col span={12}>

                                        <Form.Item label="车主名称"
                                                   required={true}
                                        >
                                            <Input value={conversionInfo.vehicleOwner}
                                                   onChange={v => setConversionInfo({
                                                       ...conversionInfo,
                                                       vehicleOwner: v.target.value
                                                   })}/>
                                        </Form.Item>

                                    </Col>

                                </Row>
                            </Form>

                        </div>
                    }

                </Modal>

                <Modal title="选调拨单"
                       destroyOnClose={true}
                       visible={lookupAllotCarVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupAllotCarVisible(false)
                       }}>

                    <LookupAllotCar
                        isMultiple={false}
                        onOk={(val) => { //需要多选，则isMultiple=true
                            // setAllotVin(val.vin)
                            if (!val.goodsDto) {
                                common.toast("对方未接收")
                                return
                            } else {
                                setOrder({
                                    ...order,
                                    vehicleSalePrice: val.deliveryDto.totalCost,
                                    transferId: val.id,
                                    customerName: val.companyNameIn,
                                    customerId: val.companyIdIn,
                                    customerType: 3,
                                    productName: val.goodsDto.productDto.name,
                                    productId: val.goodsDto.productDto.id,
                                    vehicleOwner: val.companyNameIn,
                                    vehicle: {
                                        ...order.vehicle,
                                        productName: val.goodsDto.productDto.name,
                                        productId: val.goodsDto.productDto.id,
                                        vin: val.goodsDto.vin,
                                        colorOut: val.goodsDto.colorOutside,
                                        colorInner: val.goodsDto.colorInside
                                    }
                                })
                                setLookupAllotCarVisible(false)
                            }
                        }}/>

                </Modal>

                <Modal
                    maskClosable={false}
                    title="选择客户"
                    visible={lookupCustomerVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomerVisible(false)
                    }}>

                    <LookupCustomer
                        onOk={(value) => {
                            setLookupCustomerVisible(false)
                            setIsClick(true)
                            setConversionInfo({
                                ...conversionInfo,
                                customerId: value.id,
                                customerName: value.name,
                                vehicleOwner: value.name,
                                vehicleOwnerPhone: value.phone,
                                customerType: 1,
                            })
                        }}
                    />
                </Modal>

                <Modal
                    maskClosable={false}
                    title={modalTitle}
                    visible={visible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setVisible(false)
                    }}>
                    {modalNumber === 2 && <CarSkuCheck limit={10}/>}
                    {modalNumber === 1 && <ArticleSkuCheck limit={10}/>}
                    {modalNumber === 3 && <SaleContractForm
                        defaultValue={order.deliveryDate != null ? order.deliveryDate.substring(0, 10) : ""}
                        onOk={print}/>}
                </Modal>
            </div>
        </div>
    );
}

export default Plus;
