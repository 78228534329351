import {Form, Radio, Select} from "antd";
import React, {useState, useEffect} from "react";
import {Term} from "../../../components/finance/config";

import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";

function Setting() {

    const initDefaultTypeOfReceivableCustomers = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.defaultTypeOfReceivableCustomers,
        name: "收款作业默认业务大类",
        flag: Term.defaultTypeOfReceivableCustomersSupport, // 默认维修
    }

    const initCompanyReceivableInvoiceType = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.companyReceivableInvoiceType,
        name: "收款作业，默认票据类型",
        flag: "", // 默认维修
    }

    const initDefaultTypeOfPayableApply = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.defaultTypeOfPayableApply,
        name: "付款申请默认业务大类",
        flag: Term.defaultTypeOfPayableApplySale, // 默认销售
    }

    const initDefaultTypeOfPayableReview = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.defaultTypeOfPayableReview,
        name: "付款审核默认业务大类",
        flag: Term.defaultTypeOfPayableReviewSale, // 默认销售
    }

    const initDefaultTypeOfPayableCustomers = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.defaultTypeOfPayableCustomers,
        name: "支付确认默认业务大类",
        flag: Term.defaultTypeOfPayableCustomersSale, // 默认销售
    }

    const initDefaultTypeOfAdvanceBalance = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.defaultTypeOfAdvanceBalance,
        name: "预收收款默认类别",
        flag: Term.defaultTypeOfAdvanceBalanceSupport, // 默认维修
    }

    let [companyReceivableInvoiceType, setCompanyReceivableInvoiceType] = useState(initCompanyReceivableInvoiceType);
    let [defaultTypeOfReceivableCustomers, setDefaultTypeOfReceivableCustomers] = useState(initDefaultTypeOfReceivableCustomers);
    let [defaultTypeOfPayableApply, setDefaultTypeOfPayableApply] = useState(initDefaultTypeOfPayableApply)
    let [defaultTypeOfPayableReview, setDefaultTypeOfPayableReview] = useState(initDefaultTypeOfPayableReview)
    let [defaultTypeOfPayableCustomers, setDefaultTypeOfPayableCustomers] = useState(initDefaultTypeOfPayableCustomers)
    let [defaultTypeOfAdvanceBalance, setDefaultTypeOfAdvanceBalance] = useState(initDefaultTypeOfAdvanceBalance)
    let [invoiceTypeList, setInvoiceTypeList] = useState([]);// 收款作业 票据类型

    // 获取参数
    useEffect(() => {
        getDefaultTypeOfReceivableCustomers()
        getDefaultTypeOfPayableApply()
        getDefaultTypeOfPayableReview()
        getDefaultTypeOfPayableCustomers()
        getDefaultTypeOfAdvanceBalance()
    }, [])

    let getFinanceSettle = (params) => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', {
            ownerId: params.ownerId,
            taxonomy: params.taxonomy,
        }).then(data => {
            if (data != null) {
                if (params.taxonomy === companyReceivableInvoiceType.taxonomy) {
                    setCompanyReceivableInvoiceType(data);
                }
            }
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        getFinanceSettle(initCompanyReceivableInvoiceType)
    }, [])

    // 获取票据类型
    let getInvoiceType = () => {
        common.loadingStart();
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: 'invoiceType',
            status: Term.STATUS_YES,// 启用
        }).then((data) => {
            data = data.filter(item => item.status !== 2)
            setInvoiceTypeList([...data]);
        }).finally(common.loadingStop)
    }
    useEffect(getInvoiceType, [])

    let getDefaultTypeOfReceivableCustomers = () => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', defaultTypeOfReceivableCustomers)
            .then(res => {
                if (res !== null) {
                    setDefaultTypeOfReceivableCustomers({
                        ...defaultTypeOfReceivableCustomers,
                        flag: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }

    let getDefaultTypeOfPayableApply = () => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', defaultTypeOfPayableApply)
            .then(res => {
                if (res !== null) {
                    setDefaultTypeOfPayableApply({
                        ...defaultTypeOfPayableApply,
                        flag: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }

    let getDefaultTypeOfPayableReview = () => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', defaultTypeOfPayableReview)
            .then(res => {
                if (res !== null) {
                    setDefaultTypeOfPayableReview({
                        ...defaultTypeOfPayableReview,
                        flag: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }

    let getDefaultTypeOfPayableCustomers = () => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', defaultTypeOfPayableCustomers)
            .then(res => {
                if (res !== null) {
                    setDefaultTypeOfPayableCustomers({
                        ...defaultTypeOfPayableCustomers,
                        flag: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }

    let getDefaultTypeOfAdvanceBalance = () => {
        common.loadingStart()
        common.ajax('get', '/finance/setting', defaultTypeOfAdvanceBalance)
            .then(res => {
                if (res !== null) {
                    setDefaultTypeOfAdvanceBalance({
                        ...defaultTypeOfAdvanceBalance,
                        flag: parseInt(res.flag)
                    })
                }
            })
            .finally(common.loadingStop)
    }

    // 设置参数
    let setting = (params) => {
        common.loadingStart()
        common.ajax('post', '/finance/setting', params)
            .then(() => {
                common.toast("修改成功");
            })
            .finally(common.loadingStop)
    }

    return (
        <React.Fragment>
            <PageTop title={"财务业务参数设置"}/>
            <Form>

                <Form.Item label={defaultTypeOfReceivableCustomers.name}>
                    <Radio.Group value={defaultTypeOfReceivableCustomers.flag} onChange={e => {
                        setDefaultTypeOfReceivableCustomers({
                            ...defaultTypeOfReceivableCustomers,
                            flag: e.target.value
                        })
                        setting({
                            ...defaultTypeOfReceivableCustomers,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={Term.defaultTypeOfReceivableCustomersSale}
                               key={Term.defaultTypeOfReceivableCustomersSale}>销售</Radio>
                        <Radio value={Term.defaultTypeOfReceivableCustomersSupport}
                               key={Term.defaultTypeOfReceivableCustomersSupport}>维修</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label={defaultTypeOfPayableApply.name}>
                    <Radio.Group value={defaultTypeOfPayableApply.flag} onChange={e => {
                        setDefaultTypeOfPayableApply({
                            ...defaultTypeOfPayableApply,
                            flag: e.target.value
                        })
                        setting({
                            ...defaultTypeOfPayableApply,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={Term.defaultTypeOfPayableApplySale}
                               key={Term.defaultTypeOfPayableApplySale}>销售</Radio>
                        <Radio value={Term.defaultTypeOfPayableApplySupport}
                               key={Term.defaultTypeOfPayableApplySupport}>维修</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label={defaultTypeOfPayableReview.name}>
                    <Radio.Group value={defaultTypeOfPayableReview.flag} onChange={e => {
                        setDefaultTypeOfPayableReview({
                            ...defaultTypeOfPayableReview,
                            flag: e.target.value
                        })
                        setting({
                            ...defaultTypeOfPayableReview,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={Term.defaultTypeOfPayableReviewSale}
                               key={Term.defaultTypeOfPayableReviewSale}>销售</Radio>
                        <Radio value={Term.defaultTypeOfPayableReviewSupport}
                               key={Term.defaultTypeOfPayableReviewSupport}>维修</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label={defaultTypeOfPayableCustomers.name}>
                    <Radio.Group value={defaultTypeOfPayableCustomers.flag} onChange={e => {
                        setDefaultTypeOfPayableCustomers({
                            ...defaultTypeOfPayableCustomers,
                            flag: e.target.value
                        })
                        setting({
                            ...defaultTypeOfPayableCustomers,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={Term.defaultTypeOfPayableCustomersSale}
                               key={Term.defaultTypeOfPayableCustomersSale}>销售</Radio>
                        <Radio value={Term.defaultTypeOfPayableCustomersSupport}
                               key={Term.defaultTypeOfPayableCustomersSupport}>维修</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label={defaultTypeOfAdvanceBalance.name}>
                    <Radio.Group value={defaultTypeOfAdvanceBalance.flag} onChange={e => {
                        setDefaultTypeOfAdvanceBalance({
                            ...defaultTypeOfAdvanceBalance,
                            flag: e.target.value
                        })
                        setting({
                            ...defaultTypeOfAdvanceBalance,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={Term.defaultTypeOfAdvanceBalanceSale}
                               key={Term.defaultTypeOfAdvanceBalanceSale}>销售</Radio>
                        <Radio value={Term.defaultTypeOfAdvanceBalanceSupport}
                               key={Term.defaultTypeOfAdvanceBalanceSupport}>维修</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={companyReceivableInvoiceType.name}>
                    <Select
                        style={{width: 200}}
                        value={companyReceivableInvoiceType.flag}
                        onChange={val => {
                            setCompanyReceivableInvoiceType({...companyReceivableInvoiceType, flag: val})
                            setting({
                                ...companyReceivableInvoiceType,
                                flag: val
                            })
                        }}>
                        {
                            invoiceTypeList.map(item => {
                                return <Select.Option value={item.code} key={item.code}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>

            </Form>
        </React.Fragment>
    )
}

export default Setting;