import React, {useEffect, useState} from "react";
import {Button, Modal, Pagination, Table, Tooltip} from "antd";
import moment from "moment";
import ReceiptList from "./receiptList";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import PageBottom from "../../layout/PageBottom";
import {QuestionCircleOutlined,} from "@ant-design/icons";

function ReceivableTable(props) {

    const BusinessName = {1: "销售订单", 2: "维修工单", 3: "调拨出库", 4: "入库退货", 5: "增值业务", 6: "销售出库", 7: "礼券销售"};
    const {isTenant, searchNeedles, val, num} = props

    // 收款明细组件
    let [lookupVisible, setLookupVisible] = useState(false)
    let [receivableId, setReceivableId] = useState('')

    // 列表、分页
    let [dataSource, setDataSource] = useState([])
    let [total, setTotal] = useState(0);
    let [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 15,
        // isReset: false
    })

    // 页码或每页数量改变
    let handlePageChange = (currentPage, pageSize) => {
        setPagination({currentPage, pageSize})
    }

    // 收款明细列表
    let getData = () => {
        if (val == num) {
            let params = {
                page: pagination.currentPage,
                limit: pagination.pageSize,
                ...searchNeedles,
                businessType: `${searchNeedles['businessType']}`
            }
            common.loadingStart()
            common.ajax('get', '/finance/report/receivableList', params).then(data => {
                let obj = {total: '0', received: '0', notReceived: '0'}
                data.receivables.map((item, index) => {
                    item.id = index
                    Object.keys(item).map(key => {
                        if (key in obj) {
                            obj[key] = common.numberCut(new BigNumber(obj[key]).plus(new BigNumber(item[key])).toString(), 2)
                        }
                    })
                })
                data.receivables.push({
                    id: '',
                    businessOrderCode: '小计',
                    ...obj
                })
                setDataSource(data.receivables)
                setTotal(data.pagination.total)
                // setPagination({...pagination, isReset: false})
            }).finally(common.loadingStop)
        }
    }
    useEffect(getData, [pagination])

    useEffect(() => {
        // setPagination({...pagination, currentPage: 1, isReset: true})
        setPagination({...pagination, currentPage: 1})
        getData()
    }, [searchNeedles])

    const columns = [
        {
            title: '业务单号',
            dataIndex: 'businessOrderCode',
            width: 120,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '结算日期',
            dataIndex: 'createdAt',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeName',
            width: 100,
            align: 'center',
            ellipsis: true
        },
        {
            title: '应收账款',
            dataIndex: 'total',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '已收账款',
            dataIndex: 'received',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                return <Button type={'link'} onClick={() => {
                    if (record.id !== '') {
                        setLookupVisible(true)
                        setReceivableId(record.receivableId)
                    }
                }}>{common.numberFormat(text)}</Button>
            }
        },
        {
            title: '欠款金额',
            dataIndex: 'notReceived',
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '合同编号',
            dataIndex: 'supportAccountAgreementCode',
            width: 100,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '有效期限',
            dataIndex: 'supportAccountStartAt',
            width: 200,
            align: 'left',
            ellipsis: true,
            render: (text, record) => {
                let d = "";
                let startAt = record.supportAccountStartAt ? moment(record.supportAccountStartAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : "";
                let endAt = record.supportAccountEndAt ? moment(record.supportAccountEndAt, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : "";
                if (startAt !="") {
                    d = startAt + "至"
                }
                return d + endAt;
            }
        },
        {
            title: '挂帐类别',
            dataIndex: 'supportAccountCategoryName',
            width: 120,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '挂帐原因',
            dataIndex: 'supportAccountSpec',
            width: 120,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: 'kong',
            width: 50,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '客户编号',
            dataIndex: 'customerCode',
            width: 120,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '车牌号',
            dataIndex: 'plate',
            width: 100,
            align: 'left',
            ellipsis: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            width: 180,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '结算备注',
            dataIndex: 'spec',
            width: 150,
            align: 'left',
            ellipsis: true,
        },
        {
            title: '财务类别',
            dataIndex: 'financeCategoryName',
            width: 140,
            ellipsis: true,
        },
        {
            title: '挂帐说明',
            dataIndex: 'onAccountSpec',
            width: 100,
            ellipsis: true,
        },
        {
            title: '挂帐确认人',
            dataIndex: 'onAccountEmployeeName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '关联号/厂家',
            dataIndex: 'dms',
            width: 100,
            ellipsis: true,
        },
        {
            title: '应收款单号',
            dataIndex: 'receivableCode',
            width: 120,
            ellipsis: true,
        },
        {
            title: '账龄/天',
            dataIndex: 'createdDay',
            width: 80,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '账龄/段',
            dataIndex: 'createdStage',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
        {
            title: '经营主体',
            dataIndex: 'ownerName',
            width: 140,
            ellipsis: true,
        },
        {
            title: <>业务员 <Tooltip title={'对应业务单据的业务员'}><QuestionCircleOutlined/></Tooltip></>,
            dataIndex: 'businessSaleName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '业务日期',
            dataIndex: 'businessOrderCreated',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
        {
            title: '客户类型',
            dataIndex: 'customerType',
            width: 100,
            ellipsis: true,
        },
        {
            title: '客户最后一次往来日期',
            dataIndex: 'lastTimeAt',
            align: 'center',
            width: 140,
            ellipsis: true,
            render: (text, record, index) => {
                return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },
    ]

    return (
        <>

            <Table 
                rowKey={record => record.id}
                scroll={{x: '100%'}}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />

            <PageBottom
                pagination={<Pagination 
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={1}
                                current={pagination.currentPage}
                                showSizeChanger
                                defaultPageSize={pagination.pageSize}
                            />}>
            </PageBottom>

            <div>
                <Modal title="付款明细"
                       visible={lookupVisible}
                       width={1000}
                       footer={null}
                       destroyOnClose={true}
                       maskClosable={false}
                       onCancel={() => {
                           setLookupVisible(false)
                       }}>
                    <ReceiptList id={receivableId}></ReceiptList>
                </Modal>
            </div>

        </>
    )
}

export default ReceivableTable