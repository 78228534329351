import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {connect} from "react-redux";
import common from "../../../../../../utils/common";
import {Ticket} from "../../../../../../components/coupon/config";
import BigNumber from "bignumber.js";

function ReceiptIndex(props) {
    let {query} = props

    // 列表数据
    let [list2, setList2] = useState([])
    const columns2 = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '方案启用日期',
            dataIndex: 'beginAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '方案结束日期',
            dataIndex: 'endAt',
            width: 120,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '适用部门',
            dataIndex: 'departmentId',
            width: 150,
            ellipsis: true,
            render: (text) => text in Ticket.DepartmentTypeAlias ? Ticket.DepartmentTypeAlias[text] : text
        },
        {
            title: '合计售价',
            dataIndex: 'price',
            width: 150,
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '合计最大抵扣金额',
            dataIndex: 'reduceMax',
            width: 150,
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '实收预收金额',
            dataIndex: 'preTotal',
            width: 150,
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '正常回收',
            dataIndex: 'quantityUse',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '卡券核销',
            dataIndex: 'quantityApplied',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: '合计收回',
            dataIndex: 'quantityAll',
            width: 100,
            align: 'right',
            ellipsis: true,
        },
    ]

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/coupon/report/ticket/statistics", {
            ...query,
        }).then((data) => {
            if (data.length > 0) {
                let objMy = {
                    id: "-1",
                    price: 0,
                    reduceMax: 0,
                    quantityAll: 0,
                    quantityUse: 0,
                    quantityApplied: 0,
                    preTotal: 0,
                    total: 0,
                    code: '合计',
                }
                data.forEach((item) => {
                    item.quantityAll = new BigNumber(item.quantityUse).plus(item.quantityApplied).toNumber()
                    item.total = new BigNumber(item.preTotal).plus(item.preTotal).toNumber()

                    objMy.quantityAll = new BigNumber(item.quantityAll).plus(objMy.quantityAll).toNumber()
                    objMy.total = new BigNumber(item.total).plus(objMy.total).toNumber()
                    objMy.preTotal = new BigNumber(item.preTotal).plus(objMy.preTotal).toNumber()
                    objMy.quantityUse = new BigNumber(item.quantityUse).plus(objMy.quantityUse).toNumber()
                    objMy.quantityApplied = new BigNumber(item.quantityApplied).plus(objMy.quantityApplied).toNumber()
                    objMy.reduceMax = new BigNumber(item.reduceMax).plus(objMy.reduceMax).toNumber()
                    objMy.price = new BigNumber(item.price).plus(objMy.price).toNumber()
                })

                setList2([
                    ...data,
                    ...[objMy]
                ])
            } else {
                setList2([])
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 初始化页面
    useEffect(getData, [query])

    return (
        <>
            <Table
                bordered
                pagination={false}
                rowKey="id"
                columns={columns2}
                dataSource={list2}
                scroll={{x: "100%"}}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)