import React, {useState, useEffect} from "react";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Pagination, Select, Table, Row, Col, Form, DatePicker, Input, Modal, Divider} from "antd";
import moment from 'moment'
import PageBottom from "../../../../components/layout/PageBottom";
import {Link} from "react-router-dom";
import SearchArea from "../../../../components/layout/SearchArea";
import {ReloadOutlined, SearchOutlined, SelectOutlined, CloseOutlined} from '@ant-design/icons';
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import RepairHistory from "../../../../components/support/RepairHistory";
import RepairDetail from "../../../../components/support/RepairDetail";
import ExportButton from "../../../../components/export";
import Search from "../../../../utils/search";


const FormItem = Form.Item
const {RangePicker} = DatePicker;
const {Option} = Select;

const STATUS_NO = 1;
const STATUS_YES = 2;

const statusAlias = {
    [STATUS_NO]: "未回访",
    [STATUS_YES]: "已回访"
}


let initialSubtotal = {
    customerNum: 0,
    reviewNum: 0
}
const TYPE_SALE = 1;
const TYPE_SUPPORT = 2;
const TYPE_INFO = {[TYPE_SALE]: 'sale', [TYPE_SUPPORT]: 'support'}

function ReviewSupport(props) {
    const {type} = props

    let initialSearch = {
        businessCode: "",
        name: "",
        plate: "",
        status: 0,
        type: TYPE_SUPPORT,
        employeeId: "",
        phone: "",
        businessCreatedStart: "",
        businessCreatedEnd: "",
        settlementAtStart: moment().subtract(30, 'days').format("YYYY-MM-DD") + " 00:00:00",
        settlementAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        minReviewAt: "",
        maxReviewAt: "",
        minTaskAt: "",
        maxTaskAt: "",
        ownerId: common.getUser().company.id,
    }

    // let [search, setSearch] = useState(initialSearch) // 搜索输入的内容
    // let [query, setQuery] = useState(initialSearch)   // 提交ajax搜索值
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   // 提交ajax搜索值
    let [total, setTotal] = useState(0)     // 总记录条数
    let [employeeName, setEmployeeName] = useState("") // 服务顾问名称
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)    // 选择员工
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false) // 维修记录弹框
    let [repairDetailVisible, setRepairDetailVisible] = useState(false) // 维修详情弹框
    let [vin, setVin] = useState("") // 选中的车架号
    let [repairCategories, setRepairCategories] = useState([]) // 工单类型
    let [selectedRepairCode, setSelectedRepairCode] = useState("") // 选中的业务单号
    let [dataSource, setDataSource] = useState([])
    let [subTotal, setSubTotal] = useState(initialSubtotal)
    let [pagination, setPagination] = useState({page: 1, limit: 15})


    //  当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    useEffect(() => {
        common.consoleLog("query", query)
        let params = {
            ...query,
            ...pagination,
            type: type,
        }
        common.loadingStart()
        common.ajax("get", "/crm/review/listSupportReview", params)
            .then(res => {
                subTotal = {
                    customerNum: res.customerNum,
                    reviewNum: res.reviewNum
                }
                setSubTotal({...subTotal})
                setDataSource(res.reviewDtoList)
                setTotal(res.pagination.total)
                common.consoleLog("list", res)
            }).finally(common.loadingStop)
    }, [pagination, query])

    //获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                common.consoleLog(data)
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    const columns = [
        {
            title: '业务单号',
            dataIndex: 'businessCode',
            width: 120,
            ellipsis: true,
            fixed: "left"
        },
        {
            title: '工单类型',
            dataIndex: "repairCategoryName",
            width: 120,
            ellipsis: true,
            fixed: "left",
        },
        {
            title: "车牌号码",
            dataIndex: 'plate',
            width: 120,
            ellipsis: true,
            fixed: "left",
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        setSelectedRepairCode(record.businessCode)
                        setRepairDetailVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '工单备注',
            dataIndex: 'repairSpec',
            width: 200,
            ellipsis: true,
        },
        {
            title: "外饰颜色",
            dataIndex: 'color',
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text ? text : '-';
            }
        },
        {
            title: "VIN",
            dataIndex: 'vin',
            width: 200,
            ellipsis: true,
            render: text => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        setVin(text)
                        setRepairHistoryVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '品牌',
            dataIndex: "brandName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '车系',
            dataIndex: "seriesName",
            width: 100,
            ellipsis: true,
        },
        {
            title: '车型',
            dataIndex: 'productName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '送修人',
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            title: '送修人电话',
            dataIndex: "phone",
            width: 150,
            ellipsis: true,
        },
        {
            title: "购车日期",
            dataIndex: "buyDate",
            width: 100,
            ellipsis: true,
            //  render: (text) => {
            //      return text ? moment(text).format('YYYY-MM-DD') : '-';
            //  }
        },
        {
            title: "报修日期",
            dataIndex: "businessCreated",
            width: 100,
            ellipsis: true,
            //  render: (text) => {
            //      return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            //  }
        },
        {
            title: "结算日期",
            dataIndex: "settlementAt",
            width: 100,
            ellipsis: true,
            //  render: (text) => {
            //      return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            //  }
        },
        {
            title: "净产值",
            dataIndex: "actualTotal",
            width: 100,
            align: "right",
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text || 0)
            }
        },
        {
            title: "",
            width: 50,
        },
        {
            title: "业务类型",
            dataIndex: "chargeTypeNameStr",
            width: 200,
            ellipsis: true,
        },
        {
            title: "结算类别",
            dataIndex: "businessKindStr",
            width: 200,
            ellipsis: true,
        },
        {
            title: "回访备注",
            dataIndex: "spec",
            width: 100,
            ellipsis: true,
        },
        {
            title: "行驶里程",
            dataIndex: "mileage",
            width: 100,
            ellipsis: true,
            align: "right",
            render: (text) => {
                return common.numberFormat(text || 0)
            }
        },
        {
            title: "",
            width: 50,
        },
        {
            title: "保险公司",
            dataIndex: "partnerName",
            width: 200,
            ellipsis: true,
        },
        {
            title: "车主姓名",
            dataIndex: "vehicleName",
            width: 100,
            ellipsis: true,
        },
        {
            title: "车主电话",
            dataIndex: "vehiclePhone",
            width: 100,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: "customerName",
            width: 100,
            ellipsis: true,
        },
        {
            title: "客户电话",
            dataIndex: "customerPhone",
            width: 100,
            ellipsis: true,
        },
        {
            title: "发动机号",
            dataIndex: "engineNum",
            width: 150,
            ellipsis: true,
        },
        //  {
        //      title: "厂牌型号",
        //      dataIndex: "buyDate",
        //      width: 100,
        //      ellipsis: true,
        //  },
        {
            title: "状态",
            dataIndex: "statusName",
            width: 100,
            ellipsis: true,
            //  render: (text, record, index) => {
            //      return statusAlias.hasOwnProperty(text) ? statusAlias[text] : ''
            //  }
        },
        {
            title: "回访人",
            dataIndex: "reviewName",
            width: 100,
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: "回访时间",
            dataIndex: "reviewAt",
            width: 100,
            ellipsis: true,
            render: (text) => {
                return text.substring(0, 10) == "1970-01-01" ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            }
        },

        {
            title: "任务日期",
            dataIndex: "taskAt",
            width: 100,
            ellipsis: true,
            //  render: (text, record, index) => {
            //      return !text ? '' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
            //  }
        },
        {
            title: "服务顾问",
            dataIndex: "employeeName",
            width: 100,
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 100,
            fixed: 'right',
            align: 'center',
            ellipsis: true,
            render: (text, record) =>
                record.status === STATUS_YES ?
                    <Link to={`/crm/exam/detail/${TYPE_INFO[type]}/${record.id}`}>查看回访单</Link> :
                    <Link to={`/crm/exam/create/${TYPE_INFO[type]}/${record.id}`}>填写回访单</Link>
        }
    ]


    return (
        <div>
            <PageTop title={"售后回访"}>
                <ExportButton
                    filename={"售后回访.xlsx"}
                    buttonName={'数据导出'}
                    ownerId={search.ownerId}
                    params={JSON.stringify(query)}
                    type={'crm_review_support'}
                />
            </PageTop>
            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[
                                                search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                                search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                val ? setSearch({
                                                    ...search,
                                                    settlementAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    settlementAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    settlementAtStart: "",
                                                    settlementAtEnd: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"报修日期"}>
                                        <RangePicker
                                            value={[
                                                search.businessCreatedStart ? moment(search.businessCreatedStart) : null,
                                                search.businessCreatedEnd ? moment(search.businessCreatedEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                val ? setSearch({
                                                    ...search,
                                                    businessCreatedStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    businessCreatedEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    businessCreatedStart: "",
                                                    businessCreatedEnd: "",
                                                })

                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"任务时间"}>
                                        <RangePicker
                                            value={[
                                                search.minTaskAt ? moment(search.minTaskAt) : null,
                                                search.maxTaskAt ? moment(search.maxTaskAt) : null
                                            ]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                val ? setSearch({
                                                    ...search,
                                                    minTaskAt: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    maxTaskAt: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    minTaskAt: "",
                                                    maxTaskAt: "",
                                                })

                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"回访时间"}>
                                        <RangePicker
                                            value={[
                                                search.minReviewAt ? moment(search.minReviewAt) : null,
                                                search.maxReviewAt ? moment(search.maxReviewAt) : null
                                            ]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                val ? setSearch({
                                                    ...search,
                                                    minReviewAt: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                                    maxReviewAt: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                                }) : setSearch({
                                                    ...search,
                                                    minReviewAt: "",
                                                    maxReviewAt: "",
                                                })
                                            }}/>
                                    </FormItem>
                                </Col>

                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"车架号码"}>
                                        <Input placeholder={"请输入车架号码"} value={search.vin} onChange={(e) => {
                                            setSearch({...search, vin: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"}>
                                        <Input placeholder={"请输入车牌号码"} value={search.plate}
                                               onChange={(e) => {
                                                   setSearch({...search, plate: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务单号"}>
                                        <Input placeholder={"请输入业务单号"} value={search.businessCode} onChange={(e) => {
                                            setSearch({...search, businessCode: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"工单类型"}>
                                        <Select
                                            placeholder={"请选择工单类型"}
                                            value={search.repairCategoryId}
                                            onChange={(value) => {
                                                setSearch({
                                                    ...search,
                                                    repairCategoryId: value,
                                                    repairCategoryName: repairCategories.filter(item => item.id === value)[0].name || ""
                                                })
                                            }}>
                                            {repairCategories.map(item => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"送修人员"}>
                                        <Input placeholder={"请输入送修人名称"} value={search.name} onChange={(e) => {
                                            setSearch({...search, name: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车主姓名"}>
                                        <Input placeholder={"请输入车主姓名"} value={search.vehicleName}
                                               onChange={(e) => {
                                                   setSearch({...search, vehicleName: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"服务顾问"}>
                                        <Input
                                            value={employeeName}
                                            autoComplete="off"
                                            placeholder={'请选择员工名称'}
                                            readOnly
                                            suffix={
                                                employeeName !== "" ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, employeeId: ""})
                                                            setEmployeeName("")
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"联系电话"}>
                                        <Input placeholder={"请输入联系电话"} value={search.phone} onChange={(e) => {
                                            setSearch({...search, phone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"车主电话"}>
                                        <Input placeholder={"请输入车主电话"} value={search.vehiclePhone} onChange={(e) => {
                                            setSearch({...search, vehiclePhone: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>

                                <Col span={24}>
                                    <FormItem label={"单据状态"}>
                                        <Select value={search.status} onChange={(val) => {
                                            common.consoleLog(val)
                                            setSearch({...search, status: val})
                                        }}>
                                            <Option value={0} key={0}>全部</Option>
                                            {Object.keys(statusAlias).map(key =>
                                                <Option value={key} key={key}>{statusAlias[key]}</Option>
                                            )}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Col span={24}>
                                <div className={'search-btns'}>
                                    <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                        Search.setParams(search)
                                    }}>搜索
                                    </Button>
                                    <Button icon={<ReloadOutlined/>} onClick={() => {
                                        common.consoleLog("initialSearch", initialSearch)
                                        setPagination({...pagination, page: 1})
                                        setSearch(initialSearch)
                                        setQuery(initialSearch)
                                        Search.clearParams()
                                    }}>清空</Button>
                                </div>
                            </Col>
                        </Col>

                    </Row>
                </Form>
            </SearchArea>

            <Table rowKey={record => record.id}
                   scroll={{x: '100%'}}
                   dataSource={dataSource}
                   columns={columns}
                   pagination={false}
            />
            <PageBottom
                children={
                    <div>
                        台数: {subTotal.reviewNum}
                        <Divider type="vertical"/>
                        客户数: {subTotal.customerNum}
                    </div>
                }
                pagination={<Pagination
                    pageSizeOptions={['15', '30', '50', '100']}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageChange}
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    defaultCurrent={pagination.page}
                    current={pagination.page}
                    showSizeChanger
                    defaultPageSize={pagination.limit}
                />}>
            </PageBottom>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择服务顾问"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            common.consoleLog(value)
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id})
                            setEmployeeName(value.name)
                        }}
                        isMultiple={false}  // 如果想单选，传false
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="车辆维修记录"
                    visible={repairHistoryVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setRepairHistoryVisible(false)
                    }}>
                    <RepairHistory vin={vin} plate={""}/>
                </Modal>
            </div>

            {/*维修工单*/}
            <Modal title={'维修工单详情'}
                   maskClosable={false}
                   visible={repairDetailVisible}
                   footer={null} width={1000}
                   onCancel={() => {
                       setRepairDetailVisible(false)
                   }}>
                <RepairDetail code={selectedRepairCode}/>
            </Modal>

        </div>
    )
}

export default ReviewSupport

