import React from 'react'
import {Route, Switch} from "react-router-dom"
import SupportProject from "./support/project"
import SupportAchievement from "./support/achievement"
import SupportProfit from "./support/profit"
import SupportProfitSmall from "./support/profitSmall"
import SupportTotal from "./support/total"
import SupportDiscount from "./support/discount"
import SupportRepair from "./support/repair"
import SupportAppointment from "./support/appointment"
import SupportOutsource from "./support/outsource"
import Index from "./component";
import CouponTicketReportReceipt from "./coupon/ticket/index";
import CouponBalance from "./coupon/balance/index";
import CouponTicketUseReportReceipt from "./coupon/ticketUse/index";
import CouponTicketNotUseReportReceipt from "./coupon/ticketNotUse/index";
import CarReportReceipt from "./wms/car/receipt/index";
import PartReportReceipt from "./wms/part/receipt/index";
import PartReportDelivery from "./wms/part/delivery/index";
import PartReportDeliveryNew from "./wms/part/delivery/new";
import PartReportSku from "./wms/part/sku/index";
import ArticleReportReceipt from "./wms/article/receipt/index";
import ArticleReportSku from "./wms/article/sku/index";
import ArticleOrderReportDelivery from "./wms/article/order/index";
import CarReportReceiptAndDelivery from "./wms/car/receiptAndDelivery/index";
import ArticleReportReceiptAndDelivery from "./wms/article/receiptAndDelivery/index";
import PartReportReceiptAndDelivery from "./wms/part/receiptAndDelivery/index";
import CarReportDelivery from "./wms/car/delivery/index";
import ArticleReportDelivery from "./wms/article/delivery/index";
import ArticleReportDeliveryNew from "./wms/article/delivery/indexNew";
import CarReportSku from "./wms/car/sku/index";
import ArticleReportGrant from "./wms/article/grant/index";
import ArticleReportWork from "./wms/article/work/index";
import AvocationOrder from "./avocation/order";
import AvocationPremium from "./avocation/premium";
import LeadStatics from "./crm/lead"
import TaskStatics from "./crm/task"
import Sale from "./sale"
import SaleCompositeImport from "../../../components/sale/ReportSale/import"
import Review from "./crm/review";

function Business() {
    return (
        <Switch>
            <Route exact path={"/report/business/lead/statistics"} component={LeadStatics}/>
            <Route exact path={"/report/business/task/statistics"} component={TaskStatics}/>
            <Route exact path={"/report/business/sale/statics/:type"} component={Sale}/>
            <Route exact path={"/report/business/sale/statics/:type"} component={Sale}/>
            <Route exact path={"/report/business/sale/statics/:type"} component={Sale}/>
            <Route exact path={"/report/business/sale/statics/composite/import"} component={SaleCompositeImport}/>
            <Route exact path={"/report/business/crm/review/:type"} component={Review}/>
            <Route exact path={"/report/business/crm/review/:type"} component={Review}/>
            <Route exact path={"/report/business/support/appointment"} component={SupportAppointment}/>
            <Route exact path={"/report/business/avocation/order"} component={AvocationOrder}/>
            <Route exact path={"/report/business/avocation/premium"} component={AvocationPremium}/>
            <Route exact path={"/report/business/support/project"} component={SupportProject}/>
            <Route exact path={"/report/business/support/achievements"} component={SupportAchievement}/>
            <Route exact path={"/report/business/support/repair"} component={SupportRepair}/>
            <Route exact path={"/report/business/support/total"} component={SupportTotal}/>
            {/*    维修毛利核算*/}
            <Route exact path={"/report/business/support/profit"} component={SupportProfit}/>
            <Route exact path={"/report/business/support/discount"} component={SupportDiscount}/>
            <Route exact path={"/report/business/support/outsource"} component={SupportOutsource}/>
            <Route exact path={"/report/business/coupon/balance"} component={CouponBalance}/>
            <Route exact path={"/report/business/coupon/ticket"} component={CouponTicketReportReceipt}/>
            <Route exact path={"/report/business/coupon/ticket/use"} component={CouponTicketUseReportReceipt}/>
            <Route exact path={"/report/business/coupon/ticket/sent"}
                   component={CouponTicketNotUseReportReceipt}/>
            <Route exact path={"/report/business/wms/car/receipt"} component={CarReportReceipt}/>
            <Route exact path={"/report/business/wms/car/delivery"} component={CarReportDelivery}/>
            <Route exact path={"/report/business/wms/car/receiptAndDelivery"}
                   component={CarReportReceiptAndDelivery}/>
            <Route exact path={"/report/business/wms/car/sku"} component={CarReportSku}/>
            <Route exact path={"/report/business/wms/part/receipt"} component={PartReportReceipt}/>
            <Route exact path={"/report/business/wms/part/delivery"} component={PartReportDelivery}/>
            {/*    新统计*/}
            <Route exact path={"/report/business/wms/part/delivery/new"} component={PartReportDeliveryNew}/>
            {/*合计 仅输入地址可见*/}
            <Route exact path={"/report/business/wms/part/delivery/subtotal"} component={PartReportDelivery}/>

            <Route exact path={"/report/business/wms/part/sku"} component={PartReportSku}/>
            <Route exact path={"/report/business/wms/part/receiptAndDelivery"}
                   component={PartReportReceiptAndDelivery}/>
            <Route exact path={"/report/business/wms/article/receiptAndDelivery"}
                   component={ArticleReportReceiptAndDelivery}/>

            {/*    旧版 统计 /report/business/wms/article/delivery*/}
            {/*    新版 统计 /report/business/wms/article/delivery/new*/}
            <Route exact path={"/report/business/wms/article/delivery"} component={ArticleReportDelivery}/>
            <Route exact path={"/report/business/wms/article/delivery/new"} component={ArticleReportDeliveryNew}/>

            <Route exact path={"/report/business/wms/article/sku"} component={ArticleReportSku}/>
            <Route exact path={"/report/business/wms/article/order"} component={ArticleOrderReportDelivery}/>
            <Route exact path={"/report/business/wms/article/receipt"} component={ArticleReportReceipt}/>
            <Route exact path={"/report/business/wms/article/grant"} component={ArticleReportGrant}/>
            <Route exact path={"/report/business/wms/article/work"} component={ArticleReportWork}/>
            <Route exact path={"/report/business"} component={Index}/>
        </Switch>
    )
}

export default Business
