import React, {useEffect} from "react";
import {SaveOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import PageTop from "../../../components/layout/PageTop";

const FormItem = Form.Item
const {Option} = Select;


const REPAIR_CATEGORY_STATUS_ENABLE = 1; //启用
const REPAIR_CATEGORY_STATUS_DISABLE = 2; //禁用

//使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()


function RepairCategoryForm(props) {

    const [form] = Form.useForm()

    let {repairCategory, setRepairCategory, onSave} = props

    //返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (repairCategory.id === "") {
            return SCENE_NEW
        }
        return SCENE_EDIT
    }

    let onFinish = (values) => {
        return onSave()
    }

    useEffect(() => {
        form.setFieldsValue({...repairCategory})
    }, [repairCategory])

    return (
        <React.Fragment>

            <br/>
            <PageTop title={getScene() === SCENE_NEW ? "新增工单类型" : "编辑工单类型"}>
                <Button type="primary" icon={<SaveOutlined/>}
                        onClick={() => {
                            form.submit()
                        }}>保存</Button>
            </PageTop>

            <Form className={'ant-advanced-inline-form'} onFinish={onFinish} form={form} initialValues={repairCategory}>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"类型名称"}
                                  className="label-character-4"
                                  name="name"
                                  rules={[{
                                      required: true,
                                      message: "请输入类型名称"
                                  }]}>
                            <Input onChange={(e) => {
                                setRepairCategory({...repairCategory, name: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label={"标记"} className="label-character-4">
                            <Input value={repairCategory.flag} onChange={(e) => {
                                setRepairCategory({...repairCategory, flag: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>

                    <Col span={8}>
                        <FormItem label={"资料说明"}>
                            <Input value={repairCategory.spec} onChange={(e) => {
                                setRepairCategory({...repairCategory, spec: e.target.value})
                            }}/>
                        </FormItem>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <FormItem label={"类型状态"} name="status" className="label-character-4"
                                  rules={[{
                                      required: true,
                                      message: "状态不能为空",
                                  }]}>
                            <Select style={{minWidth: 1}} onChange={(val) => {
                                setRepairCategory({...repairCategory, status: val})
                            }}>
                                <Option value={REPAIR_CATEGORY_STATUS_ENABLE}>启用</Option>
                                <Option value={REPAIR_CATEGORY_STATUS_DISABLE}>禁用</Option>
                            </Select>
                        </FormItem>

                    </Col>
                    <Col span={8}>
                        <FormItem label={"序号"} className="label-character-4" name="sort"
                                  rules={[
                                      {required: true, message: "序号不能为空"},
                                  ]}>
                            <InputNumber min={0}
                                         value={repairCategory.sort}
                                         onChange={val => {
                                             setRepairCategory({...repairCategory, sort: val})
                                         }}/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            {/*补充点高度*/}
            <div style={{height: 50}}/>

        </React.Fragment>
    );
}


export default RepairCategoryForm