import React, {useEffect, useState} from "react";
import {
    CheckOutlined, CheckSquareOutlined,
    CloseOutlined,
    HistoryOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined,
    EditOutlined
    , PrinterOutlined
} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table} from "antd";
import moment from "moment";
import PageTop from "../../../components/layout/PageTop";
import common from "../../../utils/common";
import {useHistory} from "react-router-dom";
import RepairHistory from "../../../components/support/RepairHistory";
import {connect} from "react-redux";
import LookupCompany from "../../passport/LookupCompany"
import "./index.less"
import LookupEmployee from "../../passport/LookupEmployee";
import BigNumber from "bignumber.js";


const FormItem = Form.Item
const {Option} = Select

// 普通工单
// 1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
// 5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

let repairStatusTypeAlias = {
    [REPAIR_STATUS_CREATING]: '工单编辑',
    [REPAIR_STATUS_WAITING]: '等待派工',
    [REPAIR_STATUS_SUPPORTING]: '维修中',
    [REPAIR_STATUS_SUPPORTED]: '已竣工',
    [REPAIR_STATUS_PREPAID]: '订单预结',
    [REPAIR_STATUS_SETTLED]: '订单结算',
    [REPAIR_STATUS_RECEIVED]: '订单收款',
    [REPAIR_STATUS_BACK]: '已退单',
}
// 维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 5.委外维修项目 6.委外维修材料 7.委内维修项目 8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
// const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = "7";
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = "8";
//
//  let repairItemTypeAlias = {
//      [REPAIR_ITEM_TYPE_PROJECT]: '工时费用',
//      [REPAIR_ITEM_TYPE_PREDICT_PRODUCT]: '预估材料',
//      [REPAIR_ITEM_TYPE_PRODUCT]: '维修材料',
//  }
// 维修明细类型状态： 1.等待派工 2.派工中 3.已竣工
const REPAIR_ITEM_STATUS_WAITING = 10
const REPAIR_ITEM_STATUS_DISPATCH = 20
const REPAIR_ITEM_STATUS_FINISH = 30
let repairItemStatusAlias = {
    [REPAIR_ITEM_STATUS_WAITING]: '等待派工',
    [REPAIR_ITEM_STATUS_DISPATCH]: '已派工',
    [REPAIR_ITEM_STATUS_FINISH]: '已竣工',
}

function DispatchForm(props) {

    let repairId = props.id // 订单id
    let history = useHistory()
    let [projects, setProjects] = useState([])// 工时费用
    let [products, setProducts] = useState([])// 维修材料
    let [company, setCompany] = useState() // 公司信息
    let [employees, setEmployees] = useState({})// 员工
    let [groups, setGroups] = useState([])// 班组
    let [repair, setRepair] = useState({}) // 维修工单信息
    let [companyIds, setCompanyIds] = useState([])// 所有的公司
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(false)    // 维修记录弹框
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)    // 选择经营主体
    let [lookupDispatchVisible, setLookupDispatchVisible] = useState(false)    // 变更派工人
    let [rowSelectedRowKeys, setSelectedRowKeys] = useState([])
    let [selectedRows, setSelectedRows] = useState([])
    let [selectedId, setSelectedId] = useState("") // 选择维修项目工时的id
    let [dispatchVisible, setDispatchVisible] = useState(false)
    let [finishVisible, setFinishVisible] = useState(false)

    // 表单方法
    const [form] = Form.useForm();
    const [dispatchForm] = Form.useForm();
    const [finishForm] = Form.useForm();

    // 维修项目列
    const projectColumns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: 250,
            ellipsis: true,
        },
        {
            title: '维修工段',
            dataIndex: 'sectionName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '经营主体',
            dataIndex: 'ownerId',
            width: 350,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Input
                            value={record.companyName}
                            autoComplete="off"
                            readOnly
                            placeholder="选择经营主体"
                            style={{width: 200, marginRight: "10px"}}
                            addonAfter={
                                <SelectOutlined
                                    onClick={() => {
                                        selectedId = record.id
                                        setSelectedId(selectedId) // 设置选中的维修工时id
                                        setLookupCompanyVisible(true)
                                    }}/>
                            }
                        />
                        {/*<Button icon={<CheckSquareOutlined/>}*/}
                        {/*        hidden={!(index === 0 && record.companyId !== null && record.companyId !== "")}*/}
                        {/*        onClick={() => {*/}
                        {/*            setProjects(projects.map(item => {*/}
                        {/*                return {*/}
                        {/*                    ...item,*/}
                        {/*                    companyId : record.companyId,*/}
                        {/*                    companyName : record.companyName,*/}
                        {/*                }*/}
                        {/*            }))*/}
                        {/*        }}>批量*/}
                        {/*</Button>*/}
                    </div>
                )
            }
        },
        {
            title: '维修班组',
            align: 'center',
            dataIndex: 'groupName',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (repair.status >= REPAIR_STATUS_SUPPORTED) {
                    return (<div>{text}</div>)
                } else {
                    return (
                        <Select
                            value={record.groupId}
                            style={{width: 100}}
                            onChange={(val, option) => {
                                projects.forEach(item => {
                                        if (item.id === record.id) {
                                            item.groupId = val
                                            item.groupName = option.children
                                            item.workerId = ''
                                            Object.keys(employees).forEach(key => {
                                                if (key === JSON.stringify(record.companyId)) {
                                                    item.workers = employees[key].filter(employee => employee.groupId === val)
                                                }
                                            })
                                        }
                                    }
                                )
                                setProjects([...projects])
                            }}
                        >
                            <Option value="">请选择</Option>
                            {groups.map(group => (
                                record.sectionId === group.parentId && (group.companyIds.indexOf(record.companyId) !== -1) ?
                                    <Option key={group.id} value={group.id}>{group.name}</Option> : null
                            ))}
                        </Select>
                    )
                }
            }
        },
        {
            title: '主修人',
            align: 'center',
            dataIndex: 'workerName',
            width: 120,
            ellipsis: true,
            render: (text, record) => {
                if (repair.status >= REPAIR_STATUS_SUPPORTED) {
                    return (<div>{text}</div>)
                } else {
                    return (
                        <Select
                            style={{width: 100}}
                            value={record.workerId}
                            onChange={(val, option) => {
                                for (let i = 0; i < projects.length; i++) {
                                    if (projects[i].id === record.id) {
                                        projects[i].workerId = val
                                        projects[i].workerName = option.children
                                        projects[i].batchAction = true // 设置展示批量按钮为true
                                    }
                                }
                                setProjects([...projects])
                            }}>
                            <Option value="">请选择</Option>
                            {
                                record.workers.map(worker =>
                                    <Option key={worker.id} value={worker.id}>{worker.name}</Option>)
                            }
                        </Select>)
                }
            }
        },
        {
            title: '',
            width: 80,
            render: (text, record, index) => {
                return (
                    <Button icon={<CheckSquareOutlined/>}
                            hidden={!record.batchAction}
                            onClick={() => {
                                for (let i = 0; i < projects.length; i++) {
                                    if (projects[i].sectionId !== record.sectionId || i <= index) {
                                        continue
                                    }
                                    projects[i].groupId = record.groupId
                                    projects[i].groupName = record.groupName
                                    projects[i].companyId = record.companyId// 公司
                                    projects[i].companyName = record.companyName
                                    Object.keys(employees).forEach(key => {
                                        if (key === JSON.stringify(record.companyId)) {
                                            projects[i].workers = employees[key].filter(employee => employee.groupId === record.groupId)
                                        }
                                    })
                                    projects[i].workerId = record.workerId
                                    projects[i].workerName = record.workerName
                                }
                                setProjects([...projects])
                            }}>批量
                    </Button>
                )
            }
        },
        {
            title: "状态",
            align: 'center',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => (
                repairItemStatusAlias[text]
            )
        },
        {
            title: "派工日期",
            align: "center",
            dataIndex: "dispatchAt",
            width: 170,
            ellipsis: true,
            render: (text) => (text === null ? "-" : moment(text).format("YYYY-MM-DD HH:mm"))
        },
        {
            title: "竣工日期",
            align: "center",
            dataIndex: "finishAt",
            width: 170,
            ellipsis: true,
            render: (text) => (text === null ? "-" : moment(text).format("YYYY-MM-DD HH:mm"))
        },
        {
            title: '备注',
            align: 'center',
            dataIndex: 'remark',
            width: 200,
            render: (text, record) =>
                <Input.TextArea
                    style={{width: 180}}
                    defaultValue={record.remark}
                    onChange={(e => {
                        record.remark = e.target.value
                    })}
                    rows={1}
                />
        }
    ]

    // 分组
    let groupBy = (list, fn) => {
        const groups = {};
        list.forEach(function (o) {
            const group = JSON.stringify(fn(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return groups;
    }

    // 获取班组
    let getGroups = () => {
        let params = {
            taxonomy: "group",
            userIds: companyIds,
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params)
            .then((data) => {
                groups = data || []
                projects.forEach(project => {
                    if (project.groupId !== "") {
                        Object.keys(employees).forEach(key => {
                            if (key === JSON.stringify(project.companyId)) {
                                project.workers = employees[key].filter(employee => employee.groupId === project.groupId)
                            }
                        })
                    }
                })
                setGroups(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        if (companyIds.length > 0) {
            getGroups()
        }
    }, [companyIds, employees])


    useEffect(() => {
        if (companyIds.length > 0) {
            getEmployeesByCompany()
        }
    }, [companyIds])

    // 获取公司信息
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findById?id=" + common.getUser().company.id)
            .then((data) => {
                setCompany({...data})
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getCompany()
    }, [])

    // 获取公司下的所有员工
    let getEmployeesByCompany = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/list', {
            companyIds: companyIds,
            limit: 1000,
            status: 1 // 显示在职人员,离职的不显示
        }).then((data) => {
            let groupData = groupBy(data.employees, (data) => {
                return data.companyId
            })
            setEmployees(groupData)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 通过repairId获取数据
    let getRepair = () => {
        let params = {
            ownerId: common.getUser().company.id,
            id: repairId,
        }
        common.loadingStart()
        common.ajax('get', '/support/repair/findById', params)
            .then((repair) => {
                    // 如果工单的派工时间为null 就设置当前时间为派工时间
                    if (repair.dispatchAt === null) {
                        repair.dispatchAt = moment().format("YYYY-MM-DD HH:mm:ss")
                    }

                    // 获取维修项目
                    projects = []
                    let products = []
                    companyIds = []
                    let repairItems = repair.repairItems
                    for (let i = 0; i < repairItems.length; i++) {
                        // 维修材料
                        if (repairItems[i].type === REPAIR_ITEM_TYPE_PRODUCT) {
                            products.push(repairItems[i])
                        }
                        if (repairItems[i].type === REPAIR_ITEM_TYPE_PROJECT) {
                            repairItems[i].groups = [] // 设置班组
                            repairItems[i].workers = [] // 设置主修人
                            projects.push(repairItems[i])
                            // 设置经营主体
                            if (repairItems[i].workerId === "") {
                                repairItems[i].companyId = repair.ownerId
                                repairItems[i].companyName = repair.companyName
                            }
                            companyIds.push(repairItems[i].companyId)
                        }
                    }
                    setCompanyIds([...new Set(companyIds)]) // 设置公司ids
                    form.setFieldsValue({...repair})// 设置form表单
                    setProjects([...projects]) // 设置维修项目列表
                    setProducts([...products]) // 维修材料
                    setRepair({...repair}) // 设置工单信息
                }
            ).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getRepair()
    }, [])

    /**
     * 取消竣工
     * 参数 repair维修工单信息
     */
    let cancelWork = () => {
        common.loadingStart()
        common.ajax('post', '/support/repair/cancel', {
            cancelType: "work",
            id: repairId,
            ownerId: common.getUser().company.id,
        })
            .then(res => {
                    common.toast("取消竣工成功")
                    getRepair() // 重新获取工单信息
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    // 多选框
    let rowSelection = {
        fixed: true,
        selectedRowKeys: rowSelectedRowKeys,
        type: "checkbox",
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys([...selectedRowKeys])
            setSelectedRows([...selectedRows])
        }
    }

    // 保存维修明细竣工确认
    let handleRepairItemFinish = () => {
        finishForm.validateFields()
            .then(values => {
                common.loadingStart()
                common.ajax('post', '/support/repair/finish',
                    {
                        id: repairId,
                        repairItemIds: selectedRows.map(row => row.id),
                        finishAt: values.finishAt.format("YYYY-MM-DD HH:mm:ss"),
                    })
                    .then((res => {
                            common.toast("竣工成功")
                            setFinishVisible(false)
                            getRepair() // 重新获取工单信息
                        }
                    ))
                    .finally(() => {
                        common.loadingStop()
                    })
            })
    }


    // 保存派工确认
    let handleDispatch = () => {
        dispatchForm.validateFields()
            .then(values => {
                selectedRows.forEach(item => {
                    item.dispatchAt = values.dispatchAt.format("YYYY-MM-DD HH:mm:ss")
                    item.repairId = repairId
                })
                let params = {
                    id: repairId,
                    dispatchCreator: common.getUser().nickname,
                    dispatchAt: values.dispatchAt.format("YYYY-MM-DD HH:mm:ss"), // 派工时间
                    repairItems: selectedRows,
                }
                common.loadingStart()
                common.ajax('post', '/support/repair/dispatch', params)
                    .then((res => {
                            common.toast("派工成功")
                            setDispatchVisible(false)
                            getRepair() // 重新获取工单信息
                        }
                    ))
                    .finally(() => {
                        common.loadingStop()
                    })
            })
    }

    /**
     * 变更变更人
     */
    let changeDispatchCreator = (dispatchCreator) => {
        common.loadingStart()
        common.ajax('post', '/support/repair/changeDispatchCreator', {
            id: repairId,
            dispatchCreator: dispatchCreator,
        })
            .then(res => {
                    common.toast("操作成功")
                    getRepair() // 重新获取工单信息
                }
            ).finally(() => {
            common.loadingStop()
        })
    }

    // 打印
    let print = () => {
        // 如果打印预估材料 则计算预估材料费用
        let projectPrice = 0;
        for (let i = 0; i < projects.length; i++) {
            projectPrice = common.numberHalfUp(new BigNumber(projectPrice).plus(projects[i].price).toString(), 2)
        }

        let data = {
            "code": "support_dispatch",
            "owner_id": common.getUser().company.id,
            "data": {
                "logo": null,
                "repair": {
                    ...repair,
                    "address": repair.customerAddress,
                    "engineNum": "",
                    "printTime": moment().format("YYYY-MM-DD HH:mm:ss")// 打印时间
                },
                "projects": projects.sort((f1, f2) => f1.sectionId - f2.sectionId),// 按工段排序
                "products": [...products],
                "companyInfo": {
                    ...company,
                    "address": company.provinceName + company.cityName + company.areaName + company.address,
                }
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <React.Fragment>
            <PageTop title={'维修派工'}>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    history.goBack()
                }}>返回</Button>
                <Button icon={<PrinterOutlined/>}
                        onClick={() => {
                            print()
                        }}>打印</Button>
                <Button icon={<HistoryOutlined/>} disabled={repair.plate === ""} onClick={() => {
                    setRepairHistoryVisible(true)
                }}>维修记录</Button>
                <Button icon={<CheckOutlined/>}
                        hidden={!((repair.status === REPAIR_STATUS_SUPPORTING && projects.length !== 0) ||
                            (projects.length === 0))}
                        onClick={() => {
                            setFinishVisible(true)
                        }}>竣工确认</Button>
                <Button
                    type="primary"
                    onClick={() => {
                        // 检查是否选择主修人
                        for (let i = 0; i < selectedRows.length; i++) {
                            if (selectedRows[i].workerId === "") {
                                common.alert("请选择主修人员")
                                return
                            }
                        }
                        setDispatchVisible(true)
                    }}
                    icon={<SaveOutlined/>}
                    disabled={rowSelectedRowKeys.length === 0}
                    hidden={repair.status !== REPAIR_STATUS_SUPPORTING && repair.status !== REPAIR_STATUS_WAITING}
                >保存派工</Button>
                <Button icon={<CloseOutlined/>}
                        hidden={repair.status !== REPAIR_STATUS_SUPPORTED}
                        type="primary"
                        onClick={cancelWork}>取消竣工</Button>
            </PageTop>

            <Form form={form} id={"dispatch-warp"}>
                <Row>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <FormItem label={"送修人员"}>
                                    <span className="ant-form-text"> {repair.name}</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"联系电话"}>
                                    <span className="ant-form-text"> {repair.phone}</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"服务顾问"}>
                                    <span className="ant-form-text"><b>{repair.employeeName}</b></span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label="派工人员">
                                    <span className="ant-form-text"> 
                                        {repair.dispatchCreator}&nbsp;&nbsp;
                                        {
                                            repair.dispatchCreator ? <EditOutlined onClick={() => {
                                                setLookupDispatchVisible(true)
                                            }}/> : ""
                                        }
                                    </span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Col span={24}>
                            <FormItem label={"车牌号码"}>
                                <span className="ant-form-text"> <b>{repair.plate}</b></span>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label={"进厂里程"}>
                                <span className="ant-form-text"> {repair.mileage}</span>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label={"车架号码"}>
                                <span className="ant-form-text"> {repair.vin}</span>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="维修车型">
                                <span className="ant-form-text"> <b>{repair.productName}</b></span>
                            </FormItem>
                        </Col>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24}>
                                <FormItem label="工单编号">
                                    <span className="ant-form-text"><b>{repair.code}</b></span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label={"工期时间"}>
                                    <span className="ant-form-text"> {repair.schedule} 分钟</span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label="施工时长">
                                <span className="ant-form-text">
                                {
                                    (repair.dispatch === null || repair.finishAt === null
                                        ? 0
                                        : moment(repair.finishAt).diff(moment(repair.dispatchAt), 'minutes')) + " 分钟"
                                }
                                </span>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem label="工单状态">
                                    <span className="ant-form-text"><b>{repairStatusTypeAlias[repair.status]}</b></span>
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Col span={24}>
                            <Col span={24}>
                                <FormItem label={"报修日期"}>
                                <span className="ant-form-text">
                                {repair.makeAt}
                                </span>
                                </FormItem>
                            </Col>
                            <FormItem label={"派工日期"}>
                                <span className="ant-form-text">
                                {repair.dispatchAt}
                                </span>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label={"竣工日期"}>
                                <span className="ant-form-text">
                                {repair.finishAt}
                                </span>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label={"预计交车"}>
                                {/*todo*/}
                                <span className="ant-form-text">
                                       <b>{repair.estimateFinish}</b>
                                </span>
                            </FormItem>
                        </Col>
                    </Col>
                    <Col span={24}>
                        <FormItem label="工单备注" name={"spec"}>
                            <span className="ant-form-text"> {repair.spec}</span>
                        </FormItem>
                    </Col>
                </Row>

                <Table
                    rowSelection={rowSelection}
                    rowKey={record => record.id}
                    scroll={{x: '100%'}}
                    pagination={false}
                    columns={projectColumns}
                    dataSource={projects}
                />

                <Modal
                    title={'车辆维修历史'}
                    maskClosable={false}
                    visible={repairHistoryVisible}
                    footer={null}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => setRepairHistoryVisible(false)}
                >
                    <RepairHistory plate={repair.plate} vin={""}/>
                </Modal>

                {/*选择竣工日期*/}
                <Modal
                    title={'请选择竣工日期'}
                    maskClosable={false}
                    visible={finishVisible}
                    footer={null}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => setFinishVisible(false)}
                >
                    <Form form={finishForm} initialValues={{finishAt: moment()}}>
                        <Row>
                            <Col span={8}>
                                <FormItem label={"开单日期"}>
                                    <span className="ant-form-text"> {repair.makeAt}</span>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="维修车系">
                                    <span className="ant-form-text"> {repair.seriesName}</span>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label={"车架号码"} className='label-character-4'>
                                    <span className="ant-form-text"> {repair.vin}</span>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label={"进厂里程"}>
                                    <span className="ant-form-text"> {repair.mileage}</span>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label={"派工日期"}>
                                    <span className="ant-form-text"> {repair.dispatchAt}</span>
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    className='label-character-4'
                                    label={"竣工日期"}
                                    name="finishAt"
                                    rules={[
                                        {required: true, message: "竣工日期不能为空"},
                                        {
                                            validator: async (rule, value) => {
                                                if (moment(value).isBefore(form.getFieldValue('dispatchAt'))) {
                                                    throw new Error('竣工日期必须大于派工日期');
                                                }
                                            }
                                        }]}>
                                    <DatePicker
                                        disabledDate={(current) => {
                                            return current < moment(repair.dispatchAt)
                                        }}
                                        showTime style={{minWidth: 1}}
                                        format='YYYY-MM-DD HH:mm:ss'
                                        placeholder="请选择竣工日期"
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <div className={"modal-footer-btn"}>
                            <Button
                                type={"primary"}
                                onClick={handleRepairItemFinish}>确定
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </Form>

            {/*选择派工日期*/}
            <Modal
                title={'请选择派工日期'}
                maskClosable={false}
                visible={dispatchVisible}
                footer={null}
                width={1000}
                destroyOnClose={true}
                onCancel={() => setDispatchVisible(false)}>
                <Form form={dispatchForm} initialValues={{dispatchAt: moment()}}>
                    <Row>
                        <Col span={8}>
                            <FormItem label={"开单日期"}>
                                <span className="ant-form-text"> {repair.makeAt}</span>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label="维修车系">
                                <span className="ant-form-text"> {repair.seriesName}</span>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"车架号码"} className='label-character-4'>
                                <span className="ant-form-text"> {repair.vin}</span>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"进厂里程"}>
                                <span className="ant-form-text"> {repair.mileage}</span>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem label={"预计交车"}>
                                <span className="ant-form-text"> {repair.estimateFinish}</span>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                className='label-character-4'
                                label={"派工日期"}
                                name="dispatchAt"
                                rules={[
                                    {required: true, message: "派工日期不能为空"},
                                ]}>
                                <DatePicker
                                    disabledDate={(current) => {
                                        return current < moment(repair.makeAt)
                                    }}
                                    showTime style={{minWidth: 1}}
                                    format='YYYY-MM-DD HH:mm:ss'
                                    placeholder="请选择派工日期"
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <div className={"modal-footer-btn"}>
                        <Button
                            type={"primary"}
                            onClick={handleDispatch}>确定
                        </Button>
                    </div>
                </Form>
            </Modal>


            {/*经营主体*/}
            <Modal title={'经营主体'}
                   maskClosable={false}
                   visible={lookupCompanyVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupCompanyVisible(false)
                   }}>
                <LookupCompany type={'group'} isMultiple={false} onOk={(value) => {
                    companyIds = []
                    // 讲维修项目的主修人和班组 设置为空
                    projects.forEach(project => {
                        if (project.id === selectedId) {
                            project.workerId = ""
                            project.workerName = ""
                            project.groupId = ""
                            project.groupName = ""
                            project.companyId = value.id
                            project.companyName = value.name
                        }
                        companyIds.push(project.companyId)
                    })
                    setProjects([...projects])
                    setCompanyIds([...new Set(companyIds)]) // 设置公司ids
                    setLookupCompanyVisible(false)
                }}/>
            </Modal>

            {/* 变更派工人员 */}
            <Modal title="变更派工人员"
                   maskClosable={false}
                   visible={lookupDispatchVisible}
                   footer={null}
                   width={1000}
                   destroyOnClose={true}
                   onCancel={() => {
                       setLookupDispatchVisible(false)
                   }}
            >
                <LookupEmployee canChangeCompany={true} onOk={(value) => {

                    changeDispatchCreator(value.name)

                    setLookupDispatchVisible(false)
                }}/>
            </Modal>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchForm)

