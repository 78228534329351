import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, Tabs, Tooltip} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {CloseOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from "@ant-design/icons";
import common from "../../../utils/common";
import moment from "moment";
import PageBottom from "../../../components/layout/PageBottom";
import LookupEmployee from "../../../components/passport/LookupEmployee";
import LookupCompany from "../../../components/passport/LookupCompany";
import PageTop from "../../../components/layout/PageTop";
import SearchPlanButton from "../../../components/support/SearchPlanButton";
import PrepayForm from "../PrepayForm";
import ReviewDiscountForm from "../../../components/support/ReviewDiscountForm";
import LookupPartner from "../../../components/passport/LookupPartner";
import ExportButton from "../../../components/export"

const {RangePicker} = DatePicker;
const FormItem = Form.Item
const Option = Select.Option
const {TabPane} = Tabs;

//维修业务的状态 1启用 2禁用
const BUSINESS_KIND_STATUS_ENABLE = 1;

//收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3

let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}

//维修明细类型type： 1.工时费用 2.预估材料 3.维修材料 4.礼券 5.委外维修项目 6.委外维修材料 7.委内维修项目  8.委内维修材料
const REPAIR_ITEM_TYPE_PROJECT = '1';
const REPAIR_ITEM_TYPE_PREDICT_PRODUCT = '2';
const REPAIR_ITEM_TYPE_PRODUCT = '3';
const REPAIR_ITEM_TYPE_COUPON = '4';
const REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT = '5';
const REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT = '6';
const REPAIR_ITEM_TYPE_DELEGATE_PROJECT = '7';
const REPAIR_ITEM_TYPE_DELEGATE_PRODUCT = '8';
let typesAlias = {
    "all": '全部',
    "project": '工时类',
    "product": '材料类',
    "outsource": '委外类',
    "exceptOutsource": '不含委外',
}

// 绩效产值的统计数据
let initialSummaries = {
    repairNum: 0, // 维修台次（按工号计算）
    customerNum: 0, // 客户数量
    freeFeeNum: 0, // 0收费台次
    projectTotal: 0, // 工时产值
    productTotal: 0, // 材料产值
    total: 0,  // 净产值
}

//分组类型
let GROUP_TYPE_ITEM = "1"
let GROUP_TYPE_COMPANY = "2"
let GROUP_TYPE_REPAIR_CATEGORY = "3"
let GROUP_TYPE_EMPLOYEE = "4"
let GROUP_TYPE_BUSINESS_KIND = "5"
let GROUP_TYPE_CHARGE_TYPE = "6"
let GROUP_TYPE_SECTION = "7"
let GROUP_TYPE_GROUP = "8"
let GROUP_TYPE_WORKER = "9"
let GROUP_TYPE_INSURANCE_EMPLOYEE = "10"


//收费区分名称
let groupTypeAlias = {
    [GROUP_TYPE_COMPANY]: 'company',
    [GROUP_TYPE_REPAIR_CATEGORY]: 'repairCategory',
    [GROUP_TYPE_EMPLOYEE]: 'employee',
    [GROUP_TYPE_BUSINESS_KIND]: 'businessKind',
    [GROUP_TYPE_CHARGE_TYPE]: 'chargeType',
    [GROUP_TYPE_SECTION]: 'section',
    [GROUP_TYPE_GROUP]: 'group',
    [GROUP_TYPE_WORKER]: 'worker',
    [GROUP_TYPE_INSURANCE_EMPLOYEE]: 'insuranceEmployee',
}


function Achievement(props) {

    //url地址
    //url '/support/repair' String类型
    //是否是集团调用
    //isTenant ture:是     false:否
    let {url, isTenant = false} = props
    // 初始化搜索
    let initialSearch = {
        // repairCreatedAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        // repairCreatedAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        // repairCreatedAt: [moment().startOf('month'), moment().endOf('month')],
        repairCreatedAtStart: "",
        repairCreatedAtEnd: "",
        repairCreatedAt: [null, null],
        settlementAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAtEnd: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        settlementAt: [moment().startOf('month'), moment().endOf('month'),],
        workerId: "",
        groupId: "",
        departmentId: "",
        companyId: isTenant ? "" : common.getUser().company.id,
        companyIds: isTenant ? [] : [common.getUser().company.id],
        companyNames: isTenant ? "" : common.getUser().company.name,
        companyName: "",
        repairCategoryId: "",
        businessKindId: "",
        settlementStatus: 0,
        employeeId: "",
        employeeName: "",
        chargeType: 0,
        repairCode: "",
        plate: "",
        insuranceEmployee: "",
        repairItemName: "",
        customerName: "",
        phone: "",
        buyDateStart: "",
        buyDateEnd: "",
    }

    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [total, setTotal] = useState(0)     //总记录条数
    let [repairItems, setRepairItems] = useState() //设置维修明细
    let [businessKinds, setBusinessKinds] = useState([]) //业务类别
    let [groups, setGroups] = useState([]) //班组
    let [workers, setWorkers] = useState([]) //主修人
    let [repairCategories, setRepairCategories] = useState([]) //工单类型
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false) // 选择员工
    let [findPrepayVisible, setFindPrepayVisible] = useState(false)    // 查看工单预结算弹框
    let [findReviewDiscountVisible, setFindReviewDiscountVisible] = useState(false)    // 折扣录入弹框
    let [selectedRepairId, setSelectedRepairId] = useState("") //选择的业务单号
    let [summaries, setSummaries] = useState(initialSummaries) // 统计数据
    let [companies, setCompanies] = useState([]) // 跨主体
    let [tabKey, setTabKey] = useState(isTenant ? GROUP_TYPE_COMPANY : GROUP_TYPE_ITEM) //标签页的key
    let [counts, setCounts] = useState([]) // 汇总数据
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    // 选择组件
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState(false) //选择外部单位弹框
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false) //选择经营主体弹框


    // 获取绩效明细
    let getItems = () => {
        common.loadingStart()
        let params = {
            ...query,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax('get', '/report/support/achievementList', params)
            .then(res => {
                setTotal(res.pagination.total)
                // 小计
                let subtotal = {...res.subtotal, id: "小计", repairCreatedAt: "小计"}
                // 合计
                let total = {...res.total, id: "合计", repairCreatedAt: "合计"}
                //设置维修明细
                setRepairItems([...res.list, subtotal, total])
                // 重点指标
                let summaries = {
                    repairNum: res.summaries.repairNum,
                    customerNum: res.summaries.customerNum,
                    freeFeeNum: res.summaries.freeFeeNum,
                    projectTotal: res.summaries.projectTotal,
                    productTotal: res.summaries.productTotal,
                    total: res.summaries.total
                }
                setSummaries(summaries)
            }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取汇总
    let getCount = () => {
        common.loadingStart()
        let params = {
            ...query,
            type: groupTypeAlias[tabKey],
        }
        common.ajax('get', '/report/support/countAchievement', params).then(res => {
            counts = res
            setCounts([...counts])
        }).finally(() => {
            common.loadingStop()
        })
    }

    //页码发生变化就请求数据
    useEffect(() => {
        if (tabKey !== GROUP_TYPE_ITEM) {
            getCount()
        } else {
            getItems()
        }
    }, [pagination, query, tabKey])


    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }


    //获取业务类别
    let getBusinessKinds = () => {
        common.loadingStart()
        let params = {
            ownerId: "",
            status: BUSINESS_KIND_STATUS_ENABLE, //维修业务状态：1.启用
        }
        common.ajax('get', '/support/businessKind/list', params).then(res => {
            setBusinessKinds(res.businessKinds)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getBusinessKinds()  //获取业务类别
    }, [])

    useEffect(() => {
        getRepairCategories()
    }, [])

    useEffect(() => {
        getEmployees()
    }, [search.companyIds])


    //获取班组
    let getGroups = () => {
        let params = {
            taxonomy: "group",
            userIds: search.companyIds
        }
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', params).then((data) => {
            setGroups([...data])
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        if (search.companyIds.length > 0) {
            getGroups()
        }
    }, [search.companyIds])

    //获取主修人
    let getEmployees = () => {
        common.loadingStart()
        common.ajax('get', '/passport/employee/list', {
            limit: 1000,
        }).then((data) => {
            setWorkers(data.employees)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取工单类型
    let getRepairCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取当前登录员工的所掌管的公司列表
    useEffect(() => {
        if (!isTenant) {
            common.loadingStart()
            common.ajax("get", "/passport/employee/findCompaniesByAuthUserId")
                .then((res) => {
                    if (res.length !== 0) {//查询成功
                        companies = res || []
                        setCompanies([...companies])
                    }
                }).finally(() => {
                common.loadingStop()
            })
        }
    }, [])


    // 绩效产值列
    let columns = [
        {
            title: '报修日期',
            width: 120,
            dataIndex: 'repairCreatedAt',
            ellipsis: true,
            align: "center",
            render: (text) => {
                if (text === "小计" || text === "合计") {
                    return text
                }
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '结算日期',
            width: 120,
            dataIndex: 'settlementAt',
            align: 'center',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '工单类型',
            width: 120,
            dataIndex: 'repairCategoryName',
            ellipsis: true,
        },
        {
            title: '业务单号',
            width: 120,
            dataIndex: 'repairCode',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        // selectedRepairId = record.repairId
                        setSelectedRepairId(record.repairId)
                        setFindPrepayVisible(true)
                    }}>{text}</span>
                )
            }
        },
        {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '送修人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '送修人电话',
            width: 100,
            dataIndex: 'phone',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 100,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 120,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: 'VIN',
            width: 200,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '外饰颜色',
            width: 100,
            dataIndex: 'color',
            ellipsis: true,
        },
        {
            title: '购买日期',
            width: 120,
            dataIndex: 'buyDate',
            ellipsis: true,
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
            }
        },
        {
            title: '业务部门',
            width: 120,
            dataIndex: 'departmentName',
            ellipsis: true,
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '项目名称',
            width: 150,
            dataIndex: 'repairItemName',
            ellipsis: true,
        },
        {
            title: '业务类别',
            width: 120,
            dataIndex: 'businessKindName',
            ellipsis: true,
        },
        {
            title: '结算类型',
            dataIndex: 'chargeTypeName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '保险公司(定损)',
            dataIndex: 'insuranceName',
            ellipsis: true,
            width: 150,
        },
        {
            title: '维修大类',
            dataIndex: 'typeName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '维修工段',
            dataIndex: 'sectionName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '总产值',
            width: 120,
            align: 'right',
            dataIndex: 'saleTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠合计',
            width: 150,
            align: 'right',
            dataIndex: 'discount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '结算金额(净产值)',
            width: 120,
            align: 'right',
            dataIndex: 'total',
            ellipsis: true,
            render: (text, record) => {
                if (record.repairCreatedAt === "小计" || record.repairCreatedAt === "合计") {
                    return text
                } else {
                    return (
                        <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                            selectedRepairId = record.repairId
                            setSelectedRepairId(selectedRepairId)
                            setFindReviewDiscountVisible(true)
                        }}>{common.numberFormat(text)}</span>
                    )
                }
            }
        },
        {
            title: '项目成本',
            width: 120,
            align: 'right',
            dataIndex: 'receiptTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>项目毛利 <Tooltip title={`(项目毛利 ＝ 净产值－项目成本)}`}><QuestionCircleOutlined/></Tooltip>
            </div>,
            width: 120,
            align: 'right',
            dataIndex: 'profit',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: <div>毛利率 <Tooltip title={`(毛利率 ＝ 项目毛利/净产值)}`}><QuestionCircleOutlined/></Tooltip></div>,
            width: 100,
            align: 'right',
            dataIndex: 'profitRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
        {
            title: <div>项目折扣率 <Tooltip title={`(项目折扣率 ＝优惠合计/总产值)}`}><QuestionCircleOutlined/></Tooltip>
            </div>,
            width: 120,
            align: 'right',
            dataIndex: 'discountRate',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
        {
            title: '定损顾问',
            width: 120,
            dataIndex: 'insuranceEmployee',
            ellipsis: true,

        },
        {
            title: '维修班组',
            width: 120,
            dataIndex: 'groupName',
            ellipsis: true,
        },
        {
            title: '主修人',
            width: 120,
            dataIndex: 'workerName',
            ellipsis: true,
        },
        {
            title: '领料人',
            width: 120,
            dataIndex: 'collectorName',
            ellipsis: true,
        },
        {
            title: '派工人',
            width: 120,
            dataIndex: 'dispatchCreator',
            ellipsis: true,
        },
        {
            title: '手动折扣',
            width: 120,
            dataIndex: 'manualDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text || 0)
            }
        },
        {
            title: '礼券折扣',
            width: 120,
            dataIndex: 'couponDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text || 0)
            }
        },
        {
            title: '会员折扣',
            width: 120,
            dataIndex: 'cardDiscount',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text || 0)
            }
        },
        {
            title: '工时单价',
            width: 120,
            align: "right",
            dataIndex: 'dispatchPrice',
            render: text => {
                return common.numberFormat(text);
            }
        },
        {
            title: '派工工时',
            width: 120,
            align: "right",
            dataIndex: 'dispatchTime',
            render: text => {
                return common.numberFormat(text);
            }
        },
        {
            title: '工时提成',
            width: 120,
            align: "right",
            dataIndex: 'dispatchTotal',
            render: text => {
                return common.numberFormat(text);
            }
        },
    ]

    // 汇总统计列
    let reportColumns = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
        },
        {
            title: '台次',
            width: 120,
            dataIndex: 'repairNum',
            align: "right",
        },
        {
            title: '总产值',
            width: 120,
            align: "right",
            dataIndex: 'saleTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠合计',
            width: 120,
            align: "right",
            dataIndex: 'discount',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '净产值',
            width: 120,
            align: "right",
            dataIndex: 'total',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '折扣率',
            width: 120,
            align: "right",
            dataIndex: 'discountRate',
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
        {
            title: '成本',
            width: 120,
            align: "right",
            dataIndex: 'receiptTotal',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '毛利',
            width: 120,
            align: "right",
            dataIndex: 'profit',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '毛利率',
            width: 120,
            align: "right",
            dataIndex: 'profitRate',
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
    ]

    // 汇总统计列 和 派工工时
    let reportColumnsAndDispatch = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
        },
        {
            title: '台次',
            width: 120,
            dataIndex: 'repairNum',
            align: "right",
        },
        {
            title: '总产值',
            width: 120,
            align: "right",
            dataIndex: 'saleTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠合计',
            width: 120,
            align: "right",
            dataIndex: 'discount',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '净产值',
            width: 120,
            align: "right",
            dataIndex: 'total',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '折扣率',
            width: 120,
            align: "right",
            dataIndex: 'discountRate',
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
        {
            title: '成本',
            width: 120,
            align: "right",
            dataIndex: 'receiptTotal',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '毛利',
            width: 120,
            align: "right",
            dataIndex: 'profit',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '毛利率',
            width: 120,
            align: "right",
            dataIndex: 'profitRate',
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
        {
            title: '派工工时',
            width: 120,
            align: "right",
            dataIndex: 'dispatchTime',
            render: text => {
                return common.numberFormat(text);
            }
        },
        {
            title: '工时提成',
            width: 120,
            align: "right",
            dataIndex: 'dispatchTotal',
            render: text => {
                return common.numberFormat(text);
            }
        },
    ]
    let reportColumnsAndDispatchAndWorker = [
        {
            title: '',
            width: 120,
            dataIndex: 'typeName',
        },
        {
            title: '所属班组',
            width: 120,
            dataIndex: 'groupName',
            align: "right",
        },
        {
            title: '台次',
            width: 120,
            dataIndex: 'repairNum',
            align: "right",
        },
        {
            title: '总产值',
            width: 120,
            align: "right",
            dataIndex: 'saleTotal',
            ellipsis: true,
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '优惠合计',
            width: 120,
            align: "right",
            dataIndex: 'discount',
            ellipsis: true,
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: '净产值',
            width: 120,
            align: "right",
            dataIndex: 'total',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '折扣率',
            width: 120,
            align: "right",
            dataIndex: 'discountRate',
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
        {
            title: '成本',
            width: 120,
            align: "right",
            dataIndex: 'receiptTotal',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '毛利',
            width: 120,
            align: "right",
            dataIndex: 'profit',
            render: text => {
                return common.numberFormat(text)
            }
        },
        {
            title: '毛利率',
            width: 120,
            align: "right",
            dataIndex: 'profitRate',
            render: text => {
                return common.numberFormat(text) + "%";
            }
        },
        {
            title: '派工工时',
            width: 120,
            align: "right",
            dataIndex: 'dispatchTime',
            render: text => {
                return common.numberFormat(text);
            }
        },
        {
            title: '工时提成',
            width: 120,
            align: "right",
            dataIndex: 'dispatchTotal',
            render: text => {
                return common.numberFormat(text);
            }
        },
    ]

    // 班组
    let groupSelect = () => {
        return (groups.map(item => {
            return (
                <Option key={item.id} value={item.id}>{item.name}</Option>
            )
        }))
    }


    //主修人
    let workerSelect = (groupId) => {
        if (groupId === "") {
            return false
        } else {
            return (
                workers
                    .filter(item => {
                        if (item.groupId === groupId) {
                            if (search.companyIds.length === 0) {
                                return true;
                            }
                            if (search.companyIds.includes(item.companyId)) {
                                return true;
                            }
                        }
                        return false
                    })
                    .map(item => {
                        return (
                            <Option key={item.id} value={item.id}>{item.name}</Option>
                        )
                    })
            )
        }
    }

    return (
        <React.Fragment>
            <PageTop title={"绩效产值统计"}>
                <ExportButton
                    ownerId={search.companyId}
                    filename={'绩效产值统计表.xlsx'}
                    buttonName={'数据导出'}
                    params={JSON.stringify({
                        repairCreatedAtStart: query.repairCreatedAtStart,
                        repairCreatedAtEnd: query.repairCreatedAtEnd,
                        settlementAtStart: query.settlementAtStart,
                        settlementAtEnd: query.settlementAtEnd,
                        workerId: query.workerId,
                        groupId: query.groupId,
                        companyIds: query.companyIds,
                        repairCategoryId: query.repairCategoryId,
                        businessKindId: query.businessKindId,
                        employeeId: query.employeeId,
                        chargeType: query.chargeType,
                        repairCode: query.repairCode,
                        plate: query.plate,
                        insuranceEmployee: query.insuranceEmployee,
                        companyId: query.companyId,
                        types: query.types,
                        vin: query.vin,
                        insuranceId: query.insuranceId,
                        repairItemName: query.repairItemName,
                        customerName: query.customerName,
                        phone: query.phone,
                        buyDateStart: query.buyDateStart,
                        buyDateEnd: query.buyDateEnd,
                    })}
                    type={'support_report_achievements'}
                />
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        {/*日期*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[
                                                search.settlementAtStart ? moment(search.settlementAtStart) : null,
                                                search.settlementAtEnd ? moment(search.settlementAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: "",
                                                        settlementAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        settlementAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        settlementAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"报修日期"}>
                                        <RangePicker
                                            value={[
                                                search.repairCreatedAtStart ? moment(search.repairCreatedAtStart) : null,
                                                search.repairCreatedAtEnd ? moment(search.repairCreatedAtEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        repairCreatedAtStart: "",
                                                        repairCreatedAtEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        repairCreatedAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        repairCreatedAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem
                                        label={"业务单号"}>
                                        <Input value={search.repairCode} placeholder={"请输入维修单号..."}
                                               onChange={e => {
                                                   setSearch({...search, repairCode: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修班组"}>
                                        <Select value={search.groupId} onChange={(val) => {
                                            setSearch({...search, groupId: val, workerId: ""})
                                        }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                groupSelect()
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem
                                        label={"客户名称"}>
                                        <Input value={search.customerName} placeholder={"请输入客户名称..."}
                                               onChange={e => {
                                                   setSearch({...search, customerName: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        {/*类型*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"车牌号码"} className={"label-character-3"}>
                                        <Input value={search.plate} placeholder={"请输入车牌号码..."} onChange={e => {
                                            setSearch({...search, plate: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"VIN "} className={"label-character-3"}>
                                        <Input value={search.vin} placeholder={"请输入VIN..."} onChange={e => {
                                            setSearch({...search, vin: e.target.value.trim()})
                                        }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem className={"label-character-3"}
                                              label={"项目名称"}>
                                        <Input value={search.repairItemName} placeholder={"请输入项目名称..."}
                                               onChange={e => {
                                                   setSearch({...search, repairItemName: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"主修人员"} className={"label-character-3"}>
                                        <Select value={search.workerId} onChange={(val) => {
                                            setSearch({...search, workerId: val})
                                        }}>
                                            <Option value={""}>请选择</Option>
                                            {
                                                workerSelect(search.groupId)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem
                                        className={"label-character-3"}
                                        label={"联系电话"}>
                                        <Input value={search.phone} placeholder={"请输入联系电话..."}
                                               onChange={e => {
                                                   setSearch({...search, phone: e.target.value.trim()})
                                               }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        {/*车信息*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"工单类型"}>
                                        <Select value={search.repairCategoryId} onChange={(val) => {
                                            setSearch({...search, repairCategoryId: val})
                                        }}>
                                            <Option value="">全部</Option>
                                            {repairCategories.map(item =>
                                                <Option value={item.id} key={item.id}>{item.name}</Option>
                                            )}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"付费类型"}>
                                        <Select value={search.chargeType} onChange={(val) => {
                                            setSearch({...search, chargeType: val})
                                        }}>
                                            <Option value={0} key={0}>全部</Option>
                                            {
                                                Object.keys(chargeTypeAlias).map(key =>
                                                    <Option value={key} key={key}>{chargeTypeAlias[key]}</Option>)
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"维修大类"}>
                                        <Select defaultValue={"all"} onChange={(value) => {
                                            let typeList = {
                                                "all": [],
                                                "project": [REPAIR_ITEM_TYPE_PROJECT],
                                                "product": [REPAIR_ITEM_TYPE_PRODUCT],
                                                "outsource": [REPAIR_ITEM_TYPE_OUTSOURCE_PROJECT, REPAIR_ITEM_TYPE_OUTSOURCE_PRODUCT],
                                                "exceptOutsource": [REPAIR_ITEM_TYPE_PROJECT, REPAIR_ITEM_TYPE_PRODUCT],
                                            }
                                            setSearch({...search, types: typeList[value]})
                                        }}>
                                            {
                                                Object.keys(typesAlias).map(key => {
                                                    return (
                                                        <Option key={key} value={key}>{typesAlias[key]}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"业务类别"}>
                                        <Select value={search.businessKindId} onChange={(val) => {
                                            setSearch({...search, businessKindId: val})
                                        }}>
                                            <Option value={""}>全部</Option>
                                            {
                                                businessKinds.map(item => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>{item.name}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"购车日期"}>
                                        <RangePicker
                                            value={[
                                                search.buyDateStart ? moment(search.buyDateStart) : null,
                                                search.buyDateEnd ? moment(search.buyDateEnd) : null
                                            ]}
                                            onChange={(val) => {
                                                if (val === null) {
                                                    setSearch({
                                                        ...search,
                                                        buyDateStart: "",
                                                        buyDateEnd: "",
                                                    })
                                                } else {
                                                    setSearch({
                                                        ...search,
                                                        buyDateStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        buyDateEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }}/>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        {/*服务顾问*/}
                        <Col span={6}>
                            <Row>
                                <Col span={24}>
                                    <FormItem label={"服务顾问"}>
                                        <Input
                                            value={search.employeeName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择员工名称'}
                                            suffix={
                                                search.employeeName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({...search, employeeName: "", employeeId: ""})
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupEmployeeVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupEmployeeVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"定损顾问"}>
                                        <Input
                                            value={search.insuranceEmployee}
                                            autoComplete="off"
                                            placeholder={'请输入定损顾问名称'}
                                            onChange={e => {
                                                setSearch({...search, insuranceEmployee: e.target.value.trim()})
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={"保险公司"}>
                                        <Input
                                            value={search.insuranceName}
                                            readOnly
                                            autoComplete="off"
                                            placeholder={'请选择保险公司'}
                                            suffix={
                                                search.insuranceName ?
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            setSearch({
                                                                ...search,
                                                                insuranceId: "",
                                                                insuranceName: ""
                                                            })
                                                        }}/> : <span/>
                                            }
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setLookupPartnerVisible(true)
                                                    }}/>
                                            }
                                            onKeyPress={() => {
                                                setLookupPartnerVisible(true)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                {isTenant ?
                                    <Col span={24}>
                                        <FormItem label={"经营主体"}>
                                            <Input
                                                value={search.companyNames}
                                                readOnly
                                                autoComplete="off"
                                                placeholder={'请选择公司...'}
                                                suffix={
                                                    search.companyNames ?
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                setSearch({
                                                                    ...search,
                                                                    companyIds: [],
                                                                    companyNames: ""
                                                                })
                                                            }}/> : <span/>
                                                }
                                                addonAfter={
                                                    <SelectOutlined
                                                        onClick={() => {
                                                            setLookupCompanyVisible(true)
                                                        }}/>
                                                }
                                                onKeyPress={() => {
                                                    setLookupCompanyVisible(true)
                                                }}
                                            />
                                        </FormItem>
                                    </Col> : null}
                                {
                                    (search.companyIds.length > 0 && !isTenant) ?
                                        <Col span={24}>
                                            <FormItem label={"经营主体"}>
                                                <Select value={search.companyIds} onChange={(value => {
                                                    setSearch({...search, companyIds: [value]})
                                                })}>
                                                    {companies.map(company => {
                                                        return (
                                                            <Option
                                                                key={company.companyId}
                                                                value={company.companyId}>
                                                                {company.companyName}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        : null
                                }
                                <Col span={24}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                            setQuery(search)
                                            setPagination({...pagination, page: 1})
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined/>} onClick={() => {
                                            setSearch(initialSearch)
                                            setQuery(initialSearch)
                                            setPagination({...pagination, page: 1})
                                        }}>清空</Button>
                                        {/*搜索方案保存*/}
                                        <SearchPlanButton initSearch={initialSearch} url={url}
                                                          search={search} setSearch={setSearch}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Form>
            </SearchArea>
            <Tabs defaultActiveKey={tabKey} onChange={key => {
                setTabKey(key)
            }}>
                {isTenant ? <TabPane tab="按公司汇总" key={GROUP_TYPE_COMPANY}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                    />
                </TabPane> : null}
                <TabPane tab="按工单类型汇总" key={GROUP_TYPE_REPAIR_CATEGORY}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                    />
                </TabPane>
                <TabPane tab="按服务顾问汇总" key={GROUP_TYPE_EMPLOYEE}>
                    <Table dataSource={counts}
                           pagination={false}
                           scroll={{x: "100%"}}
                           columns={reportColumns}
                           rowKey={record => record.typeId}>
                    </Table>
                </TabPane>
                <TabPane tab="按业务类别汇总" key={GROUP_TYPE_BUSINESS_KIND}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                    />
                </TabPane>
                <TabPane tab="按结算类别汇总" key={GROUP_TYPE_CHARGE_TYPE}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                    />
                </TabPane>
                <TabPane tab="按工段汇总" key={GROUP_TYPE_SECTION}>
                    <Table
                        columns={reportColumnsAndDispatch}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                    />
                </TabPane>
                <TabPane tab="按班组汇总" key={GROUP_TYPE_GROUP}>
                    <Table
                        columns={reportColumnsAndDispatch}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        // bordered
                    />
                </TabPane>
                <TabPane tab="按主修人汇总" key={GROUP_TYPE_WORKER}>
                    <Table
                        columns={reportColumnsAndDispatchAndWorker}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        // bordered
                    />
                </TabPane>
                <TabPane tab="按定损顾问汇总" key={GROUP_TYPE_INSURANCE_EMPLOYEE}>
                    <Table
                        columns={reportColumns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.typeId}
                        dataSource={counts}
                        // bordered
                    />
                </TabPane>
                <TabPane tab="绩效产值明细" key={GROUP_TYPE_ITEM}>
                    <Table
                        columns={columns}
                        pagination={false}
                        scroll={{x: "100%"}}
                        rowKey={record => record.id}
                        dataSource={repairItems}
                        bordered/>
                    <PageBottom
                        className={"page-bottom-pagination"}
                        children={<div>
                            <div>
                        <span style={{
                            display: "inline-block",
                            width: "150px"
                        }}>维修台次：{summaries.repairNum}</span>
                                <span style={{
                                    display: "inline-block",
                                    width: "150px"
                                }}>0收费台次：{summaries.freeFeeNum}</span>
                                <span style={{
                                    display: "inline-block",
                                    width: "150px"
                                }}>客户数量：{summaries.customerNum}</span>
                            </div>
                            <div>
                        <span style={{
                            display: "inline-block",
                            width: "150px"
                        }}>净产值：{common.numberFormat(summaries.total)}</span>
                                <span style={{
                                    display: "inline-block",
                                    width: "150px"
                                }}>工时净产值：{common.numberFormat(summaries.projectTotal)}</span>
                                <span style={{
                                    display: "inline-block",
                                    width: "150px"
                                }}>材料净产值：{common.numberFormat(summaries.productTotal)}</span>
                            </div>
                        </div>}
                        pagination={
                            <Pagination
                                pageSizeOptions={['15', '30', '50', '100']}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={total}
                                defaultCurrent={pagination.page}
                                current={pagination.page}
                                showSizeChanger
                                defaultPageSize={pagination.limit}
                            />
                        }>
                    </PageBottom>
                </TabPane>
            </Tabs>

            <div>
                <Modal
                    maskClosable={false}
                    title="选择服务顾问"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>
                    <LookupEmployee
                        isMultiple={false}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setSearch({...search, employeeId: value.id, employeeName: value.name})
                        }}
                    />
                </Modal>
            </div>

            {/*维修工单预结算*/}
            <div>
                <Modal
                    maskClosable={false}
                    visible={findPrepayVisible}
                    footer={null}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => {
                        setFindPrepayVisible(false)
                    }}>
                    <br/>
                    <PrepayForm repairId={selectedRepairId}/>
                </Modal>
            </div>

            {/*维修工单折扣录入*/}
            <div>
                <Modal
                    title={"折扣录入详情"}
                    maskClosable={false}
                    visible={findReviewDiscountVisible}
                    footer={null}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => {
                        setFindReviewDiscountVisible(false)
                    }}>
                    <ReviewDiscountForm
                        products={[]}
                        projects={[]}
                        repairId={selectedRepairId}
                        onOk={() => {
                            setFindReviewDiscountVisible(false)
                        }}/>
                </Modal>
            </div>

            {/*选择外部单位*/}
            <div>
                <Modal
                    title={'选择外部单位'}
                    maskClosable={false}
                    visible={lookupPartnerVisible}
                    footer={null}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => setLookupPartnerVisible(false)}>
                    {/* 1只获取集团的 2只获取公司的 3都获取 const DATASOURCE_TENANT = 1  //集团数据 */}
                    <LookupPartner dataSource={3} onOk={value => {
                        setSearch({...search, insuranceId: value.id, insuranceName: value.name})
                        setLookupPartnerVisible(false)
                    }}/>
                </Modal>
            </div>

            {/*选择经营主体*/}
            <div>
                <Modal
                    title={'选择经营主体'}
                    maskClosable={false}
                    visible={lookupCompanyVisible}
                    footer={null}
                    width={1000}
                    destroyOnClose={true}
                    onCancel={() => setLookupCompanyVisible(false)}>
                    <LookupCompany type="group" isMultiple={true} onOk={value => {
                        let companyIds = value.map(item => item.id)
                        let companyNames = value.map(item => item.nameShort).join(' ,')
                        setSearch({...search, companyIds: companyIds, companyNames: companyNames})
                        setLookupCompanyVisible(false)
                    }}/>
                </Modal>
            </div>

        </React.Fragment>
    )
}

export default Achievement