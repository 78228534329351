import React, {useEffect, useState} from "react";
import {Col, Form, Input, Modal, Radio, Row, Select} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";
import {Term} from "../../../components/sale/config";
import {Term as WmsTerm} from "../../../components/wms/config";

const FormItem = Form.Item
const {Option} = Select;

function Setting() {
    const initialInput = {
        ownerId: common.getUser().company.id,
        taxonomy: Term.TAXONOMY_IS_UPDATE_SALE,
        name: "开单是否可以选择销售顾问",
        flag: Term.SETTING_NO, // 默认为否
        url: "sale"
    }

    const initialProductCarSupplier = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.PRODUCT_CAR_SUPPLIER,
        name: "新增车型时，供应厂商默认值",
        flag: "", // 默认为无
        spec: "", // 默认为无
        url: "wms"
    }

    const initialCarSaleOrderMatchCar = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_SALE_ORDER_MATCH_PRODUCT,
        name: "车辆管理，订单查询配车出库时限定为订单车型",
        flag: WmsTerm.SETTING_YES, // 默认是
        spec: "", // 默认为无
        url: "wms"
    }

    const initialCarReceiptBank = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_RECEIPT_BANK,
        name: "新车入库时，委贷单位默认值",
        flag: "", // 默认为无
        spec: "", // 默认为无
        url: "wms"
    }

    const initialCarReceiptManufacturer = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_RECEIPT_MANUFACTURER,
        name: "新车入库时，生产厂商默认值",
        flag: "", // 默认为无
        spec: "", // 默认为无
        url: "wms"
    }

    const initialCarReceiptSupplier = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_RECEIPT_SUPPLIER,
        name: "新车入库时，供货商默认值",
        flag: "", // 默认为无
        spec: "", // 默认为无
        url: "wms"
    }

    const initialCarReceiptTax = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_RECEIPT_TAX,
        name: "新车入库时，发票税率默认值",
        flag: "", // 默认为无
        spec: "", // 默认为无
    }

    const initialCarReceiptGoodsKind = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_RECEIPT_GOODS_KIND,
        name: "新车入库时，车辆类别默认值",
        flag: "", // 默认为无
        spec: "", // 默认为无
        url: "wms"
    }

    const initialCarReceiptInvoiceType = {
        ownerId: common.getUser().company.id,
        taxonomy: WmsTerm.CAR_RECEIPT_INVOICE_TYPE,
        name: "新车入库时，车辆类别默认值",
        flag: "", // 默认为无
        spec: "", // 默认为无
        url: "wms"
    }

    let [inputTaxonomy, setInputTaxonomy] = useState(initialInput)
    let [productCarSupplier, setProductCarSupplier] = useState(initialProductCarSupplier)
    let [carSaleOrderMatchCar, setCarSaleOrderMatchCar] = useState(initialCarSaleOrderMatchCar)
    let [carReceiptBank, setCarReceiptBank] = useState(initialCarReceiptBank)
    let [carReceiptManufacturer, setCarReceiptManufacturer] = useState(initialCarReceiptManufacturer)
    let [carReceiptSupplier, setCarReceiptSupplier] = useState(initialCarReceiptSupplier)
    let [carReceiptTax, setCarReceiptTax] = useState(initialCarReceiptTax)
    let [carReceiptGoodsKind, setCarReceiptGoodsKind] = useState(initialCarReceiptGoodsKind)
    let [carReceiptInvoiceType, setCarReceiptInvoiceType] = useState(initialCarReceiptInvoiceType)
    let [taxData, setTaxData] = useState([]) // 税率列表
    let [invoiceTypeData, setInvoiceTypeData] = useState([]) // 发票类型
    let [visible, setVisible] = useState(false)
    let [modalTitle, setModalTitle] = useState('') // 模态框名称
    let [modalNumber, setModalNumber] = useState(0) // 模态框选择

    // 更改数据
    let changeData = (params) => {
        common.loadingStart()
        common.ajax('post', params.url === 'sale' ? '/sale/setting' : '/wms/setting', params)
            .then(() => {
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    // 获取数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax('get', params.url === 'sale' ? '/sale/setting' : '/wms/setting', {
            taxonomy: params.taxonomy,
            ownerId: params.ownerId
        })
            .then(res => {
                if (res !== null) {
                    if (params.taxonomy === inputTaxonomy.taxonomy) {
                        setInputTaxonomy({
                            ...inputTaxonomy,
                            flag: res.flag
                        })
                    } else if (params.taxonomy === carReceiptGoodsKind.taxonomy) {
                        setCarReceiptGoodsKind({
                            ...carReceiptGoodsKind,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    } else if (params.taxonomy === carReceiptInvoiceType.taxonomy) {
                        setCarReceiptInvoiceType({
                            ...carReceiptInvoiceType,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    } else if (params.taxonomy === carReceiptTax.taxonomy) {
                        setCarReceiptTax({
                            ...carReceiptTax,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    } else if (params.taxonomy === productCarSupplier.taxonomy) {
                        setProductCarSupplier({
                            ...productCarSupplier,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    } else if (params.taxonomy === carSaleOrderMatchCar.taxonomy) {
                        setCarSaleOrderMatchCar({
                            ...carSaleOrderMatchCar,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    } else if (params.taxonomy === carReceiptBank.taxonomy) {
                        setCarReceiptBank({
                            ...carReceiptBank,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    } else if (params.taxonomy === carReceiptManufacturer.taxonomy) {
                        setCarReceiptManufacturer({
                            ...carReceiptManufacturer,
                            flag: res.flag,
                            spec: res.spec,
                        })
                    }
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getData(inputTaxonomy)
        getData(initialProductCarSupplier)
        getData(initialCarReceiptBank)
        getData(initialCarReceiptManufacturer)
        getData(initialCarReceiptSupplier)
        getData(initialCarReceiptTax)
        getData(initialCarReceiptGoodsKind)
        getData(initialCarReceiptInvoiceType)
        getData(initialCarSaleOrderMatchCar)
    }, [])

    let getTermData = (params) => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: params,
            status: Term.TERM_STATUS_ENABLE,
            ownerId: common.getUser().company.id,
            limit: 500
        }).then(data => {
            if (params === Term.TAXONOMY_RECEIPT_TAX) {
                setTaxData(data.terms)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 获取发票类型
    let getInvoiceTypeData = () => {
        common.loadingStart();
        common.ajax("get", "/finance/term/findByTaxonomy", {
            taxonomy: "invoiceType",
        }).then((data) => {
            setInvoiceTypeData(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        getInvoiceTypeData()
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"销售业务参数设置"}/>
            <Form>
                <FormItem label={inputTaxonomy.name}>
                    <Radio.Group value={inputTaxonomy.flag} onChange={e => {
                        changeData({
                            ...inputTaxonomy,
                            flag: e.target.value
                        })
                        setInputTaxonomy({
                            ...inputTaxonomy,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={Term.SETTING_YES} key={Term.SETTING_YES}>是</Radio>
                        <Radio value={Term.SETTING_NO} key={Term.SETTING_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>

                {/*车辆管理，订单查询配车出库时限定为订单车型*/}
                <FormItem label={carSaleOrderMatchCar.name}>
                    <Radio.Group value={carSaleOrderMatchCar.flag} onChange={e => {
                        changeData({
                            ...carSaleOrderMatchCar,
                            flag: e.target.value
                        })
                        setCarSaleOrderMatchCar({
                            ...carSaleOrderMatchCar,
                            flag: e.target.value
                        })
                    }}>
                        <Radio value={WmsTerm.SETTING_YES} key={WmsTerm.SETTING_YES}>是</Radio>
                        <Radio value={WmsTerm.SETTING_NO} key={WmsTerm.SETTING_NO}>否</Radio>
                    </Radio.Group>
                </FormItem>

            {/*    <FormItem*/}
            {/*        label={carReceiptGoodsKind.name}>*/}
            {/*        <Select value={carReceiptGoodsKind.spec} onChange={(val) => {*/}
            {/*            changeData({*/}
            {/*                ...carReceiptGoodsKind,*/}
            {/*                flag: Goods.KindsAlias[Goods.KIND_AUTOMATIC_CATCH],*/}
            {/*                spec: val*/}
            {/*            })*/}
            {/*            setCarReceiptGoodsKind({*/}
            {/*                ...carReceiptGoodsKind,*/}
            {/*                flag: Goods.KindsAlias[Goods.KIND_AUTOMATIC_CATCH],*/}
            {/*                spec: val*/}
            {/*            })*/}
            {/*        }}>*/}
            {/*            <Option value={Goods.KIND_AUTOMATIC_CATCH}*/}
            {/*                    key={Goods.KIND_AUTOMATIC_CATCH}>{Goods.KindsAlias[Goods.KIND_AUTOMATIC_CATCH]}</Option>*/}
            {/*            <Option value={Goods.KIND_MANUAL_GEAR}*/}
            {/*                    key={Goods.KIND_MANUAL_GEAR}>{Goods.KindsAlias[Goods.KIND_MANUAL_GEAR]}</Option>*/}
            {/*            <Option value={Goods.KIND_TIPTRONIC}*/}
            {/*                    key={Goods.KIND_TIPTRONIC}>{Goods.KindsAlias[Goods.KIND_TIPTRONIC]}</Option>*/}
            {/*        </Select>*/}
            {/*    </FormItem>*/}

            {/*    <FormItem*/}
            {/*        label={carReceiptTax.name}>*/}
            {/*        <Select value={carReceiptTax.spec} onChange={(val) => {*/}
            {/*            changeData({*/}
            {/*                ...carReceiptTax,*/}
            {/*                flag: Goods.KindsAlias[Goods.KIND_AUTOMATIC_CATCH],*/}
            {/*                spec: val*/}
            {/*            })*/}
            {/*            setCarReceiptTax({*/}
            {/*                ...carReceiptTax,*/}
            {/*                flag: Goods.KindsAlias[Goods.KIND_AUTOMATIC_CATCH],*/}
            {/*                spec: val*/}
            {/*            })*/}
            {/*        }}>*/}
            {/*            {*/}
            {/*                taxData.map((item) => {*/}
            {/*                    return <Option*/}
            {/*                        value={item.name}*/}
            {/*                        key={item.id}>{item.tax + '%'}</Option>*/}
            {/*                })*/}
            {/*            }*/}
            {/*        </Select>*/}
            {/*    </FormItem>*/}

            {/*    <FormItem*/}
            {/*        label={carReceiptInvoiceType.name}>*/}
            {/*        <Select value={carReceiptInvoiceType.spec} onChange={(val) => {*/}
            {/*            changeData({*/}
            {/*                ...carReceiptInvoiceType,*/}
            {/*                flag: val,*/}
            {/*                spec: val*/}
            {/*            })*/}
            {/*            setCarReceiptInvoiceType({*/}
            {/*                ...carReceiptInvoiceType,*/}
            {/*                flag: val,*/}
            {/*                spec: val*/}
            {/*            })*/}
            {/*        }}>*/}
            {/*            {*/}
            {/*                invoiceTypeData.map((item) => {*/}
            {/*                    return <Option value={item.id} key={item.id}>{item.name}</Option>*/}
            {/*                })*/}
            {/*            }*/}
            {/*        </Select>*/}
            {/*    </FormItem>*/}

            {/*    <Form.Item label={carReceiptSupplier.name}>*/}
            {/*        <Input*/}
            {/*            style={{width: '300px'}}*/}
            {/*            readOnly={true}*/}
            {/*            value={carReceiptSupplier.spec}*/}
            {/*            autoComplete={"off"}*/}
            {/*            onKeyPress={() => {*/}
            {/*                setModalTitle("选择供货商")*/}
            {/*                setModalNumber(1)*/}
            {/*                setVisible(true)*/}
            {/*            }}*/}
            {/*            suffix={carReceiptSupplier.spec !== "" ?*/}
            {/*                <CloseOutlined type={'close'} onClick={() => {*/}
            {/*                    setCarReceiptSupplier({*/}
            {/*                        ...carReceiptSupplier,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                    changeData({*/}
            {/*                        ...carReceiptSupplier,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                }}/> : <span/>}*/}
            {/*            addonAfter={<SelectOutlined onClick={() => {*/}
            {/*                setModalTitle("选择供货商")*/}
            {/*                setModalNumber(1)*/}
            {/*                setVisible(true)*/}
            {/*            }} type={'select'}/>}*/}
            {/*        />*/}
            {/*    </Form.Item>*/}

            {/*    <Form.Item label={carReceiptManufacturer.name}>*/}
            {/*        <Input*/}
            {/*            style={{width: '300px'}}*/}
            {/*            readOnly={true}*/}
            {/*            value={carReceiptManufacturer.spec}*/}
            {/*            autoComplete={"off"}*/}
            {/*            onKeyPress={() => {*/}
            {/*                setModalTitle("选择生产厂商")*/}
            {/*                setModalNumber(2)*/}
            {/*                setVisible(true)*/}
            {/*            }}*/}
            {/*            suffix={carReceiptManufacturer.spec !== "" ?*/}
            {/*                <CloseOutlined type={'close'} onClick={() => {*/}
            {/*                    setCarReceiptManufacturer({*/}
            {/*                        ...carReceiptManufacturer,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                    changeData({*/}
            {/*                        ...carReceiptManufacturer,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                }}/> : <span/>}*/}
            {/*            addonAfter={<SelectOutlined onClick={() => {*/}
            {/*                setModalTitle("选择生产厂商")*/}
            {/*                setModalNumber(2)*/}
            {/*                setVisible(true)*/}
            {/*            }} type={'select'}/>}*/}
            {/*        />*/}
            {/*    </Form.Item>*/}

            {/*    <Form.Item label={carReceiptBank.name}>*/}
            {/*        <Input*/}
            {/*            style={{width: '300px'}}*/}
            {/*            readOnly={true}*/}
            {/*            value={carReceiptBank.spec}*/}
            {/*            autoComplete={"off"}*/}
            {/*            onKeyPress={() => {*/}
            {/*                setModalTitle("选择委贷单位")*/}
            {/*                setModalNumber(3)*/}
            {/*                setVisible(true)*/}
            {/*            }}*/}
            {/*            suffix={carReceiptBank.spec !== "" ?*/}
            {/*                <CloseOutlined type={'close'} onClick={() => {*/}
            {/*                    setCarReceiptBank({*/}
            {/*                        ...carReceiptBank,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                    changeData({*/}
            {/*                        ...carReceiptBank,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                }}/> : <span/>}*/}
            {/*            addonAfter={<SelectOutlined onClick={() => {*/}
            {/*                setModalTitle("选择委贷单位")*/}
            {/*                setModalNumber(3)*/}
            {/*                setVisible(true)*/}
            {/*            }} type={'select'}/>}*/}
            {/*        />*/}
            {/*    </Form.Item>*/}

            {/*    <Form.Item label={productCarSupplier.name}>*/}
            {/*        <Input*/}
            {/*            style={{width: '300px'}}*/}
            {/*            readOnly={true}*/}
            {/*            value={productCarSupplier.spec}*/}
            {/*            autoComplete={"off"}*/}
            {/*            onKeyPress={() => {*/}
            {/*                setModalTitle("选择供应厂商")*/}
            {/*                setModalNumber(4)*/}
            {/*                setVisible(true)*/}
            {/*            }}*/}
            {/*            suffix={productCarSupplier.spec !== "" ?*/}
            {/*                <CloseOutlined type={'close'} onClick={() => {*/}
            {/*                    setProductCarSupplier({*/}
            {/*                        ...productCarSupplier,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                    changeData({*/}
            {/*                        ...productCarSupplier,*/}
            {/*                        flag: "",*/}
            {/*                        spec: ""*/}
            {/*                    })*/}
            {/*                }}/> : <span/>}*/}
            {/*            addonAfter={<SelectOutlined onClick={() => {*/}
            {/*                setModalTitle("选择供应厂商")*/}
            {/*                setModalNumber(4)*/}
            {/*                setVisible(true)*/}
            {/*            }} type={'select'}/>}*/}
            {/*        />*/}
            {/*    </Form.Item>*/}
            </Form>

            {/*<Modal*/}
            {/*    maskClosable={false}*/}
            {/*    title={modalTitle}*/}
            {/*    visible={visible}*/}
            {/*    footer={null}*/}
            {/*    width={1000}*/}
            {/*    destroyOnClose={true}*/}
            {/*    onCancel={() => {*/}
            {/*        setVisible(false)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {(modalNumber === 1 || modalNumber === 2) &&*/}
            {/*    <Partner*/}
            {/*        dataSource={3}*/}
            {/*        onOk={val => {*/}
            {/*            if (modalNumber === 1) {*/}
            {/*                setCarReceiptSupplier({*/}
            {/*                    ...carReceiptSupplier,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*                changeData({*/}
            {/*                    ...carReceiptSupplier,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*            }*/}
            {/*            if (modalNumber === 2) {*/}
            {/*                setCarReceiptManufacturer({*/}
            {/*                    ...carReceiptManufacturer,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*                changeData({*/}
            {/*                    ...carReceiptManufacturer,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*            }*/}
            {/*            if (modalNumber === 3) {*/}
            {/*                setCarReceiptBank({*/}
            {/*                    ...carReceiptBank,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*                changeData({*/}
            {/*                    ...carReceiptBank,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*            }*/}
            {/*            if (modalNumber === 4) {*/}
            {/*                setProductCarSupplier({*/}
            {/*                    ...productCarSupplier,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*                changeData({*/}
            {/*                    ...productCarSupplier,*/}
            {/*                    flag: val.id,*/}
            {/*                    spec: val.name*/}
            {/*                })*/}
            {/*            }*/}
            {/*            setVisible(false)*/}
            {/*        }}/>}*/}
            {/*</Modal>*/}
        </React.Fragment>
    )

}

export default Setting