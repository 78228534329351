import React from 'react'
import {Route, Switch} from "react-router-dom"
import AdminLayout from '../components/layout/AdminLayout'

import Ext from './ext'
import Crm from './crm'
import Passport from './passport'
import Coupon from './coupon'
import Wms from './wms'
import Sale from './sale'
import Support from './support'
import Avocation from "./avocation";
import Finance from "./finance"
import Qa from "./qa"
import Report from "./report";
import QuickIndex from "./quick";

function Admin() {
    return (
        <AdminLayout>
            <Switch>
                <Route exact path={'/admin'} component={QuickIndex}/>
                <Route path={'/admin/crm'} component={Crm}/>
                <Route path={'/admin/passport'} component={Passport}/>
                <Route path={'/admin/coupon'} component={Coupon}/>
                <Route path={'/admin/qa'} component={Qa}/>
                <Route path={'/admin/wms'} component={Wms}/>
                <Route path={'/admin/sale'} component={Sale}/>
                <Route path={'/admin/support'} component={Support}/>
                <Route path={'/admin/avocation'} component={Avocation}/>
                <Route path={'/admin/finance'} component={Finance}/>
                <Route path={'/admin/report'} component={Report}/>
                <Route path={'/admin/code'} component={Report}/>
                <Route path={'/admin/ext'} component={Ext}/>
            </Switch>
        </AdminLayout>
    )
}

export default Admin
