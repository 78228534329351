
class PassportUser {

    // 0:未知 1:男 2:女
    static SEX_UNKNOWN = 0;
    static SEX_MEN = 1;
    static SEX_WOMEN = 2;
    static SexName = {
        [this.SEX_UNKNOWN]: '未知',
        [this.SEX_MEN]: '男',
        [this.SEX_WOMEN]: '女',
    }

    // 状态 0:全部 1:启用 2:禁用
    static STATUS_ALL = 0;
    static STATUS_YES = 1;
    static STATUS_NO = 2;
    static StatusName = {
        [this.STATUS_ALL]: '全部',
        [this.STATUS_YES]: '启用',
        [this.STATUS_NO]: '禁用',
    }

}

class PassportEmployee {

    // 状态 0:全部 1:在职 2:离职
    static STATUS_ALL = 0;
    static STATUS_YES = 1;
    static STATUS_NO = 2;
    static StatusName = {
        [this.STATUS_ALL]: '全部',
        [this.STATUS_YES]: '在职',
        [this.STATUS_NO]: '离职',
    }

}

export {
    PassportUser,
    PassportEmployee
}