import React, {useEffect, useState} from 'react'
import {
    CloseOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Pagination,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import RepairHistory from "../../../../components/support/RepairHistory";
import common from "../../../../utils/common";
import moment from "moment";
import {Link} from "react-router-dom";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import condition from "../../../../utils/condition";
import SearchPlanButton from "../../../../components/support/SearchPlanButton";
import Search from "../../../../utils/search";

//  const STATUS_WAIT = 1; // 未结算
//  const STATUS_RECEIVABLE = 2; // 待收款
//  const STATUS_PAID = 3; // 待付款
const FormItem = Form.Item
const {Option} = Select;
const {RangePicker} = DatePicker;

// 普通工单
//  1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
//  5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
const REPAIR_STATUS_CREATING = 1;
const REPAIR_STATUS_WAITING = 2;
const REPAIR_STATUS_SUPPORTING = 3;
const REPAIR_STATUS_SUPPORTED = 4;
const REPAIR_STATUS_PREPAID = 5;
const REPAIR_STATUS_SETTLED = 6;
const REPAIR_STATUS_RECEIVED = 7;
const REPAIR_STATUS_BACK = 8;

let repairStatusTypeAlias = {
    [REPAIR_STATUS_CREATING]: '工单编辑',
    [REPAIR_STATUS_WAITING]: '待派工',
    [REPAIR_STATUS_SUPPORTING]: '维修中',
    [REPAIR_STATUS_SUPPORTED]: '已竣工',
    [REPAIR_STATUS_PREPAID]: '预结算',
    [REPAIR_STATUS_SETTLED]: '已结算',
    [REPAIR_STATUS_RECEIVED]: '已收款',
    [REPAIR_STATUS_BACK]: '已退单',
}

function RepairList(props) {

    const initialStatistics = {
        repairNum: 0,
        predictTotal: "0.00",
        settleVehicleNum: 0,
        profit: "0.00",
        freeVehicleNum: 0,
        receiveVehicleNum: 0,
        customerPrice: "0.00",
        freeRate: "0.00",
        inFactoryVehicleNum: 0,
        supportedVehicleNum: 0,
        predictProjectTotal: "0.00",
        deliveryTotal: "0.00",
    }

    let initialSearch = {
        code: "",
        name: "",
        plate: "",
        status: 0,
        type: "",
        repairCategoryIds: [],
        consignorCompanyId: "",// 获取本公司id
        employeeNames: common.getUser().nickname,
        employeeIds: [],
        phone: "",
        makeAtStart: "",
        makeAtEnd: "",
        clearingAtStart: "",
        clearingAtEnd: "",
        exampleVin: "",// 输入框展示字段 接口实际没有这个字段
        bothVin: "",
        productName: "",
        ownerId: common.getUser().company.id,
        statuses: [REPAIR_STATUS_WAITING, REPAIR_STATUS_SUPPORTING, REPAIR_STATUS_SUPPORTED, REPAIR_STATUS_PREPAID],
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   // 提交ajax搜索值
    let [total, setTotal] = useState(0)     // 总记录条数
    let [statistics, setStatistics] = useState(initialStatistics) // 汇总
    let [repairs, setRepairs] = useState([])    // 维修工单列表
    let [repairCategories, setRepairCategories] = useState([]) // 工单类型
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)    //  选择员工
    let [repairHistoryVisible, setRepairHistoryVisible] = useState(0) // 选择维修记录
    let [vin, setVin] = useState("") // 已选择的车架号
    let [spareFrameNumber, setSpareFrameNumber] = useState("") // 已选择的备用车架号
    let [pagination, setPagination] = useState({page: 1, limit: 15})
    let [orderBy, setOrderBy] = useState(" created_at DESC ")


    // 页码发生变化就请求数据
    let getRepairs = () => {
        common.loadingStart()
        let params = {
            ...query,
            ...pagination,
            orderBy: orderBy,
        }
        common.ajax('get', '/support/repair/list', params).then(res => {
            setTotal(res.pagination.total)
            repairs = res.repairs || []
            if (res.repairs.length > 0) {
                setRepairs([...repairs, res.subtotal])
                // setRepairs([...repairs, res.subtotal, res.total])
            } else {
                setRepairs([...repairs])
            }
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getRepairs()
    }, [pagination, query, orderBy])


    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取工单类型
    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/support/term/findByTaxonomy', {taxonomy: 'repairCategory'})
            .then((data) => {
                setRepairCategories(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }, [])

    // 处理排序
    let handleSortChange = (pagination, filters, sorter) => {
        let table = '';
        if (sorter.field === 'id') {
            table = "repair.";
        }
        sorter.field = table + condition.getFieldStr(sorter.field);
        setOrderBy(condition.getOrderBy(sorter))
    }


    // 维修工单列
    let columns = [
        {
            title: '维修单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
            fixed: 'left',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: '车牌号',
            width: 80,
            dataIndex: 'plate',
            ellipsis: true,
            fixed: 'left',
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '报修日期',
            width: 130,
            dataIndex: 'makeAt',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text) => {
                return text === null ? '-' : moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: '送修人',
            width: 100,
            dataIndex: 'name',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '车系',
            width: 100,
            dataIndex: 'seriesName',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '车型',
            width: 160,
            dataIndex: 'productName',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '里程数',
            width: 60,
            dataIndex: 'mileage',
            align: 'right',
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '',
            width: 30,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '车架号',
            width: 160,
            dataIndex: 'vin',
            ellipsis: true,
            render: (text, record) => {
                {
                    return text ? <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        vin = record.vin
                        setVin(vin)
                        setRepairHistoryVisible(1)
                    }}>{text}</span> : '-'
                }
            }
        },
        {
            title: '结算日期',
            width: 130,
            dataIndex: 'clearingAt',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm') : '-'
            }
        },
        {
            title: '状态',
            width: 80,
            dataIndex: 'status',
            ellipsis: true,
            render: (text, record) => {
                return <div>
                    {text ? repairStatusTypeAlias[record.status] : '-'}
                </div>
            }
        },
        {
            title: '预估工时',
            width: 120,
            align: 'right',
            dataIndex: "projectTotal",
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: <Tooltip title={"出库金额"}>材料费 <QuestionCircleOutlined/></Tooltip>,
            width: 120,
            align: "right",
            dataIndex: "productDeliveryTotal",
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '总费用',
            width: 120,
            align: 'right',
            dataIndex: "total",
            ellipsis: true,
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '',
            width: 30,
            ellipsis: true,

        },
        {
            title: '服务顾问',
            width: 100,
            dataIndex: 'employeeName',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: text => {
                return text ? text : '-'
            }
        },
        {
            title: '工单类型',
            width: 100,
            dataIndex: 'repairCategoryId',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (text, record) => {
                return record.repairCategoryName ? record.repairCategoryName : '-'
            }
        },
        {
            title: '备用VIN',
            width: 160,
            dataIndex: 'spareFrameNumber',
            ellipsis: true,
            render: (text, record) => {
                {
                    return text ? <span style={{color: "#1890ff", cursor: "pointer"}} onClick={() => {
                        spareFrameNumber = record.spareFrameNumber
                        setSpareFrameNumber(spareFrameNumber)
                        setRepairHistoryVisible(2)
                    }}>{text}</span> : '-'
                }
            }
        },
        {
            title: '客户名称',
            width: 130,
            dataIndex: 'customerName',
            ellipsis: true
        },
        {
            title: '车主姓名',
            width: 130,
            dataIndex: 'vehicleName',
            ellipsis: true
        },
        {
            title: '操作',
            width: 160,
            align: 'center',
            ellipsis: true,
            fixed: 'right',
            render: (text, record) => {
                if (record.id === "小计" || record.id === "合计") {
                    return
                }
                return (
                    <div>
                        <Link to={`/support/repair/edit/${record.id}`}>编辑</Link>
                        {common.can("important.support.repair.dispatch") && <Divider type="vertical"/>}
                        {common.can("important.support.repair.dispatch") && <Link to={`/support/repair/dispatch/${record.id}`}>派工</Link>}
                        <Divider type="vertical"/>
                        <Link to={`/support/repair/prepay/${record.id}`}
                              disabled={record.finishAt === null}>预结算</Link>
                    </div>
                )
            }
        },
    ]


    // 获取汇总 (左下角的车辆)
    // let getStatistics = () => {
    //     let params = {
    //         ownerId: common.getUser().company.id,
    //     }
    //     common.ajax('get', '/support/repair/statistics', params)
    //         .then(res => {
    //             statistics = res || initialStatistics
    //             setStatistics({...statistics})
    //         })
    // }
    // useEffect(() => {
    // getStatistics()
    // }, [])

    return (
        <React.Fragment>
            <PageTop title={'维修工单'}>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => {
                    props.history.push('/support/repair/create')
                }}>新增</Button>
            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"报修日期"}>
                                <RangePicker
                                    value={[
                                        search.makeAtStart ? moment(search.makeAtStart) : null,
                                        search.makeAtEnd ? moment(search.makeAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            makeAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            makeAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            makeAtStart: "",
                                            makeAtEnd: "",
                                        })

                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"送修人员"}>
                                <Input placeholder={"请输入送修人或车主名称"} value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input placeholder={"请输入车牌号"} value={search.plate}
                                       onChange={(e) => {
                                           setSearch({...search, plate: e.target.value.trim()})
                                       }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"服务顾问"}>
                                <Input
                                    value={search.employeeNames}
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择员工名称'}
                                    suffix={
                                        search.employeeNames !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, employeeIds: [], employeeNames: ""})
                                                }}/> : <span/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupEmployeeVisible(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setLookupEmployeeVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"结算日期"}>
                                <RangePicker
                                    value={[
                                        search.clearingAtStart ? moment(search.clearingAtStart) : null,
                                        search.clearingAtEnd ? moment(search.clearingAtEnd) : null
                                    ]}
                                    onChange={(val) => {
                                        val ? setSearch({
                                            ...search,
                                            clearingAtStart: val[0] ? moment(val[0]).format("YYYY-MM-DD") + " 00:00:00" : null,
                                            clearingAtEnd: val[1] ? moment(val[1]).format("YYYY-MM-DD") + " 23:59:59" : null,
                                        }) : setSearch({
                                            ...search,
                                            clearingAtStart: "",
                                            clearingAtEnd: "",
                                        })
                                    }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"联系电话"}>
                                <Input placeholder={"请输入送修人或车主电话"} value={search.phone} onChange={(e) => {
                                    setSearch({...search, phone: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"维修单号"}>
                                <Input placeholder={"请输入维修单号"} value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value.trim()})
                                }}/>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"工单类型"}>
                                <Select value={search.repairCategoryIds}
                                        mode="multiple"
                                        placeholder={"请选择工单类型"}
                                        onChange={(val) => {
                                            setSearch({...search, repairCategoryIds: val})
                                        }}>
                                    {repairCategories.map(item =>
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="工单状态">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    value={search.statuses}
                                    style={{width: '100%'}}
                                    placeholder="请选择工单状态"
                                    onChange={(val => {
                                        if (val.includes(0)) {
                                            search.statuses = [
                                                REPAIR_STATUS_WAITING, REPAIR_STATUS_SUPPORTING,
                                                REPAIR_STATUS_SUPPORTED, REPAIR_STATUS_PREPAID,
                                                REPAIR_STATUS_SETTLED, REPAIR_STATUS_RECEIVED, REPAIR_STATUS_BACK
                                            ]
                                            setSearch({...search})
                                        } else {
                                            search.statuses = val
                                            setSearch({...search})
                                        }
                                    })}>
                                    <Option value={0}>全部</Option>
                                    <Option
                                        value={REPAIR_STATUS_WAITING}>{repairStatusTypeAlias[REPAIR_STATUS_WAITING]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_SUPPORTING}>{repairStatusTypeAlias[REPAIR_STATUS_SUPPORTING]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_SUPPORTED}>{repairStatusTypeAlias[REPAIR_STATUS_SUPPORTED]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_PREPAID}>{repairStatusTypeAlias[REPAIR_STATUS_PREPAID]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_SETTLED}>{repairStatusTypeAlias[REPAIR_STATUS_SETTLED]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_RECEIVED}>{repairStatusTypeAlias[REPAIR_STATUS_RECEIVED]}</Option>
                                    <Option
                                        value={REPAIR_STATUS_BACK}>{repairStatusTypeAlias[REPAIR_STATUS_BACK]}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车架车型 "}>
                                <Input placeholder={"请输入车架号码/备用VIN/车型模糊搜索"}
                                       value={search.exampleVin}
                                       onChange={(e) => {
                                           if (e.target.value.trim() != undefined && e.target.value.trim() != null && e.target.value.trim() != "") {
                                               let regExp = /\W/;
                                               if (regExp.test(e.target.value.trim())) {
                                                   setSearch({
                                                       ...search,
                                                       exampleVin: e.target.value.trim(),
                                                       bothVin: "",
                                                       productName: e.target.value.trim(),
                                                   })
                                               } else {
                                                   // 英文及数字
                                                   setSearch({
                                                       ...search,
                                                       exampleVin: e.target.value.trim(),
                                                       bothVin: e.target.value.trim(),
                                                       productName: "",
                                                   })
                                               }
                                           } else {
                                               setSearch({
                                                   ...search,
                                                   exampleVin: e.target.value.trim(),
                                                   bothVin: "",
                                                   productName: ""
                                               })
                                           }
                                       }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"工单费用 "}>
                                <Space>
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        max={99999999}
                                        value={search.minTotal}
                                        onChange={(value) => {
                                            setSearch({...search, minTotal: value})
                                        }}
                                    />至<InputNumber
                                    precision={2}
                                    min={0}
                                    max={99999999}
                                    value={search.maxTotal}
                                    onChange={(value) => {
                                        setSearch({...search, maxTotal: value})
                                    }}
                                />
                                </Space>

                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} type="default" onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索
                                </Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch({...initialSearch})
                                    setQuery({...initialSearch})
                                    Search.clearParams()
                                }}>清空</Button>
                                {/*搜索方案保存*/}
                                <SearchPlanButton initSearch={initialSearch} url={props.match.url}
                                                  search={search} setSearch={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={repairs}
                onChange={handleSortChange}
            />

            <PageBottom
                /*children={<div>
                    <span>台次：{statistics.repairNum}</span>
                    <Divider type="vertical"/>
                    <span>在厂车辆：{statistics.inFactoryVehicleNum}</span>
                    <Divider type="vertical"/>
                    <span>待派工：{statistics.waitingVehicleNum}</span>
                    <Divider type="vertical"/>
                    <span>维修中：{statistics.supportingVehicleNum}</span>
                    <Divider type="vertical"/>
                    <span>竣工未结：{statistics.supportedVehicleNum}</span>
                    <Divider type="vertical"/>
                    <span>预结算：{statistics.prepayVehicleNum}</span>
                    <Divider type="vertical"/>
                    <span>结算：{statistics.settleVehicleNum}</span>
                </div>}*/
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            />
            <div>
                <Modal
                    maskClosable={false}
                    title="选择员工"
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        isMultiple={true}
                        canChangeCompany={true}
                        companyId={common.getUser().company.id}
                        onOk={(values) => {
                            setLookupEmployeeVisible(false)
                            setSearch({
                                ...search,
                                employeeIds: values.map(v => v.id),
                                employeeNames: values.map(v => v.name).join(",")
                            })
                        }}
                    />
                </Modal>
            </div>

            <div>
                <Modal
                    maskClosable={false}
                    title="车辆维修记录"
                    visible={repairHistoryVisible > 0}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setRepairHistoryVisible(0)
                        getRepairs()
                    }}>
                    <RepairHistory
                        vin={repairHistoryVisible === 1 ? vin : ""}
                        spareFrameNumber={repairHistoryVisible === 2 ? spareFrameNumber : ""} plate={""}/>
                </Modal>
            </div>

        </React.Fragment>
    )
}


export default RepairList
