class Sms {
    // 状态 1.待审批 2.审批通过待执行 3.执行成功 4.执行失败 5.作废
    static STATUS_WAIT = 1;
    static STATUS_PASS = 2;
    static STATUS_SUCCESS = 3;
    static STATUS_FAIL = 4;
    static STATUS_INVALID = 5;

    static StatusAlias = {
        [this.STATUS_WAIT]: "待审批",
        [this.STATUS_PASS]: "审批通过待执行",
        [this.STATUS_SUCCESS]: "发送成功",
        [this.STATUS_FAIL]: "发送失败",
        [this.STATUS_INVALID]: "审批不通过",
    }

    static TEMPLATE_COUPON = 'COUPON';
    static TEMPLATE_MARKETING = 'MARKETING';

    static TemplateAlias = {
        [this.TEMPLATE_COUPON]: "卡券模板",
        [this.TEMPLATE_MARKETING]: "营销短信模板",
    }
}

class Template {
    //代办任务类型 1:生日提醒任务 2:新车首保任务 3:常规保养任务 4:保险到期任务
    //21:新车客户跟进 22：保养客户提醒 23：客户分类统计 24：车辆档案统计 25：流失客户提醒 26保险业务统计 27：维修业务构成
    //28:绩效统计报表 29:服务评价统计
    static BUSINESS_BIRTHDAY = 1;
    static BUSINESS_VEHICLE = 2;
    static BUSINESS_ROUTINE = 3;
    static BUSINESS_INSURANCE = 4;
    static BUSINESS_XCKHGJ = 21;
    static BUSINESS_BYKHTX = 22;
    //    static BUSINESS_KHFLTJ = 23;
//    static BUSINESS_CLDATJ = 24;
    static BUSINESS_LSKHTX = 25;
//    static BUSINESS_BXYWTJ = 26;
    static BUSINESS_WXYWGC = 27;
//    static BUSINESS_JXTJBB = 28;
    static BUSINESS_FWPJTJ = 29;

    static BusinessAlias = {
        [this.BUSINESS_BIRTHDAY]: "生日提醒任务",
        [this.BUSINESS_VEHICLE]: "新车首保任务",
        [this.BUSINESS_ROUTINE]: "常规保养任务",
        [this.BUSINESS_INSURANCE]: "保险到期任务",
        [this.BUSINESS_XCKHGJ]: "新车客户跟进",
        [this.BUSINESS_BYKHTX]: "保养客户提醒",
        [this.BUSINESS_LSKHTX]: "流失客户提醒",
        [this.BUSINESS_WXYWGC]: "维修业务构成",
        [this.BUSINESS_FWPJTJ]: "服务评价统计",
    }

    static STATUS_ENABLE = 1;
    static STATUS_DISABLE = 2;

    static StatusAlias = {
        [this.STATUS_ENABLE]: "启用",
        [this.STATUS_DISABLE]: "禁用",
    }
}

/**
 * 分类
 */
class Term {
    static TASK_SEARCH_CUSTOMER_LEVEL = 'task_search_customer_level';//潜客管理 客户级别 搜索条件默认值
    static NOTICE_OF_APPOINTMENT = 'notice_of_appointment';// 预约客户的提前N天提醒
    static TASK_LEVEL = 'level';// 客户级别
    static BOOKING_TIME = 'booking_time';// 预约单 预约时段

    static TERM_STATUS_ENABLE = 1;
    static TERM_STATUS_DISABLE = 2;

    static StatusAlias = {
        [this.TERM_STATUS_ENABLE]: "启用",
        [this.TERM_STATUS_DISABLE]: "禁用"
    }

    static SETTING_NO = "0";
    static SETTING_YES = "1";
}

export {
    Sms,
    Term,
    Template
}