import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Form, Modal, Pagination, Select, Table, TreeSelect} from 'antd';
import SearchArea from "../../../components/layout/SearchArea";
import {connect} from "react-redux";
import PageBottom from "../../../components/layout/PageBottom";
import common from "../../../utils/common"
import RateForm from "./component/form"
import {ProductCategoryRate, Term, wmsPublic} from "../../../components/wms/config";

const FormItem = Form.Item
const {Option} = Select

function Index() {
    // 分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([]);// 列表数据
    let [visible, setVisible] = useState(false);// 模态框是否显示
    let [rate, setRate] = useState([]) // 单条数据
    let initialSearch = {
        orderType: '',
        category1: ''
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [treeData, setTreeData] = useState([]) // 管理分类下拉框数据

    const columns = [
        {
            title: '出库类型',
            dataIndex: 'orderType',
            width: 200,
            ellipsis: true,
            render: (text) => text in ProductCategoryRate.OrderTypeAlias ? ProductCategoryRate.OrderTypeAlias[text] : text
        },
        {
            title: '管理分类',
            dataIndex: 'categoryName',
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => text in ProductCategoryRate.StatusAlias ? ProductCategoryRate.StatusAlias[text] : text
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 11)
        },
        {
            title: '操作',
            align: "center",
            width: 150,
            ellipsis: true,
            render: (text, record) => (
                <div className={"action-btns"}>
                    <Button type="link" onClick={() => {
                        findById({
                            orderType: record.orderType,
                            category1: record.category1,
                            type: ProductCategoryRate.TYPE_DELIVERY,
                            status: record.status
                        })
                    }}>编辑</Button>
                </div>
            ),
        },
    ]

    // 修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/product/categoryRate/updateDeliveryById", params).then((data) => {
            common.toast("修改成功")
            // 获取品牌列表
            getData()
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 更改每页显示条数
    let onShowSizeChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 获取列表数据 ajax
    let getData = () => {
        common.loadingStart();
        common.ajax("get", "/wms/product/categoryRate/list", {
            ...pagination,
            ...query,
            type: ProductCategoryRate.TYPE_DELIVERY
        }).then((data) => {
            setList(data.productCategoryRateDtos)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let findById = (params) => {
        common.loadingStart();
        common.ajax("get", "/wms/product/categoryRate/findById", params).then((data) => {
            setRate(data)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 页面初始化
    useEffect(getData, [pagination, query])

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            limit: 100
        }).then(data => {
            setTreeData(wmsPublic.getTree(data.terms, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    return (
        <>
            <SearchArea>
                <Form layout={"inline"} name={'form-data'} className={"ant-advanced-search-form"}>
                    <FormItem label={"出库类型"}>
                        <Select
                            style={{width: 200}}
                            onChange={(val) => {
                                setSearch({...search, orderType: val})
                            }}
                            value={search.orderType}>
                            <Option value={ProductCategoryRate.ORDER_TYPE_SALE}
                                    key={ProductCategoryRate.ORDER_TYPE_SALE}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_SALE]}</Option>
                            <Option value={ProductCategoryRate.ORDER_TYPE_REPAIR}
                                    key={ProductCategoryRate.ORDER_TYPE_REPAIR}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_REPAIR]}</Option>
                            <Option value={ProductCategoryRate.ORDER_TYPE_ALLOT}
                                    key={ProductCategoryRate.ORDER_TYPE_ALLOT}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_ALLOT]}</Option>
                            <Option value={ProductCategoryRate.ORDER_TYPE_RECEIVE}
                                    key={ProductCategoryRate.ORDER_TYPE_RECEIVE}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_RECEIVE]}</Option>
                            <Option value={ProductCategoryRate.ORDER_TYPE_SALE_WMS}
                                    key={ProductCategoryRate.ORDER_TYPE_SALE_WMS}>{ProductCategoryRate.OrderTypeAlias[ProductCategoryRate.ORDER_TYPE_SALE_WMS]}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label={"管理分类"}>
                        <TreeSelect
                            value={search.category1}
                            onChange={(val) => {
                                setSearch({...search, category1: val})
                            }}
                            style={{width: 200}}
                            allowClear={true}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            treeData={treeData}
                            treeDefaultExpandAll
                        />
                    </FormItem>
                    <FormItem className="inline-search-btns">
                        <Button icon={<SearchOutlined/>} onClick={() => {
                            setPagination({...pagination, page: 1})
                            setQuery(search)
                        }}>搜索</Button>
                        <Button icon={<ReloadOutlined/>} onClick={() => {
                            setSearch(initialSearch)
                            setQuery(initialSearch)
                        }}>清空</Button>
                    </FormItem>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                columns={columns}
                dataSource={list}
                scroll={{x: '100%'}}
                rowKey='id'/>
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total} current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['15', '30', '50', '100']}
                    onShowSizeChange={onShowSizeChange}
                    onChange={onShowSizeChange}
                    defaultPageSize={pagination.limit}/>
            }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setRate([])
                    setVisible(false)
                }}
                footer={null}
            >
                <RateForm action={ProductCategoryRate.TYPE_DELIVERY} defaultValue={rate} onOk={(val) => {
                    update(val)
                }}/>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)