import React, {useEffect, useState} from 'react';
import {
    BarcodeOutlined,
    CloseOutlined, PlusOutlined,
    QuestionCircleOutlined,
    RollbackOutlined,
    SaveOutlined,
    SelectOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Table,
    Tooltip,
    TreeSelect,
    Upload
} from 'antd';
import PageTop from "../../layout/PageTop";
import common from "../../../utils/common";
import Partner from "../../passport/LookupPartner";
import LookupBrand from "../LookupBrand";
import ProductSetupForm from "../productSetup";
import {Brand, Product, ProductGeneralRate, ProductSetup, Term, wmsPublic} from "../config";
import BigNumber from "bignumber.js";
import Search from "../../../utils/search";

const {Option} = Select
const {TextArea} = Input

const initialInputForBrand = {
    ownerId: common.getUser().company.id,
    taxonomy: Term.GOODS_INSERT_DEFAULT_BRAND,
    brandIds: [], // 默认为无
}

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function ProductGoods(props) {
    // isCompany false(集团 默认) true(公司)
    let {onOk, defaultValue, isCompany = false, isModal = false} = props

    let [visible, setVisible] = useState(false);
    let [partner, setPartner] = useState({});
    let [partner2, setPartner2] = useState({});
    let [brandIds, setBrandIds] = useState([]);
    let [pageTopTitle, setPageTopTitle] = useState('');
    let [modalTitle, setModalTitle] = useState('');// 模态框名称
    let [modalNumber, setModalNumber] = useState(0);// 模态框选择
    let [treeData2, setTreeData2] = useState([]);
    let [treeData1, setTreeData1] = useState([]);
    let [treeData3, setTreeData3] = useState([]);
    let [number1, setNumber1] = useState(0);
    let [number2, setNumber2] = useState(0);
    let [number3, setNumber3] = useState(0);
    let [number, setNumber] = useState(1);
    let [brand, setBrand] = useState([]);// 品牌
    let [brandIdList, setBrandIdList] = useState([]);// 默认品牌
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [productSetup, setProductSetup] = useState({
        productId: defaultValue,
        isGift: ProductSetup.IS_GIFT_NO,
        rebatePerItem: "",
        estimatedCost: "",
        rebateOffset: "",
        commissionUnitPrice: "",
        spec: "",
    });

    const defaultList2 = {
        id: 1,
        chargeType: undefined,
        priceRate: isCompany ? 0 : null,
        price: isCompany ? null : 0,
        status: undefined,
        kind: 'new'
    }
    let [list2, setList2] = useState([defaultList2])
    let initialOption = {
        [ProductGeneralRate.CHARGE_TYPE_SALE]: false,
        [ProductGeneralRate.CHARGE_TYPE_REPAIR]: false,
        [ProductGeneralRate.CHARGE_TYPE_CLAIM]: false,
        [ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]: false,
    }
    let [optionSelect, setOptionSelect] = useState(initialOption)

    const handleCancel = () => {
        common.consoleLog("handleCancel")
        setPreviewOpen(false)
    };
    const handlePreview = async (file) => {
        common.consoleLog(file)
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const upload = (params) => {
        common.consoleLog(params)
        common.loadingStart()
        common.ajax('post', '/wms/oss/upload', {
            files: params.file
        }, {'contentType': "multipart/form-data"})
            .then(res => {

            })
            .finally(common.loadingStop)
    };
    const handleChange = ({fileList: newFileList}) => {
        common.consoleLog("handleChange")
        common.consoleLog(newFileList)
        if (newFileList.length < fileList.length) {
            for (let oldFile of fileList) {
                let isDelete = true;
                for (let newFile of newFileList) {
                    if (oldFile.id === newFile.id) {
                        isDelete = false
                    }
                }
                if (isDelete) {
                    // 删除
                }
            }
        }
        setFileList(newFileList)
    };
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 10,
                }}
            >
                Upload
            </div>
        </div>
    );

    let columnsCompanyCreate = [
        {
            title: '收费区分',
            dataIndex: 'chargeType',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择收费区分"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].chargeType = val
                        if (record.status === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[val] = true
                            optionSelect[text] = false
                        }
                        setNumber(number + 1)
                    }}
                >
                    {number && <>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_CLAIM]}
                                value={ProductGeneralRate.CHARGE_TYPE_CLAIM}
                                key={ProductGeneralRate.CHARGE_TYPE_CLAIM}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_CLAIM]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_REPAIR]}
                                value={ProductGeneralRate.CHARGE_TYPE_REPAIR}
                                key={ProductGeneralRate.CHARGE_TYPE_REPAIR}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_REPAIR]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_SALE]}
                                value={ProductGeneralRate.CHARGE_TYPE_SALE}
                                key={ProductGeneralRate.CHARGE_TYPE_SALE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_SALE]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}
                                value={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                                key={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}</Option>
                    </>
                    }
                </Select>
        },
        {
            title: '加价率',
            width: 100,
            dataIndex: 'priceRate',
            ellipsis: true,
            render: () => '第一次入库时,更新加价率'
        },
        {
            title: '计划售价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    precision={2}
                    placeholder="请填写计划售价"
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={0}
                    onChange={(val) => {
                        list2[index].price = val
                    }}
                />
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择状态"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].status = val
                        if (val === ProductGeneralRate.STATUS_DISABLE) {
                            optionSelect[record.chargeType] = false
                        }
                        if (val === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[record.chargeType] = true
                        }
                        setNumber(number + 1)
                    }}
                >
                    <Option value={ProductGeneralRate.STATUS_ENABLE}
                            key={ProductGeneralRate.STATUS_ENABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_ENABLE]}</Option>
                    <Option value={ProductGeneralRate.STATUS_DISABLE}
                            key={ProductGeneralRate.STATUS_DISABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_DISABLE]}</Option>
                </Select>
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                        <Button type={"link"} onClick={() => {
                            if (record.chargeType === undefined || record.price === null || record.status === undefined) {
                                common.toast("请填写完整数据 再添加")
                                return false
                            }

                            setList2([
                                ...list2,
                                {
                                    id: record.id + 1,
                                    chargeType: undefined,
                                    priceRate: 0,
                                    price: null,
                                    status: undefined,
                                    kind: 'new'
                                }
                            ])
                        }}>添加</Button>}
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            if (list2.length === 1) {
                                if (list2[0].id === defaultList2.id) {
                                    defaultList2.id = defaultList2.id + 1
                                    setList2([defaultList2])
                                } else {
                                    setList2([defaultList2])
                                }
                                setOptionSelect(initialOption)
                            } else {
                                let arr = list2.filter((item) => {
                                    return item.id !== record.id
                                })
                                optionSelect[record.chargeType] = false
                                setList2(arr)
                            }
                        })
                    }}>删除
                    </Button>
                </div>)
        }
    ]

    let columnsCompanyUpdate = [
        {
            title: '收费区分',
            dataIndex: 'chargeType',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择收费区分"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].chargeType = val
                        if (record.status === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[val] = true
                            optionSelect[text] = false
                        }
                        setNumber(number + 1)
                    }}
                >
                    {number && <>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_CLAIM]}
                                value={ProductGeneralRate.CHARGE_TYPE_CLAIM}
                                key={ProductGeneralRate.CHARGE_TYPE_CLAIM}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_CLAIM]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_REPAIR]}
                                value={ProductGeneralRate.CHARGE_TYPE_REPAIR}
                                key={ProductGeneralRate.CHARGE_TYPE_REPAIR}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_REPAIR]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_SALE]}
                                value={ProductGeneralRate.CHARGE_TYPE_SALE}
                                key={ProductGeneralRate.CHARGE_TYPE_SALE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_SALE]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}
                                value={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                                key={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}</Option>
                    </>
                    }
                </Select>
        },
        {
            title: '加价率',
            width: 100,
            dataIndex: 'priceRate',
            ellipsis: true,
        },
        {
            title: '计划售价',
            width: 100,
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    precision={2}
                    placeholder="请填写计划售价"
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={0}
                    onChange={(val) => {
                        list2[index].price = val
                    }}
                    onBlur={(e) => {
                        if (new BigNumber(form.getFieldValue('lastReceiptPrice')).toNumber() === 0 || e.target.value === '') {
                            list2[index].priceRate = 0
                        } else {
                            list2[index].priceRate = new BigNumber(e.target.value).dividedBy(form.getFieldValue('lastReceiptPrice')).toFixed(2)
                        }
                        setNumber(number + 1)
                    }}
                />
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择状态"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].status = val
                        if (val === ProductGeneralRate.STATUS_DISABLE) {
                            optionSelect[record.chargeType] = false
                        }
                        if (val === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[record.chargeType] = true
                        }
                        setNumber(number + 1)
                    }}
                >
                    <Option value={ProductGeneralRate.STATUS_ENABLE}
                            key={ProductGeneralRate.STATUS_ENABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_ENABLE]}</Option>
                    <Option value={ProductGeneralRate.STATUS_DISABLE}
                            key={ProductGeneralRate.STATUS_DISABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_DISABLE]}</Option>
                </Select>
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                        <Button type={"link"} onClick={() => {
                            if (record.chargeType === undefined || record.price === null || record.status === undefined) {
                                common.toast("请填写完整数据 再添加")
                                return false
                            }
                            setList2([
                                ...list2,
                                {
                                    id: record.id + 1,
                                    chargeType: undefined,
                                    priceRate: 0,
                                    price: null,
                                    status: undefined,
                                    kind: 'new'
                                }
                            ])
                        }}>添加</Button>}
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            if (list2.length === 1) {
                                if (list2[0].id === defaultList2.id) {
                                    defaultList2.id = defaultList2.id + 1
                                    setList2([defaultList2])
                                } else {
                                    setList2([defaultList2])
                                }
                                setOptionSelect(initialOption)
                            } else {
                                let arr = list2.filter((item) => {
                                    return item.id !== record.id
                                })
                                optionSelect[record.chargeType] = false
                                setList2(arr)
                            }
                        })
                    }}>删除
                    </Button>
                </div>)
        }
    ]

    let columnsTenant = [
        {
            title: '收费区分',
            dataIndex: 'chargeType',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择收费区分"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].chargeType = val
                        if (record.status === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[val] = true
                            optionSelect[text] = false
                        }
                        setNumber(number + 1)
                    }}
                >
                    {number && <>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_CLAIM]}
                                value={ProductGeneralRate.CHARGE_TYPE_CLAIM}
                                key={ProductGeneralRate.CHARGE_TYPE_CLAIM}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_CLAIM]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_REPAIR]}
                                value={ProductGeneralRate.CHARGE_TYPE_REPAIR}
                                key={ProductGeneralRate.CHARGE_TYPE_REPAIR}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_REPAIR]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_SALE]}
                                value={ProductGeneralRate.CHARGE_TYPE_SALE}
                                key={ProductGeneralRate.CHARGE_TYPE_SALE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_SALE]}</Option>
                        <Option disabled={optionSelect[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}
                                value={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}
                                key={ProductGeneralRate.CHARGE_TYPE_BOUTIQUE}>{ProductGeneralRate.ChargeTypeAlias[ProductGeneralRate.CHARGE_TYPE_BOUTIQUE]}</Option>
                    </>
                    }
                </Select>
        },
        {
            title: '加价率',
            width: 100,
            dataIndex: 'priceRate',
            ellipsis: true,
            render: (text, record, index) =>
                <InputNumber
                    defaultValue={text}
                    style={{width: "100%"}}
                    min={0}
                    placeholder={"格式如: 1.5, 0.8"}
                    onChange={(val) => {
                        list2[index].priceRate = val
                    }}
                />
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 150,
            ellipsis: true,
            render: (text, record, index) =>
                <Select
                    placeholder="请选择状态"
                    style={{width: '100%'}}
                    defaultValue={text}
                    onChange={(val) => {
                        list2[index].status = val
                        if (val === ProductGeneralRate.STATUS_DISABLE) {
                            optionSelect[record.chargeType] = false
                        }
                        if (val === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[record.chargeType] = true
                        }
                        setNumber(number + 1)
                    }}
                >
                    <Option value={ProductGeneralRate.STATUS_ENABLE}
                            key={ProductGeneralRate.STATUS_ENABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_ENABLE]}</Option>
                    <Option value={ProductGeneralRate.STATUS_DISABLE}
                            key={ProductGeneralRate.STATUS_DISABLE}>{ProductGeneralRate.StatusAlias[ProductGeneralRate.STATUS_DISABLE]}</Option>
                </Select>
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            ellipsis: true,
            render: (text, record, index) => (
                <div className={"action-btns"}>
                    {list2.length === index + 1 &&
                        <Button type={"link"} onClick={() => {
                            if (record.chargeType === undefined || record.priceRate === null || record.status === undefined) {
                                common.toast("请填写完整数据 再添加")
                                return false
                            }
                            setList2([
                                ...list2,
                                {
                                    id: record.id + 1,
                                    chargeType: undefined,
                                    priceRate: null,
                                    price: '0',
                                    status: undefined
                                }
                            ])
                        }}>添加</Button>}
                    <Button type={'link'} onClick={() => {
                        common.confirm("确认要删除吗?", () => {
                            if (list2.length === 1) {
                                if (list2[0].id === defaultList2.id) {
                                    defaultList2.id = defaultList2.id + 1
                                    setList2([defaultList2])
                                } else {
                                    setList2([defaultList2])
                                }
                                setOptionSelect(initialOption)
                            } else {
                                let arr = list2.filter((item) => {
                                    return item.id !== record.id
                                })
                                optionSelect[record.chargeType] = false
                                setList2(arr)
                            }
                        })
                    }}>删除
                    </Button>
                </div>)
        }
    ]

    const [form] = Form.useForm()

    // 获取数据
    let getData = (obj) => {
        let params = {
            taxonomy: obj.taxonomy,
            ownerId: obj.ownerId
        }
        common.loadingStart()
        common.ajax('get', '/wms/setting', params)
            .then(res => {
                if (res !== null) {
                    if (res.taxonomy === Term.GOODS_INSERT_DEFAULT_BRAND) {
                        let brandIdArr = res.flag === "" ? [] : res.flag.split(",")
                        setBrandIdList(brandIdArr)
                    } else if (res.taxonomy === Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID_AUXILIARY) {
                        setPartner2({id: res.flag})
                        form.setFieldsValue({supplierName2: res.spec})
                    } else if (res.taxonomy === Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID) {
                        setPartner({id: res.flag})
                        form.setFieldsValue({supplierName: res.spec})
                    }
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        // 默认品牌
        if (brandIdList.length > 0 && brand.length > 0) {
            let brandIdArr = []
            // 判断品牌ID是否在品牌列表当中
            for (let brandId of brandIdList) {
                for (let brandElement of brand) {
                    if (brandId === brandElement.id) {
                        brandIdArr.push(brandId);
                    }
                }
            }
            form.setFieldsValue({
                brands: brandIdArr
            })
        }
    }, [brandIdList, brand])

    useEffect(() => {
        // 公司端新增配件
        if (isCompany && defaultValue === undefined) {
            getData(initialInputForBrand)
            getData({
                ownerId: common.getUser().company.id,
                taxonomy: Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID,
            })
            getData({
                ownerId: common.getUser().company.id,
                taxonomy: Term.GOODS_INSERT_DEFAULT_SUPPLIER_ID_AUXILIARY,
            })
        }
    }, [])

    // 获取配件信息
    let getProduct = () => {
        if (defaultValue === undefined) {
            form.setFieldsValue({
                status: Product.STATUS_ALL,
                kind: "配件/精品",
                work: Product.IS_NO,
                material: Product.IS_NO,
            })
            setPageTopTitle("新增配件")
        }

        // 公司端新增
        if (defaultValue === undefined && isCompany) {
            form.setFieldsValue({
                positionName: ''
            })
        }

        if (defaultValue !== undefined) {
            common.loadingStart()
            common.ajax('get', '/wms/product/findById', {"id": defaultValue}).then((data) => {
                setPageTopTitle("修改配件")
                if (data.rates.length > 0) {
                    setList2(data.rates)
                    data.rates.forEach((item) => {
                        if (item.chargeType in optionSelect && item.status === ProductGeneralRate.STATUS_ENABLE) {
                            optionSelect[item.chargeType] = true
                        }
                    })
                }
                form.setFieldsValue({
                    ...data,
                    category1: data.terms.category1.termId,
                    productCategory: data.terms.productCategory !== undefined ? data.terms.productCategory.termId : null,
                    category2: data.terms.category2 !== undefined ? data.terms.category2.termId : "",
                    kind: "配件/精品",
                    supplierName2: data.supplierIdAuxiliaryName,
                    positionName: data.positionName === null ? '' : data.positionName
                })
                if (data.supplierId !== "") {
                    setPartner({id: data.supplierId})
                }
                if (data.supplierIdAuxiliary !== "") {
                    setPartner2({id: data.supplierIdAuxiliary})
                }
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    useEffect(getProduct, [defaultValue])

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE
        }).then((data) => {
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getBrand, [])

    // 表单提交
    let onFinish = (values) => {
        values.rebate = '0'
        values.price = '0'
        values.basicRebate = '0'
        if (values.warranty === null) {
            values.warranty = ''
        }

        // 一般商品加价率
        let arr = []
        let number = 0

        // 集团端
        if (!isCompany) {
            // 品牌
            values.brands = brandIds.map(item => item.id)

            // 一般商品加价率
            if (list2[0].priceRate !== null || list2[0].chargeType !== undefined || list2[0].status !== undefined) {
                list2.forEach((item) => {
                    if (item.priceRate === null || item.chargeType === undefined || item.status === undefined) {
                        number++
                    }
                    if (item.kind === 'new') {
                        item.id = ''
                    }
                    arr.push(item)
                })
            }
        }

        // 公司端
        if (isCompany) {
            if (list2[0].price !== null || list2[0].chargeType !== undefined || list2[0].status !== undefined) {
                list2.forEach((item) => {
                    if (item.price === null || item.chargeType === undefined || item.status === undefined) {
                        number++
                    }
                    if (item.kind === 'new') {
                        item.id = ''
                    }
                    arr.push(item)
                })
            }

            // 归属者ID
            values.ownerId = common.getUser().company.id
        }

        if (number !== 0) {
            common.toast("请填写完整数据")
            return false
        }
        values.rates = arr

        // 分类
        let termList = {
            "category1": values.category1
        }
        if (values.category2 !== null && values.category2 !== undefined && values.category2 !== "") {
            termList.category2 = values.category2
        }

        // 公司端 配件管理分类
        if (isCompany && values.productCategory !== null && values.productCategory !== undefined && values.productCategory !== "") {
            termList.productCategory = values.productCategory
        }

        values.terms = termList
        values.kind = Product.KIND_GOODS
        if (Object.keys(partner2).length > 0) {
            values.supplierIdAuxiliary = partner2.id
        } else {
            values.supplierIdAuxiliary = ''
        }
        values.supplierId = partner.id
        if (defaultValue === undefined) {
            values.haltProduction = Product.IS_NO
            values.haltProductionSpec = ""
        }
        if (defaultValue !== undefined) {
            values.id = defaultValue
        }
        values.canRepetitionHint = true
        onOk(values)
    }

    // 通过Taxonomy获取分类信息
    let getCategory = (taxonomy) => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: taxonomy,
            status: Term.TERM_STATUS_ENABLE,
            ownerId: isCompany ? common.getUser().company.id : ""
        }).then(data => {
            let arr = wmsPublic.getTree(data.terms, "")
            if (taxonomy === Term.TAXONOMY_ONE) {
                setTreeData1(arr)
                if (defaultValue === undefined && arr.length > 0 && arr[0].id !== undefined) {
                    form.setFieldsValue({category1: arr[0].id})
                }
                setNumber1(1)
            }
            if (taxonomy === Term.TAXONOMY_TWO) {
                setTreeData2(arr)
                setNumber2(2)
            }
            if (taxonomy === Term.PRODUCT_CATEGORY) {
                setTreeData3(arr)
                setNumber3(3)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    let getCategoryAll = () => {
        getCategory(Term.TAXONOMY_ONE)
        getCategory(Term.TAXONOMY_TWO)
        getCategory(Term.PRODUCT_CATEGORY)
    }

    useEffect(getCategoryAll, [])

    let print = () => {
        wmsPublic.printBarCode(wmsPublic.src, form.getFieldsValue().name, form.getFieldsValue().barCode)
    }

    // 提成设置
    let getSetupProduct = (productId) => {
        common.loadingStart()
        common.ajax("get", "/wms/product/setup", {
            productId: productId,
        }).then((data) => {
            if (data != null) {
                setProductSetup({
                    productId: productId,
                    ...data
                })
            }
            setModalNumber(4)
            setVisible(true)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 提成设置
    let setSetupProduct = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/setup", params).then((data) => {
            common.toast("操作成功")
            setVisible(false)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return <>
        {isModal && <br/>}
        <PageTop title={pageTopTitle}>
            {defaultValue !== undefined &&
                <Button onClick={() => getSetupProduct(defaultValue)}>提成设置</Button>}
            <Button icon={<SaveOutlined/>} type="primary" onClick={() => {
                if (Object.keys(partner).length === 0) {
                    common.alert("请选择品牌厂商")
                    return false
                }
                form.submit()
            }}>保存</Button>
            {defaultValue !== undefined &&
                <Button icon={<BarcodeOutlined/>} onClick={print}>打印条码</Button>}
            {!isModal &&
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    Search.back()
                }}>返回</Button>}
        </PageTop>
        <Form
            onFinish={onFinish}
            form={form}
            name={'form-data'}
            className={"ant-advanced-inline-form label-character-4"}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        rules={[
                            {required: true, message: '请输入配件编号'},
                            {whitespace: true, message: '配件编号不能为空字符串'},
                            {min: 2, message: '名称至少 2个字符'}
                        ]}
                        name={'number'}
                        label="配件编号">
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        rules={[{required: true, message: '请选择品牌厂商'}]}
                        name={'supplierName'}
                        label={"品牌厂商"}>
                        <Input
                            readOnly={true}
                            autoComplete={"off"}
                            onKeyPress={() => {
                                setModalTitle("选择品牌厂商")
                                setModalNumber(1)
                                setVisible(true)
                            }}
                            suffix={(partner.id !== undefined && partner.id !== "") ?
                                <CloseOutlined type={'close'} onClick={() => {
                                    setPartner({})
                                    form.setFieldsValue({supplierName: ""})
                                }}/> : <span/>}
                            addonAfter={<SelectOutlined onClick={() => {
                                setModalTitle("选择品牌厂商")
                                setModalNumber(1)
                                setVisible(true)
                            }} type={'select'}/>}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        className={'label-character-6'}
                        name={'supplierName2'}
                        label={"辅助供应厂商"}>
                        <Input
                            readOnly={true}
                            autoComplete={"off"}
                            onKeyPress={() => {
                                setModalTitle("选择辅助供应厂商")
                                setModalNumber(2)
                                setVisible(true)
                            }}
                            suffix={(partner2.id !== undefined && partner2.id !== "") ?
                                <CloseOutlined type={'close'} onClick={() => {
                                    setPartner2({})
                                    form.setFieldsValue({supplierName2: ""})
                                }}/> : <span/>}
                            addonAfter={<SelectOutlined onClick={() => {
                                setModalTitle("选择辅助供应厂商")
                                setModalNumber(2)
                                setVisible(true)
                            }} type={'select'}/>}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        rules={[
                            {required: true, message: '请输入名称'},
                            {whitespace: true, message: '配件名称不能为空字符串'}
                        ]}
                        name={'name'}
                        label="配件名称">
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        rules={[{required: true, message: '请选择是否施工'}]}
                        name={'work'}
                        label="是否施工">
                        <Select>
                            <Option value={Product.IS_YES}
                                    key={Product.IS_YES}>{Product.YesOrNoAlias[Product.IS_YES]}</Option>
                            <Option value={Product.IS_NO}
                                    key={Product.IS_NO}>{Product.YesOrNoAlias[Product.IS_NO]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                {number1 === 1 &&
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-6'}
                            rules={[{required: true, message: '请选择集团管理分类'}]}
                            name={'category1'}
                            label="集团管理分类"
                        >
                            <TreeSelect
                                suffixIcon={
                                    <Tooltip title={'正序排序'}><QuestionCircleOutlined/></Tooltip>
                                }
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                treeData={treeData1}
                                treeDefaultExpandAll
                            />
                        </Form.Item>
                    </Col>}
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        rules={[
                            {required: true, message: '请输入单位'},
                            {whitespace: true, message: '单位不能为空字符串'},
                        ]}
                        name={'unit'}
                        label="计量单位">
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'barCode'}
                        label="配件条码">
                        <Input placeholder={"配件条码自动生成"} disabled={true}/>
                    </Form.Item>
                </Col>
                {number2 === 2 &&
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-6'}
                            name={'category2'}
                            label="集团统计分类">
                            <TreeSelect
                                suffixIcon={
                                    <Tooltip title={'正序排序'}><QuestionCircleOutlined/></Tooltip>
                                }
                                allowClear={true}
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                treeData={treeData2}
                                treeDefaultExpandAll
                            />
                        </Form.Item>
                    </Col>}
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        rules={[{required: true, message: '请选择状态'}]}
                        name={'status'}
                        label={"状态"}>
                        <Select disabled={defaultValue === undefined}>
                            <Option value={Product.STATUS_ALL}
                                    key={Product.STATUS_ALL}>{Product.StatusAlias[Product.STATUS_ALL]}</Option>
                            <Option value={Product.STATUS_AFTER_SALE}
                                    key={Product.STATUS_AFTER_SALE}>{Product.StatusAlias[Product.STATUS_AFTER_SALE]}</Option>
                            <Option value={Product.STATUS_NONE}
                                    key={Product.STATUS_NONE}>{Product.StatusAlias[Product.STATUS_NONE]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        rules={[{required: true, message: '请选择商品类别'}]}
                        name={'kind'}
                        label="商品类别">
                        <Input disabled={true}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        className={'label-character-6'}
                        name={'material'}
                        rules={[{required: true, message: '请选择是否保养材料'}]}
                        label="是否保养材料">
                        <Select>
                            <Option value={Product.IS_YES}
                                    key={Product.IS_YES}>{Product.YesOrNoAlias[Product.IS_YES]}</Option>
                            <Option value={Product.IS_NO}
                                    key={Product.IS_NO}>{Product.YesOrNoAlias[Product.IS_NO]}</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        name={'sort'}
                        label={"排序"}>
                        <InputNumber
                            precision={0}
                            min={0}
                            style={{width: "100%"}}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'code'}
                        label="档案编号">
                        <Input disabled={true} placeholder={defaultValue === undefined ? "配件编号自动生成" : ""}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        className={'label-character-6'}
                        name={'warranty'}
                        label="质保期(天)">
                        <InputNumber
                            precision={0}
                            style={{width: "100%"}}
                            min={0}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                {defaultValue === undefined && !isCompany &&
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择适用品牌'}]}
                            name={'brandsName'}
                            label={"适用品牌"}>
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={brandIds.id !== undefined ?
                                    <CloseOutlined type={'close'} onClick={() => {
                                        setBrandIds([])
                                        form.setFieldsValue({brandsName: ""})
                                    }}/> : <span/>}
                                onKeyPress={() => {
                                    setModalTitle("选择适用品牌")
                                    setModalNumber(3)
                                    setVisible(true)
                                }}
                                addonAfter={<SelectOutlined onClick={() => {
                                    setModalTitle("选择适用品牌")
                                    setModalNumber(3)
                                    setVisible(true)
                                }} type={'select'}/>}
                            />
                        </Form.Item>
                    </Col>}
                {defaultValue === undefined && isCompany && brand.length > 0 &&
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择适用品牌'}]}
                            name={'brands'}
                            label={"适用品牌"}>
                            <Select
                                mode="multiple"
                                options={brand.map(item => {
                                    return {
                                        label: item.name,
                                        value: item.id,
                                    }
                                })}
                            >
                            </Select>
                        </Form.Item>
                    </Col>}

                {isCompany && defaultValue !== undefined &&
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择是否停产'}]}
                            name={'haltProduction'}
                            label="是否停产">
                            <Select>
                                <Option value={Product.IS_YES}
                                        key={Product.IS_YES}>{Product.YesOrNoAlias[Product.IS_YES]}</Option>
                                <Option value={Product.IS_NO}
                                        key={Product.IS_NO}>{Product.YesOrNoAlias[Product.IS_NO]}</Option>
                            </Select>
                        </Form.Item>
                    </Col>}

                <Col span={8}>
                    <Form.Item
                        name={'source'}
                        label="来源渠道">
                        <Input/>
                    </Form.Item>
                </Col>

                {isCompany &&
                    <>
                        {number3 === 3 &&
                            <Col span={8}>
                                <Form.Item
                                    className={'label-character-6'}
                                    name={'productCategory'}
                                    label="配件管理分类"
                                >
                                    <TreeSelect
                                        suffixIcon={
                                            <Tooltip title={'正序排序'}><QuestionCircleOutlined/></Tooltip>
                                        }
                                        style={{width: '100%'}}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        treeData={treeData3}
                                        treeDefaultExpandAll
                                    />
                                </Form.Item>
                            </Col>}
                        <Col span={8}>
                            <Form.Item
                                className={'label-character-4'}
                                name={'positionName'}
                                label="仓位">
                                <Input/>
                            </Form.Item>
                        </Col>
                    </>
                }
                {!isCompany && defaultValue !== undefined &&
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '请选择是否停产'}]}
                            name={'haltProduction'}
                            label="是否停产">
                            <Select>
                                <Option value={Product.IS_YES}
                                        key={Product.IS_YES}>{Product.YesOrNoAlias[Product.IS_YES]}</Option>
                                <Option value={Product.IS_NO}
                                        key={Product.IS_NO}>{Product.YesOrNoAlias[Product.IS_NO]}</Option>
                            </Select>
                        </Form.Item>
                    </Col>}

                <Col span={8}>
                    <Form.Item
                        className={isCompany ? 'label-character-4' : 'label-character-6'}
                        name={'applicableModels'}
                        label="适用车型">
                        <Input/>
                    </Form.Item>
                </Col>

                {defaultValue !== undefined &&
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'haltProductionSpec'}
                            label="停产说明">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>}
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        className={defaultValue === undefined ? 'label-character-2' : 'label-character-4'}
                        name={'spec'}
                        label="备注">
                        <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <>
            <Divider orientation={'left'}>一般商品加价率</Divider>
            {isCompany &&
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={defaultValue === undefined ? columnsCompanyCreate : columnsCompanyUpdate}
                    dataSource={list2}
                    scroll={{x: "100%"}}
                />}
            {!isCompany &&
                <Table
                    pagination={false}
                    rowKey="id"
                    columns={columnsTenant}
                    dataSource={list2}
                    scroll={{x: "100%"}}
                />}
        </>
        {/*<Divider orientation={'left'}>图片</Divider>*/}
        {/*<Upload*/}
        {/*    customRequest={upload}*/}
        {/*    // action="https://www.erp-auto.com/api/wms/oss/upload"*/}
        {/*    listType="picture-card"*/}
        {/*    fileList={fileList}*/}
        {/*    onPreview={handlePreview}*/}
        {/*    onChange={handleChange}*/}
        {/*>*/}
        {/*    {fileList.length >= 10 ? null : uploadButton}*/}
        {/*</Upload>*/}
        {/*<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>*/}
        {/*    <img*/}
        {/*        alt="example"*/}
        {/*        style={{*/}
        {/*            width: '100%',*/}
        {/*        }}*/}
        {/*        src={previewImage}*/}
        {/*    />*/}
        {/*</Modal>*/}
        <Modal
            maskClosable={false}
            title={modalTitle}
            visible={visible}
            footer={null}
            width={1000}
            destroyOnClose={true}
            onCancel={() => {
                setVisible(false)
            }}
        >
            {(modalNumber === 1 || modalNumber === 2) &&
                <Partner
                    dataSource={3}
                    onOk={value => {
                        if (modalNumber === 1) {
                            form.setFieldsValue({supplierName: value.name})
                            setPartner(value)
                        }
                        if (modalNumber === 2) {
                            form.setFieldsValue({supplierName2: value.name})
                            setPartner2(value)
                        }
                        setVisible(false)
                    }}/>}
            {modalNumber === 3 &&
                <LookupBrand
                    isCompany={isCompany}
                    isMultiple={true}
                    onOk={(val) => {
                        setBrandIds(val)
                        form.setFieldsValue({brandsName: val.map((item) => item.name).join(', ')})
                        setVisible(false)
                    }}/>}
            {modalNumber === 4 &&
                <ProductSetupForm
                    defaultValue={productSetup}
                    onOk={(val) => {
                        setSetupProduct(val)
                    }}/>}
        </Modal>
    </>;
}

export default ProductGoods;