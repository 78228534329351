class Basic {
    static IS_YES = 'yes';
    static IS_NO = 'no';
}

class TaxonomyEnum {
    // 卡券是否需要进行人工发放
    static TICKET_GRANT = 'ticketGrant'

    // 方案审核政策价是否允许修改
    static PLAN_AUDIT_PRICE = 'planAuditPrice'

    // 方案类别
    static PLAN_CATEGORY = 'planCategory'
}

class Coupon {
    // 礼券类型
    static TYPE_CASH = 1 // 面额券
    static TYPE_DISCOUNT = 2 // 折扣券
    static TYPE_PROJECT = 3 // 项目券

    // 是否绑定VIN
    static BIND_VIN_YES = 1
    static BIND_VIN_NO = 2

    // 礼券财务类型
    static CATEGORY1_ADVANCE = "1" // 销售礼券-预收券
    static CATEGORY1_CLEAR = "2" // 优惠/折扣券-明折
    static CATEGORY1_INCREASE = "3" // 增费礼券

    // 礼券业务类型
    static CATEGORY2_COMMON = "1" // 通用券
    static CATEGORY2_HOUR = "2" // 工时券
    static CATEGORY2_MATERIAL = "3" // 材料券
    static CATEGORY2_BUSINESS = "4" // 代办业务抵扣券
    static CATEGORY2_APPLIANCE = "5" // 用品券

    // 礼券销售场景
    static SCENE_OUTLINE = "1" // 线下
    static SCENE_WE_CHAT = "2" // 微信商城
    static SCENE_ACTIVITY = "3" // 活动赠送

    // 礼券有效期类型
    static VALID_TYPE_REGION = 1 // 固定区间
    static VALID_TYPE_LENGTH = 2 // 固定时长

    // 回收部门
    static RECYCLE_DEPARTMENT_ALL = 1 // 销售售后都可用
    static RECYCLE_DEPARTMENT_SALE = 2 // 销售
    static RECYCLE_DEPARTMENT_SUPPORT = 3 // 售后

    // 回收时,是否允许和其他类型券一起使用
    static ALLOT_TOGETHER_YES = 1;
    /*** 否 **/
    static ALLOT_TOGETHER_NO = 2;

    static AllotTogetherAlias = {
        [this.ALLOT_TOGETHER_YES]: '允许',
        [this.ALLOT_TOGETHER_NO]: '不允许',
    }

    // 礼券状态
    static STATUS_VALID = 1 // 启用
    static STATUS_INVALID = 2 // 禁用

    static RecycleDepartmentAlias = {
        [this.RECYCLE_DEPARTMENT_ALL]: '销售售后都可用',
        [this.RECYCLE_DEPARTMENT_SALE]: '销售',
        [this.RECYCLE_DEPARTMENT_SUPPORT]: '售后',
    }

    static BindAlias = {
        [this.BIND_VIN_YES]: '是',
        [this.BIND_VIN_NO]: '否',
    }

    static TypeAlias = {
        [this.TYPE_CASH]: '面额券',
        [this.TYPE_DISCOUNT]: '折扣券',
        [this.TYPE_PROJECT]: '项目券'
    }

    static Category1s = {
        [this.CATEGORY1_ADVANCE]: '销售礼券-预收券',
        [this.CATEGORY1_CLEAR]: '优惠/折扣券-明折',
        [this.CATEGORY1_INCREASE]: '增费礼券',
    }
    static Category2s = {
        [this.CATEGORY2_COMMON]: '通用券',
        [this.CATEGORY2_HOUR]: '工时券',
        [this.CATEGORY2_MATERIAL]: '材料券(含配件及用品)',
        [this.CATEGORY2_BUSINESS]: '代办业务抵扣券',
        [this.CATEGORY2_APPLIANCE]: '用品券'
    }
    static scenes = {
        [this.SCENE_OUTLINE]: '线下',
        [this.SCENE_WE_CHAT]: '微信商城',
        [this.SCENE_ACTIVITY]: '活动赠送'
    }

    static validTypes = {
        [this.VALID_TYPE_REGION]: '固定区间',
        [this.VALID_TYPE_LENGTH]: '固定时长'
    }
    static stats = {[this.STATUS_VALID]: '启用', [this.STATUS_INVALID]: '禁用'}
}

class Ticket {
    // 是否需要人工发放 1:是 0:否
    static SETTING_GRANT_NO = "0";
    static SETTING_GRANT_YES = "1";

    static STATUS_AUDIT = -1 // 待发放
    static STATUS_UNLOCKED = 1 // 未锁定
    static STATUS_LOCKED = 2 // 已锁定
    static STATUS_USED = 3 // 已使用
    static STATUS_INVALID = 4 // 无效数据
    static STATUS_APPLIED = 6 // 已核销

    static StatusAlias = {
        [this.STATUS_AUDIT]: '待发放',
        [this.STATUS_UNLOCKED]: '未使用',
        [this.STATUS_LOCKED]: '已锁定',
        [this.STATUS_USED]: '已使用',
        [this.STATUS_APPLIED]: '已核销',
        [this.STATUS_INVALID]: '退单作废'
    }

    static TYPE_SALE = 1 // 销售
    static TYPE_AFTER_SALE = 2 // 售后
    static TYPE_SALE_AND_REPAIR = 3 // 售后 和 销售

    static DepartmentTypeAlias = {
        [this.TYPE_SALE]: '仅销售可用',
        [this.TYPE_AFTER_SALE]: '仅售后可用',
        [this.TYPE_SALE_AND_REPAIR]: '销售售后都可用',
    }

    static IS_SEND_NO = 1;
    static IS_SEND_YES = 2;

    static IsSendAlias = {
        [this.IS_SEND_NO]: '未发送',
        [this.IS_SEND_YES]: '已发送',
    }

    // 卡券重复发放
    static APPLIED_TYPE_REPEAT = 1;
    // 客户放弃礼券
    static APPLIED_TYPE_GIVE_UP = 2;
    // 过期手工核销
    static APPLIED_TYPE_OVERDUE_HAND = 3;
    // 过期自动核销
    static APPLIED_TYPE_OVERDUE_AUTO = 4;
    // 其他原因
    static APPLIED_TYPE_OTHER = 5;

    static AppliedTypeAlias = {
        [this.APPLIED_TYPE_REPEAT]: '卡券重复发放',
        [this.APPLIED_TYPE_GIVE_UP]: '客户放弃礼券',
        [this.APPLIED_TYPE_OVERDUE_HAND]: '过期手工核销',
        [this.APPLIED_TYPE_OVERDUE_AUTO]: '过期自动核销',
        [this.APPLIED_TYPE_OTHER]: '其他原因',
    }
}

class Plan {
    //方案适用部门
    static DEPARTMENT_SALE = "1" // 仅销售可用
    static DEPARTMENT_SUPPORT = "2" // 仅售后可用
    static DEPARTMENT_SALE_AND_SUPPORT = "3" // 售后销售都可用

    //方案状态
    static STATUS_VALID = 1 // 启用
    static STATUS_INVALID = 2 // 禁用

    //可否编辑
    static CAN_EDIT_YES = 1 // 可编辑
    static CAN_EDIT_NO = 2 // 不可编辑

    static departments = {
        [this.DEPARTMENT_SALE]: '仅销售可用',
        [this.DEPARTMENT_SUPPORT]: '仅售后可用',
        [this.DEPARTMENT_SALE_AND_SUPPORT]: '售后销售都可用'
    }

    static StatusAlias = {
        [this.STATUS_VALID]: '启用',
        [this.STATUS_INVALID]: '禁用'
    }
}

class Order {
    static BUSINESS_TYPE_SALE = 1 //销售
    static BUSINESS_TYPE_SUPPORT = 2 //售后
    static BUSINESS_SALE_TYPE_SALE = 3;
    static BUSINESS_SALE_TYPE_SUPPORT = 4;

    static BusinessTypes = {
        [this.BUSINESS_TYPE_SALE]: '销售',
        [this.BUSINESS_TYPE_SUPPORT]: '售后',
        [this.BUSINESS_SALE_TYPE_SALE]: '销售',
        [this.BUSINESS_SALE_TYPE_SUPPORT]: '售后',
    }

    // 状态 10.待支付 11.确认结算 20.已支付 30.已发放 40.废弃
    static STATUS_WAITING = 10;
    static STATUS_SETTLEMENT = 11;
    static STATUS_PAID = 20;
    static STATUS_SENT = 30;
    static STATUS_INVALID = 40;

    static StatusAlias = {
        [this.STATUS_WAITING]: '待支付',
        [this.STATUS_SETTLEMENT]: '确认结算',
        [this.STATUS_PAID]: '已支付',
        [this.STATUS_SENT]: '已发放',
        [this.STATUS_INVALID]: '已作废',
    }
}

// const dateFormat = date => typeof date === 'string' ? date.match(/\d{4}-\d{2}-\d{2}/) : ''

export {
    Coupon,
    Ticket,
    Plan,
    Order,
    // dateFormat,
    Basic,
    TaxonomyEnum
}
