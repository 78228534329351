import React from "react"
import {Route, Switch} from "react-router-dom"
import Coupons from './template'
import Plan from './plan'
import CouponCreate from './template/create'
import TicketGrant from './setting'
import PlanCreate from "./plan/create";
import PlanUpdate from "./plan/update";
import common from "../../utils/common";
import CouponImport from "./template/importData";

function Coupon() {
    return (
        <Switch>
            {/*礼券 数据导入*/}
            <Route exact path={'/admin/coupon/import'} component={CouponImport}/>

            {/*礼券发放设置*/}
            <Route exact path={'/admin/coupon/setting'} component={TicketGrant}/>

            {/*礼券模板管理*/}
            {
                common.can('admin.coupon.coupon') ?
                    <Route exact path={'/admin/coupon/create'} component={CouponCreate}/>
                    : null
            }
            {
                common.can('admin.coupon.coupon') ?
                    <Route exact path={'/admin/coupon'} component={Coupons}/>
                    : null
            }

            {/*礼券方案管理*/}
            {
                common.can('admin.coupon.plan') ?
                    <Route exact path={'/admin/coupon/plan/create'} component={PlanCreate}/>
                    : null
            }
            {
                common.can('admin.coupon.plan') ?
                    <Route exact path={'/admin/coupon/plan/:id'} component={PlanUpdate}/>
                    : null
            }
            {
                common.can('admin.coupon.plan') ?
                    <Route exact path={'/admin/coupon/plan'} component={Plan}/>
                    : null
            }
        </Switch>
    )
}

export default Coupon
