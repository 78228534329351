import React, {useEffect, useState} from "react";
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import {Button, Divider, Form, Modal, Table} from "antd";
import {
    AlipayCircleOutlined,
    CreditCardOutlined,
    MoneyCollectOutlined,
    ScanOutlined,
    WechatOutlined
} from "@ant-design/icons";
import "./style.less"
import moment from "moment";
import Payment from "./payment";
import InputPay from "./inputPay";
import BigNumber from "bignumber.js";
import ApplyForm from "../../payable/component/applyForm";
import {Term} from "../../../../components/finance/config";

const PAY_CREATE = Symbol()
const PAY_FIND = Symbol()

function Index(props) {

    let businessName =
        {
            1: { //销售 --待定
                1: "销售业务1",
                2: "销售业务2",
                3: "销售业务3",
                4: "销售业务4"
            },
            2: {
                1: "客户订单(零售)",
                2: "诚意金订单",
                3: "试驾车订单",
                4: "展车订单",
                5: "调拨订单",
                6: "区域批发订单",
                7: "待交车订单",
                8: "精品订单"
            },
            3: {
                1: "预收业务1",
                2: "预收业务2",
                3: "预收业务3",
                4: "预收业务4"
            }
        }


    const BUSINESS_SUPPORT = 1
    const BUSINESS_TRANSFER = 2
    const BUSINESS_INTERNAL = 3
    const BUSINESS_PART = 4
    const BUSINESS_PART_BACK = -4
    const BUSINESS_ARTICLE = 5
    const BUSINESS_ARTICLE_BACK = -5
    const BUSINESS_CAR = 6
    const BUSINESS_CAR_BACK = -6
    const BUSINESS_VALUE_ADDED = 7
    const BUSINESS_ORDER = 8

    const BusinessName = {
        [BUSINESS_SUPPORT]: "委外维修",
        [BUSINESS_TRANSFER]: "调拨单",
        [BUSINESS_INTERNAL]: "委内业务",
        [BUSINESS_PART]: "配件入库",
        [BUSINESS_PART_BACK]: "配件入库退货",
        [BUSINESS_ARTICLE]: "精品入库",
        [BUSINESS_ARTICLE_BACK]: "精品入库退货",
        [BUSINESS_CAR]: "车辆入库",
        [BUSINESS_CAR_BACK]: "车辆入库退货",
        [BUSINESS_VALUE_ADDED]: "增值业务",
        [BUSINESS_ORDER]: "销售费用",
    };

    const ParentColumns = [
        {
            title: "申请日期",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "申请编号",
            dataIndex: "code",
            key: "code",
            align: "center",
            ellipsis: true,
            width: 150,
            render: (text, record) => {
                return (
                    <Button type={'link'} onClick={(e) => {
                        setApplyId(record.id)
                        setApplyInfoVisible(true)
                        e.stopPropagation()
                    }}>{text}</Button>
                )
            }
        },
        {
            title: "申请人",
            dataIndex: "creatorName",
            key: "creatorName",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return text ? text : ''
            }
        },
        {
            title: "待付金额",
            // dataIndex: "total",
            // key: "total",
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return common.numberFormat(new BigNumber(record.total).minus(new BigNumber(record.paid)).toString())
            }
        },
        {
            title: "发票金额",
            dataIndex: "invoiceMoney",
            key: "invoiceMoney",
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return text ? common.numberFormat(text) : '0.00'
            }
        },
        {
            title: "差额",
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record) => {
                return new BigNumber(record.total).minus(new BigNumber(record.invoiceMoney)).toString()
            }
        },
        {
            title: "发票编号",
            dataIndex: "invoiceNo",
            key: "invoiceNo",
            align: "center",
            ellipsis: true,
            width: 100
        },
        {
            title: "发票类型",
            dataIndex: "invoiceTypeCodeName",
            key: "invoiceTypeCodeName",
            align: "center",
            ellipsis: true,
            width: 100
        },
        {
            title: "申请说明",
            dataIndex: "spec",
            key: "spec",
            align: "center",
            ellipsis: true,
            width: 200,
        },
    ]

    const childrenColumns = [
        {
            title: "创建时间",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "结算单号",
            dataIndex: "code",
            key: "code",
            align: "center",
            width: 150,
            ellipsis: true
        },
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            width: 150,
            ellipsis: true,
        },
        {
            title: "业务类型",
            dataIndex: "businessType",
            align: "center",
            key: "businessType",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (
                    BusinessName[text]
                )
            },
        },
        {
            title: "应付账款",
            dataIndex: 'total',
            key: 'total',
            align: "right",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "已付金额",
            dataIndex: 'paid',
            key: 'paid',
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "应付余额",
            ellipsis: true,
            align: "right",
            width: 100,
            render: (text, record, index) => {
                return common.numberFormat(new BigNumber(record.total).minus(record.paid).toString())
            }
        },
    ]

    let viewColumns = [
        {
            title: "业务单号",
            dataIndex: "businessOrderCode",
            key: "businessOrderCode",
            align: "center",
            render: (text) => {
                return text ? text : "-"
            }
        },
        {
            title: "业务类型",
            dataIndex: "financeCategoryName",
            width: 150,
            ellipsis: true,
            align: "center"
        },
        {
            title: "付款",
            dataIndex: "money",
            key: "money",
            align: "right",
            render: (text) => {
                return common.numberFormat(text)
            }
        },
        {
            title: "说明",
            dataIndex: "spec",
            key: "spec",
            align: "center"

        },
        {
            title: "付款时间",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (text, record, index) => {
                return moment(text, "YYYY-MM-DD ").format('YYYY-MM-DD ');
            }
        },
        {
            title: "票据类型",
            dataIndex: "invoiceTypeCode",
            render: (text, record, index) => {
                return invoiceTypeObj[text]
            },
            align: "center"
        }
    ];

    const payId = props.match.params.id

    let [customerId, setCustomerId] = useState("")
    let [customerName, setCustomerName] = useState("")
    let [data, setData] = useState([])
    let [invoiceType, setInvoiceType] = useState([{code: "", name: ""}]);
    let [invoiceTypeObj, setInvoiceTypeObj] = useState({});
    let [payments, setPayments] = useState([])
    let [channelVisible, setChannelVisible] = useState(false)
    let [payMoney, setPayMoney] = useState("")
    let [typeCode, setTypeCode] = useState("")
    let [isCommit, setIsCommit] = useState(false)
    let [createTime, setCreateTime] = useState("")
    let [createdName, setCreatedName] = useState('')
    let [payCode, setPayCode] = useState("")
    let [total, setTotal] = useState("")

    let [applyId, setApplyId] = useState('')
    let [applyInfoVisible, setApplyInfoVisible] = useState(false)

    let [keys, setKeys] = useState([])

    let [payType, setPayType] = useState('pay')

    let getScan = () => {
        if (payId === undefined) {
            return PAY_CREATE
        }
        return PAY_FIND
    }

    let init = () => {
        if (getScan() == PAY_CREATE) {
            let ids = []
            let customerId = ""
            let payType = ''
            props.location.search.split("&").forEach((item, index) => {
                if (item.indexOf('ids=') != -1) {
                    ids.push(item.split("ids=")[1])
                } else if (item.indexOf('cid=') != -1) {
                    customerId = item.split("cid=")[1]
                    setCustomerId(customerId)
                } else if (item.indexOf('payType=') != -1) {
                    payType = item.split('payType=')[1]
                }
            })
            // common.consoleLog(payType)
            setPayType(payType)
            let params = {
                customerId: customerId,
                ids: ids,
                ownerId: common.getUser().company.id
            }

            common.consoleLog(ids)
            common.loadingStart()
            common.ajax("get", "/finance/apply/findByIds", params).then(data => {
                common.consoleLog(data, 'data')
                setCustomerName(data.customerName)
                common.ajax("get", "/finance/term/findByTaxonomy", {
                    taxonomy: "financeCategory",
                    status: Term.STATUS_YES,// 启用
                }).then(data1 => {
                    data1 = data1.filter(item => item.status != 2)
                    common.ajax("get", "/finance/term/findByTaxonomy?taxonomy=invoiceType").then(invoiceData => {
                        let dataSource = []
                        data.applyList.forEach(applyItem => {
                            let index = invoiceData.findIndex(invoice => invoice.code == applyItem.invoiceTypeCode)
                            if (index != -1) {
                                applyItem.invoiceTypeCodeName = invoiceData[index].name
                            }
                            applyItem.payableList.forEach(payableItem => {
                                payableItem.itemList.forEach(item => {
                                    let index = data1.findIndex(a => a.id == item.financeCategoryId)
                                    if (index != -1) {
                                        item.financeCategoryName = data1[index].name
                                    }
                                    item.businessOrderCode = payableItem.businessOrderCode
                                })
                            })
                            if (payType == 'pay' && parseInt(applyItem.paid) == 0) {
                                dataSource.push(applyItem)
                            } else if (payType == 'cancel' && (parseInt(applyItem.total) == 0 || parseInt(applyItem.paid) != 0)) {
                                dataSource.push(applyItem)
                            }
                        })

                        setData([...dataSource])
                    }).finally(common.loadingStop)
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        } else {
            common.loadingStart()
            common.ajax("get", "/finance/pay/findById", {
                ownerId: common.getUser().company.id,
                payId: payId
            }).then(data => {
                setCustomerName(data.customerName)
                setPayCode(data.code)
                setCreateTime(data.createdAt)
                setCreatedName(data.creatorName)
                setTotal(data.total)
                common.ajax("get", "/finance/term/findByTaxonomy", {
                    taxonomy: "financeCategory",
                    status: Term.STATUS_YES,// 启用
                }).then(data1 => {
                    data1 = data1.filter(item => item.status != 2)
                    let list = data.payItems
                    let dataSource = []
                    list.forEach(item => {
                        data1.forEach(dItem => {
                            if (item.financeCategoryId == dItem.id) {
                                item.financeCategoryName = dItem.name
                            }
                        })
                        dataSource.push(item)
                    })
                    setData([...dataSource])
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        }
    }

    let getInvoiceType = () => {
        common.loadingStart();
        common.ajax("get", "/finance/term/findByTaxonomy", {
            status: Term.STATUS_YES,// 启用
            taxonomy: 'invoiceType'
        }).then((data) => {
            data = data.filter(item => item.status != 2)
            let obj = {}
            data.forEach(item => {
                obj = {...obj, [item.code]: item.name}
            })
            setInvoiceTypeObj(obj)
            setInvoiceType([...data]);
        }).finally(common.loadingStop)
    }

    useEffect(init, [])

    useEffect(getInvoiceType, [])

    let getTotal = () => {
        let sum = new BigNumber('0');
        let data = getItemData()
        data.forEach(item => {
            sum = sum.plus(new BigNumber(item.thisReceipt))
        })
        return sum.toString();
    }

    const rowSelection = {
        selectedRowKeys: keys,
        onChange: (selectedRowKeys, selectedRows) => {
            setKeys(selectedRowKeys)
        },
        getCheckboxProps: record => ({
            disabled: isCommit, // Column configuration not to be checked
        }),
    };

    let getTime = (onlyDay) => {
        const date = new Date();
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString();
        const day = date.getDate().toString();
        const hour = date.getHours().toString();
        const minuse = date.getMinutes().toString();
        const second = date.getSeconds().toString();

        if (onlyDay) {
            return year + "-" + month + "-" + day;
        }
        return year + "-" + month + "-" + day + " " + hour + ":" + minuse + ":" + second;
    }

    let getPayMoney = () => {
        let money = getTotal()
        let pay = new BigNumber('0')
        payments.forEach(item => {
            pay = pay.plus(new BigNumber(item.money))
        })

        return new BigNumber(money).minus(pay).toString()
    }

    let onPayment = (id) => {
        let payment = payments

        payment = payment.filter(item => {
            return item.id !== id
        })

        setPayments([...payment])
    }

    let onSave = (values) => {
        console.log("values", values)
        let payment = payments
        if (payment.length > 0) {
            values.forEach(item => {
                payment.forEach(pItem => {
                    if (item.id === pItem.id) {
                        pItem.money = item.money
                    }
                })
            })

            for (let i = 0; i < payment.length; i++) {
                values = values.filter(val => {
                    return val.id !== payment[i].id
                })
            }

            values.forEach(item => {
                payment.push(item)
            })
        } else {
            values.forEach(item => {
                payment.push(item)
            })
        }

        setPayments([...payment])
        setChannelVisible(false)
    }

    let onChangeChannel = (typeCode) => {
        let num = 0
        if (getItemData().length <= 0) {
            common.confirm("请先选择申请单")
            return
        }

        setPayMoney(getPayMoney)
        setTypeCode(typeCode)
        setChannelVisible(true)
    }

    let getPayInfo = () => {
        let info = new BigNumber('0');
        payments.forEach(item => {
            info = info.plus(new BigNumber(item.money))
        })
        return info.toString();
    }

    let showReceipt = (method) => {
        if (getScan() == PAY_CREATE) {
            const rids = [];
            props.location.search.split("&").forEach((item, index) => {
                if (index > 0) {
                    rids.push(item.split("ids=")[1])
                }
            });
            if (rids.length <= 0) {
                common.toast("请选择付款单");
            } else {
                let payTotal = new BigNumber('0');
                payments.forEach(item => {
                    payTotal = payTotal.plus(new BigNumber(item.money))
                })

                let receiptTotal = new BigNumber('0');
                let arr = getItemData()
                arr.forEach(item => {
                    receiptTotal = receiptTotal.plus(new BigNumber(item.thisReceipt))
                })

                if (keys.length <= 0) {
                    common.confirm("请选择申请单");
                } else if (payments.length <= 0) {
                    common.confirm("请选择渠道支付方式");
                } else if (Number(receiptTotal.toString()) !== Number(payTotal.toString())) {
                    common.confirm("支付总金额与渠道总金额不符");
                } else if (Number(payTotal.toString()) == 0 && Number(receiptTotal.toString()) == 0) {
                    common.confirm('结算金额为0，确定提交吗？', () => {
                        doReceipt()
                    })
                } else {
                    if (method === "pay") {
                        common.confirm("本次支付:" + getPayInfo(), doReceipt)
                    } else if (method === "print") {
                        print();
                    }
                }
            }
        } else {
            print()
        }
    }

    let print = () => {
        let payment = {};
        let totle = parseFloat(0);

        let receipt = [];
        let tmpData = getScan() == PAY_FIND ? data : getItemData();
        tmpData.forEach(item => {
            item.orderCode = item.businessItemId;
            // item.business = businessName[item.type][item.business];
            receipt.push(item)
        })

        let receiptPrint = {
            companyName: common.getUser().company.name,
            receiptCreatedAt: getTime(),
            receiptCode: "",
            receiptType: "付款",
            customerName: customerName,
            summary: "摘要",
            remark: "备注",
            totle,
            pay: payment,
            receipt
        }

        let printInfo = {
            "code": "finance_receipt",                           //打印模板code
            "owner_id": common.getUser().company.id,  //公司id
            "data": receiptPrint                              //打印数据
        }

        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", printInfo).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    function doReceipt() {
        let payment = {};
        payments.forEach(item => {
            payment = {
                ...payment,
                [item.id]: payType == 'pay' ? new BigNumber(item.money).toString() : new BigNumber('0').minus(new BigNumber(item.money)).toString()
            }
        })

        let itemList = [];
        let data = getItemData()
        data.forEach(item => {
            itemList.push({
                billItemId: item.id,
                invoiceTypeCode: item.invoiceTypeCode,
                money: payType == 'pay' ? new BigNumber(item.thisReceipt).toString() : new BigNumber('0').minus(new BigNumber(item.thisReceipt)).toString(),
                spec: item.spec
            })
        })

        let params = {
            ownerId: common.getUser().company.id,
            customerId: customerId,
            payments: payment,
            spec: "",
            itemList
        }

        common.loadingStart();
        common.ajax("post", "/finance/pay/create", params).then(res => {
            common.toast("提交成功");
            setIsCommit(true);
            // setKeys([])
        }).finally(common.loadingStop)
    }

    let getItemData = () => {
        let list = []
        data.forEach(dataItem => {
            if (keys.indexOf(dataItem.id) != -1) {
                dataItem.payableList.forEach(payableItem => {
                    payableItem.itemList.forEach(item => {
                        item.thisReceipt = item.money
                        item.invoiceTypeCode = dataItem.invoiceTypeCode
                        list.push(item)
                    })
                })
            }
        })

        return list
    }

    let expandedRowRender = (record, index) => {
        let arr = record.payableList
        return <Table style={{marginTop: '10px', marginBottom: '10px'}} className={'children_table'} rowKey={'id'}
                      columns={childrenColumns}
                      dataSource={arr}
                      pagination={false}/>;
    }

    return (
        <div className={"Pay"}>
            <PageTop title="付款单">
                {
                    getScan() == PAY_CREATE &&
                    <Button type="primary" disabled={isCommit} onClick={() => showReceipt("pay")}>付款确认</Button>
                }
                {
                    // getScan() == PAY_CREATE
                    //     ?
                    //     <Button icon={<PrinterOutlined/>} disabled={!isCommit}
                    //             onClick={() => showReceipt("print")}>打印</Button>
                    //     :
                    //     <Button icon={<PrinterOutlined/>} onClick={() => showReceipt("print")}>打印</Button>

                }
                <Button onClick={() => {
                    getScan() == PAY_CREATE ?
                        props.history.push({pathname: "/finance/payable/customers"}) :
                        props.history.push({pathname: "/finance/paylist"})
                }}>关闭</Button>

            </PageTop>

            <Form layout={"inline"} className={'ant-advanced-inline-form'}>

                <Form.Item label="付款单位">
                     <span className="ant-form-text">{
                         customerName
                     }</span>
                </Form.Item>
                <Form.Item label="付款日期">
                      <span className="ant-form-text">{
                          getScan() == PAY_CREATE ? getTime(true) : createTime
                      }</span>
                </Form.Item>
                <Form.Item label="付款单号">
                     <span className="ant-form-text">{
                         getScan() == PAY_CREATE ? "[待生成]" : payCode
                     }</span>
                </Form.Item>
                <Form.Item label="本笔合计">
                    <span className="ant-form-text">{
                        getScan() == PAY_CREATE ? ('￥' + common.numberFormat(getTotal())) : ('￥' + common.numberFormat(total))
                    }</span>
                </Form.Item>
                <Form.Item label="操作人">
                    <span className="ant-form-text">{
                        getScan() == PAY_CREATE ? (common.getUser().nickname) : (createdName)
                    }</span>
                </Form.Item>

                <Divider orientation="left"/>

                <div>

                    <div className='payResult'>

                        {
                            payments.map(item => {
                                return (
                                    <Payment key={item.id} name={item.name} money={item.money} id={item.id}
                                             onSave={onPayment}/>
                                )
                            })
                        }

                    </div>

                    <div className='payType'>

                        <Button type="default" icon={<WechatOutlined style={{color: '#00af0f'}}/>}
                                onClick={() => onChangeChannel("WXPAY")}>
                            微信
                        </Button>

                        <Button type="default" icon={<AlipayCircleOutlined style={{color: '#0097e6'}}/>}
                                onClick={() => onChangeChannel("ALIPAY")}>
                            支付宝
                        </Button>

                        <Button type="default" icon={<MoneyCollectOutlined style={{color: '#9e2533'}}/>}
                                onClick={() => onChangeChannel("CASH")}>
                            现金
                        </Button>

                        <Button type="default" icon={<ScanOutlined/>} onClick={() => onChangeChannel("POS")}>
                            POS
                        </Button>

                        <Button type="default" icon={<CreditCardOutlined/>} onClick={() => onChangeChannel("BANK")}>
                            银联
                        </Button>

                    </div>

                </div>

                <Divider orientation="left"/>

                <Table
                    rowKey={"id"}
                    rowSelection={getScan() == PAY_CREATE ? rowSelection : null}
                    pagination={false}
                    dataSource={data}
                    expandable={getScan() == PAY_CREATE ? {expandedRowRender: expandedRowRender,} : null}
                    columns={getScan() == PAY_CREATE ? ParentColumns : viewColumns}
                    scroll={{x: '100%'}}
                >
                </Table>

                {/* 支付的弹框 */}
                <div>
                    <Modal footer={null}
                           width={500}
                           maskClosable={false}
                           destroyOnClose={true}
                           visible={channelVisible}
                           onCancel={() => {
                               setChannelVisible(false)
                           }}>
                        <InputPay typeCode={typeCode} onSave={onSave} payMoney={payMoney}/>
                    </Modal>

                    <Modal
                        maskClosable={false}
                        visible={applyInfoVisible}
                        title={''}
                        width={1000}
                        destroyOnClose={true}
                        footer={null}
                        onCancel={() => setApplyInfoVisible(false)}
                    >
                        <ApplyForm type={'update'} applyId={applyId} customerId={customerId}
                                   isDisabled={true}/>
                    </Modal>
                </div>

            </Form>
        </div>
    )
}

export default Index