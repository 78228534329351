import React, {useEffect, useState} from "react";
import {Form, Radio} from "antd";
import common from "../../../utils/common";
import PageTop from "../../../components/layout/PageTop";

const FormItem = Form.Item

// 预结算的时候可以修改工时费 0.否  1.是
const UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_NO = "0";
const UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_YES = "1";

// VIN是否必填 0.否  1.是
const REQUIRED_VIN_NO = "0";
const REQUIRED_VIN_YES = "1";

//委外维修应付款的产生环节
const CREATE_OUTSOURCE_SCENE_REPAIR_EDIT = "edit"; //工单编辑时
const CREATE_OUTSOURCE_SCENE_REPAIR_SETTLE = "settle"; //工单结算时

//同一台车(车牌号)未结算允许再开工单
const CREATE_REPAIR_IN_SETTLEMENT_NO = "0"; //不允许
const CREATE_REPAIR_IN_SETTLEMENT_YES = "1"; //允许

// 不派工允许出料
const DELIVERY_AT_DISPATCH_NO = "0"; //不允许
const DELIVERY_AT_DISPATCH_YES = "1"; //允许

function Setting() {
    const initialTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "updateProjectSalePriceAtPrepay",
        name: "工单预结算的时候,是否可以修改工时费",
        flag: UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_NO,
    }

    const initialRequiredVINTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "requiredVIN",
        name: "车辆档案的VIN是否必填",
        flag: REQUIRED_VIN_YES, //默认必填
    }

    const initialCreateOutsourceSceneTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "createOutsourceScene",
        name: "委外维修应付款的产生环节",
        flag: CREATE_OUTSOURCE_SCENE_REPAIR_SETTLE, //默认必填
    }

    const initialCreateRepairAtSettlementTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "createRepairAtSettlement",
        name: "同一台车(车牌号)未结算允许再开工单",
        flag: CREATE_REPAIR_IN_SETTLEMENT_NO, //默认必填
    }

    const initialDeliveryAtDispatchTaxonomy = {
        ownerId: common.getUser().company.id,
        taxonomy: "deliveryAtDispatch",
        name: "不派工允许出料",
        flag: DELIVERY_AT_DISPATCH_NO, //默认必填
    }

    let [taxonomy, setTaxonomy] = useState(initialTaxonomy)  //分类
    let [requiredVINTaxonomy, setRequiredVINTaxonomy] = useState(initialRequiredVINTaxonomy)  //车辆档案的VIN是否必填分类
    let [createOutsourceSceneTaxonomy, setCreateOutsourceSceneTaxonomy] = useState(initialCreateOutsourceSceneTaxonomy)  //车辆档案的VIN是否必填分类
    let [createRepairAtSettlementTaxonomy, setCreateRepairSceneAtSettlementTaxonomy] = useState(initialCreateRepairAtSettlementTaxonomy) //同一台车(车牌号)未结算允许再开工单
    let [deliveryAtDispatchTaxonomy, setDeliveryAtDispatchTaxonomy] = useState(initialDeliveryAtDispatchTaxonomy) //不派工允许出料

    //修改工单预结算的时候,是否可以修改工时费
    let changeTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', taxonomy)
            .then(res => {
                taxonomy = res || initialTaxonomy
                common.consoleLog("res", res)
                setTaxonomy(res)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    //修改车辆档案的VIN是否必填
    let changeRequiredVINTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', requiredVINTaxonomy)
            .then(res => {
                requiredVINTaxonomy = res || initialRequiredVINTaxonomy
                common.consoleLog("requiredVINTaxonomy", requiredVINTaxonomy)
                setRequiredVINTaxonomy(requiredVINTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    //修改委外维修应付款的产生环节
    let changeCreateOutsourceSceneTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', createOutsourceSceneTaxonomy)
            .then(res => {
                createOutsourceSceneTaxonomy = res || initialCreateOutsourceSceneTaxonomy
                common.consoleLog("createOutsourceSceneTaxonomy", createOutsourceSceneTaxonomy)
                setCreateOutsourceSceneTaxonomy(createOutsourceSceneTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    //修改同一台车(车牌号)未结算允许再开工单
    let changeCreateRepairAtSettlementTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', createRepairAtSettlementTaxonomy)
            .then(res => {
                createRepairAtSettlementTaxonomy = res || initialCreateRepairAtSettlementTaxonomy
                common.consoleLog("createRepairAtSettlementTaxonomy", createRepairAtSettlementTaxonomy)
                setCreateRepairSceneAtSettlementTaxonomy(createRepairAtSettlementTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    //修改不派工允许出料
    let changeDeliveryAtDispatchTaxonomy = () => {
        common.loadingStart()
        common.ajax('post', '/support/setting', deliveryAtDispatchTaxonomy)
            .then(res => {
                deliveryAtDispatchTaxonomy = res || initialDeliveryAtDispatchTaxonomy
                common.consoleLog("deliveryAtDispatchTaxonomy", deliveryAtDispatchTaxonomy)
                setDeliveryAtDispatchTaxonomy(deliveryAtDispatchTaxonomy)
                common.toast("修改成功")
            })
            .finally(common.loadingStop)
    }

    //工单预结算的时候,是否可以修改工时费
    let getTaxonomy = () => {
        let params = {
            taxonomy: "updateProjectSalePriceAtPrepay",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                taxonomy = res || initialTaxonomy
                common.consoleLog("res", res)
                setTaxonomy(taxonomy)
            })
            .finally(common.loadingStop)
    }

    //车辆档案的VIN是否必填
    let getRequiredVINTaxonomy = () => {
        let params = {
            taxonomy: "requiredVIN",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                requiredVINTaxonomy = res || initialRequiredVINTaxonomy
                common.consoleLog("requiredVINTaxonomy", res)
                setRequiredVINTaxonomy(requiredVINTaxonomy)
            })
            .finally(common.loadingStop)
    }

    //委外维修应付款的产生环节
    let getCreateOutsourceSceneTaxonomy = () => {
        let params = {
            taxonomy: "createOutsourceScene",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                createOutsourceSceneTaxonomy = res || initialCreateOutsourceSceneTaxonomy
                common.consoleLog("createOutsourceSceneTaxonomy", res)
                setCreateOutsourceSceneTaxonomy(createOutsourceSceneTaxonomy)
            })
            .finally(common.loadingStop)
    }


    //同一台车(车牌号)未结算允许再开工单
    let getCreateRepairAtSettlementTaxonomy = () => {
        let params = {
            taxonomy: "createRepairAtSettlement",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                createRepairAtSettlementTaxonomy = res || initialCreateRepairAtSettlementTaxonomy
                common.consoleLog("createRepairAtSettlementTaxonomy", createRepairAtSettlementTaxonomy)
                setCreateRepairSceneAtSettlementTaxonomy(createRepairAtSettlementTaxonomy)
            })
            .finally(common.loadingStop)
    }

    //不派工允许出料
    let getDeliveryAtDispatchTaxonomy = () => {
        let params = {
            taxonomy: "deliveryAtDispatch",
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/support/setting', params)
            .then(res => {
                deliveryAtDispatchTaxonomy = res || initialDeliveryAtDispatchTaxonomy
                setDeliveryAtDispatchTaxonomy(deliveryAtDispatchTaxonomy)
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getTaxonomy()
        getRequiredVINTaxonomy()
        getCreateOutsourceSceneTaxonomy()
        getCreateRepairAtSettlementTaxonomy()
        getDeliveryAtDispatchTaxonomy()
    }, [])

    return (
        <React.Fragment>
            <PageTop title={"维修业务参数设置"}/>
            <Form>
                {/*修改工单预结算的时候,是否可以修改工时费*/}
                <FormItem label={taxonomy.name}>
                    <Radio.Group value={taxonomy.flag} onChange={e => {
                        taxonomy = {...taxonomy, flag: e.target.value}
                        setTaxonomy({...taxonomy})
                        changeTaxonomy()
                    }}>
                        <Radio value={UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_NO}>否</Radio>
                        <Radio value={UPDATE_PROJECT_SALE_PRICE_AT_PREPAY_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*修改车辆档案的VIN是否必填*/}
                <FormItem label={requiredVINTaxonomy.name}>
                    <Radio.Group value={requiredVINTaxonomy.flag} onChange={e => {
                        requiredVINTaxonomy = {...requiredVINTaxonomy, flag: e.target.value}
                        setRequiredVINTaxonomy({...requiredVINTaxonomy})
                        changeRequiredVINTaxonomy()
                    }}>
                        <Radio value={REQUIRED_VIN_NO}>否</Radio>
                        <Radio value={REQUIRED_VIN_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*修改委外维修应付款的产生环节*/}
                <FormItem label={createOutsourceSceneTaxonomy.name}>
                    <Radio.Group value={createOutsourceSceneTaxonomy.flag} onChange={e => {
                        createOutsourceSceneTaxonomy = {...createOutsourceSceneTaxonomy, flag: e.target.value}
                        setCreateOutsourceSceneTaxonomy({...createOutsourceSceneTaxonomy})
                        changeCreateOutsourceSceneTaxonomy()
                    }}>
                        <Radio value={CREATE_OUTSOURCE_SCENE_REPAIR_EDIT}>工单编辑时</Radio>
                        <Radio value={CREATE_OUTSOURCE_SCENE_REPAIR_SETTLE}>工单结算时</Radio>
                    </Radio.Group>
                </FormItem>

                {/*同一台车(车牌号)未结算允许再开工单*/}
                <FormItem label={createRepairAtSettlementTaxonomy.name}>
                    <Radio.Group value={createRepairAtSettlementTaxonomy.flag} onChange={e => {
                        createRepairAtSettlementTaxonomy = {...createRepairAtSettlementTaxonomy, flag: e.target.value}
                        setCreateRepairSceneAtSettlementTaxonomy({...createRepairAtSettlementTaxonomy})
                        changeCreateRepairAtSettlementTaxonomy()
                    }}>
                        <Radio value={CREATE_REPAIR_IN_SETTLEMENT_NO}>否</Radio>
                        <Radio value={CREATE_REPAIR_IN_SETTLEMENT_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>

                {/*不派工允许出料*/}
                <FormItem label={deliveryAtDispatchTaxonomy.name}>
                    <Radio.Group value={deliveryAtDispatchTaxonomy.flag} onChange={e => {
                        deliveryAtDispatchTaxonomy = {...deliveryAtDispatchTaxonomy, flag: e.target.value}
                        setDeliveryAtDispatchTaxonomy({...deliveryAtDispatchTaxonomy})
                        changeDeliveryAtDispatchTaxonomy()
                    }}>
                        <Radio value={CREATE_REPAIR_IN_SETTLEMENT_NO}>否</Radio>
                        <Radio value={CREATE_REPAIR_IN_SETTLEMENT_YES}>是</Radio>
                    </Radio.Group>
                </FormItem>
            </Form>
        </React.Fragment>
    )

}

export default Setting