import React from "react"
import {Route, Switch} from "react-router-dom"
import BusinessKind from "./businessKind";
import RepairCategory from "./repairCategory"
import VehicleTag from "./vehicleTag"
import RepairDiscountCategory from "./repairDiscountCategory"
import AccountCategory from "./accountCategory"
import VehicleImport from "./vehicle/import"
import RepairItemProjectImport from "./repairItem/repairItemProjectImport"
import RepairItemProductImport from "./repairItem/repairItemProductImport"
import Project from "./project/componets/index"
import Setting from "./setting";
import common from "../../utils/common";
import Vehicle from "./vehicle";
import ProjectImport from "./project/componets/import";

function Support() {

    return (
        <div>
            <Switch>
                {/*客户管理*/}
                {
                    common.can('admin.support.vehicle') ?
                        <Route exact={true} path={'/admin/support/vehicle'} component={Vehicle}/> : null
                }

                {/*资料管理*/}
                {/*维修业务类别*/}
                {
                    common.can('admin.support.businessKind') ?
                        <Route exact={true} path={'/admin/support/businessKind'} component={BusinessKind}/> : null
                }

                {/*常用工时项目*/}
                {
                    common.can('admin.support.project') ?
                        <Route exact={true} path={'/admin/support/project'} component={Project}/> : null
                }
                {
                    common.can('admin.support.project') ?
                        <Route exact={true} path={'/admin/support/project/import'}
                               component={ProjectImport}/> : null
                }

                {/*系统设置*/}
                {/* 车主标签管理 */}
                {
                    common.can('admin.support.vehicleTag') ?
                        <Route exact={true} path={'/admin/support/vehicleTag'} component={VehicleTag}/> : null
                }
                {/*维修工单类型*/}
                {
                    common.can('admin.support.repairCategory') ?
                        <Route exact={true} path={'/admin/support/repairCategory'} component={RepairCategory}/> : null
                }
                {/*挂帐类别*/}
                {
                    common.can('admin.support.accountCategory') ?
                        <Route exact={true} path={'/admin/support/accountCategory'} component={AccountCategory}/> : null
                }

                {/*维修工单折扣类型*/}
                <Route exact={true} path={'/admin/support/repairDiscountCategory'}
                       component={RepairDiscountCategory}/> : null
                {
                    common.can('tenant') ?
                        <Route exact={true} path={'/admin/support/repairItemProject/import'}
                               component={RepairItemProjectImport}/> : null
                }

                {
                    common.can('tenant') ?
                        <Route exact={true} path={'/admin/support/repairItemProduct/import'}
                                component={RepairItemProductImport}/> : null
                }

                {
                    common.can('tenant') ?
                        <Route exact={true} path={'/admin/support/vehicle/import'}
                                component={VehicleImport}/> : null
                }

                {/*参数设置*/}
                {
                    common.can('tenant') ?
                        <Route exact={true} path={'/admin/support/setting'} component={Setting}/> : null
                }

            </Switch>
        </div>
    )
}

export default Support;